import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";
import LoadingOverlay from 'react-loading-overlay';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';
import LongMenu from "../components/ListSetting";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DescriptionIcon from '@material-ui/icons/Description';
import { ModalTimeline } from "../components/ModalTimeline";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
        maxWidth: 200,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export default function FormListTest() {
    const classes = useStyles();
    const [dataList, setDataList] = useState([])
    const [dataListFilter, setDataListFilter] = useState([])
    const [listdepartment, setlistdepartment] = useState('')
    const [listallemployee, setListallemployee] = useState([]);
    const [dataLavel, setDataLavel] = useState([])
    const [dataUser, setDataUser] = useState([])
    const [loading, setLoading] = useState(true);
    const [filterstatus, setFilterstatus] = useState('')
    const [conditionsfilter, setConditionsfilter] = useState('')
    const [getvalues, setGetvalues] = useState('')
    const [active, setActive] = useState("");
    const [showtimeline, setShowtimeline] = useState(false)

    const handleClose = () => {
        setShowtimeline(false)
    }
    const handleShow = () => setShowtimeline(true);
    const OnloadFormData = () => {
        axios.get('api/form/forms/').then((data) => {

            if (data?.data?.results === null) {

                setLoading(false)
            } else {
                setDataList([...data?.data?.results])
                setLoading(false)
            }

        }).catch((err) => {
            console.log(err)
        })
    }
    const OnloadFormDataForFilter = () => {
        axios.get('/api/document-management/form/forms/').then((data) => {
            setDataListFilter([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const filterFormData = (e) => {
        setFilterstatus(e)
        setConditionsfilter(1)
        if (e === 'reset') {
            OnloadFormData()
        } else {
            let filterlist = dataListFilter.filter((el) => el.formstatus == e)
            setDataList([...filterlist])
        }
    }
    const filterForSearch = (e) => {
        setGetvalues(e)
        if (conditionsfilter === 1) {
            let searchname = dataListFilter.filter((el) => el.form_name.toLowerCase().includes(e.toLowerCase()));
            if (!e) {
                OnloadFormData()
            } else {
                setDataList([...searchname])
            }

        } else {
            let searchname = dataList.filter((el) => el.form_name.toLowerCase().includes(e.toLowerCase()));
            if (!e) {
                OnloadFormData()
            } else {
                setDataList([...searchname])
            }
        }
    }

    const OnLoadCount = () => {
        axios.get('/api/document-management/setting/all-approve').then((data) => {

        }).catch((err) => {
            console.log(err)
        })
    }
    const OnlaodDepartment = () => {
        axios.get('/api/document-management/setting/all-department').then((data) => {
            setlistdepartment([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnloadAllEmployee = () => {
        axios.get('/api/document-management/setting/all-employee').then((data) => {
            setListallemployee([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnloadInformationsTimeline = (form_id) => {
        axios.get(`/api/document-management/form/get-approval-data/${form_id}`).then((data) => {
            setDataLavel([...data?.data?.approvals])
            setDataUser([...data?.data?.users])
        }).catch((err) => {
            console.log(err)
        })
    }

    const columns = [
        {
            field: 'form_name',
            headerName: 'Form_name',
            width: 300,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ແບບຟອມ</small>
            ),
            renderCell: (params) => (
                <div style={{ width: '100%' }}>
                    <DescriptionIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
                    <small style={{ cursor: 'pointer' }} onClick={() => { OnloadInformationsTimeline(params.row.form_id) }}>
                        {params.row.form_name}
                    </small>

                </div>
            )
        },
        {
            field: 'created_at',
            width: 150,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່</small>
            ),
            renderCell: (params) => (
                <small>{moment(params.row.created_at).format('DD-MM-YYYY')}</small>
            )
        },
        {
            field: 'department_name',
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
            ),
            width: 250,
            renderCell: (params) => (
                <small>{params.row.department_name}</small>
            )
        },
        {
            field: 'req_status',
            width: 200,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນາ</small>
            ),
            renderCell: (params) => (
                <>
                    {
                        params.row.formstatus === 0 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <ScreenRotationIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#4f46e5' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#4f46e5' }}>Created....</small>
                            </div>
                        )}
                    {
                        params.row.formstatus === 1 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <RepeatOneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#f000b9' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#f000b9' }}>Requested....</small>
                            </div>
                        )}
                    {
                        params.row.formstatus === 2 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <AutorenewIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff9800' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff9800' }}>In Progress....</small>
                            </div>
                        )}
                    {
                        params.row.formstatus === 3 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#10b981' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#10b981' }}>Approved....</small>
                            </div>
                        )}
                    {
                        params.row.formstatus === 4 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <ErrorIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Rejected....</small>
                            </div>
                        )}
                    {
                        params.row.formstatus === 5 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <ReportProblemIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Draft....</small>
                            </div>
                        )}
                    {
                        params.row.formstatus === 6 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <ReportProblemIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff5724' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff5724' }}>Draft....</small>
                            </div>
                        )}
                </>
            ),
        },
        {
            field: 'employee_name',
            width: 200,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຜູ້ຮັບຜິຊອບ</small>
            ),
            renderCell: (params) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                }}
                >
                    <ComponentUser
                        form_id={params.row.form_id}
                        handleShow={handleShow}
                        OnloadInformationsTimeline={OnloadInformationsTimeline}
                    />
                </div>
            )
        },
        {
            field: 'actions',
            width: 150,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}></small>
            ),
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <LongMenu
                        form_id={params.row.form_id}
                        approval_id={params.row.approval_id}
                        formstatus={params.row.formstatus}
                        massage={params.row.massage}
                        OnloadFormData={OnloadFormData}
                        listdepartment={listdepartment}
                        listallemployee={listallemployee}
                    />



                </div>
            )
        },
    ];
    useEffect(() => {
        OnloadFormData()
        OnloadFormDataForFilter()
        OnlaodDepartment()
        OnloadAllEmployee();
        OnLoadCount();
    }, [])
    return (
        <LoadingOverlay
            active={loading}
            spinner
            text="Loading..."
        >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 50 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ຈັດການຂໍ້ມູນ</h5>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
                    <h5 style={{ color: '#007bff' }}>ເເບບຟອມ</h5>
                </div>
                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-start' }}>
                        <input
                            type="text"
                            placeholder="Search field"
                            style={{
                                width: '100%',
                                border: '1px solid #5EBFE3',
                                height: 35,
                                borderRadius: 5
                            }}
                            value={getvalues}
                            onChange={(e) => filterForSearch(e.target.value)}
                        />
                    
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-end' }}>
                        <select
                            style={{
                                border: '1px solid #5EBFE3',
                                height: 35,
                                borderRadius: 5,
                                marginRight: 0
                            }}
                            onChange={(e) => filterFormData(e.target.value)}
                            value={filterstatus}
                        >
                            <option value='reset'>=ໃຫ້ເລື່ອກສະຖານະ=</option>
                            <option value='0'>Created</option>
                            <option value='1'>Requested</option>
                            <option value='2'>In Progress</option>
                            <option value='3'>Approval</option>
                            <option value='4'>Rejected</option>
                            <option value='6'>Draft</option>
                        </select>
                    </div>
                </div>
                <ModalTimeline
                    showtimeline={showtimeline}
                    handleClose={handleClose}
                    dataUser={dataUser}
                    dataLavel={dataLavel}
                    req_status='form'

                />
                <div style={{ width: '100%', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={dataList}
                            columns={columns}
                            pageSize={20}
                            disableSelectionOnClick
                        />
                    </div>
                </div>
            </div>
        </LoadingOverlay >
    )
}

function ComponentUser({ form_id, OnloadInformationsTimeline, handleShow }) {
    const [datauser, setDataUser] = useState()
    const [active, setActive] = useState('')
    useEffect(() => {
        axios.get(`/api/document-management/form/get-approval-data/${form_id}`)
            .then((data) => {
                setDataUser([...data?.data?.users]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [form_id]);
    return (
        <div style={{
            display: 'flex', flexDirection: 'row', width: "100%"
        }}
            onClick={() => { OnloadInformationsTimeline(form_id) }}
        >
            {datauser?.map((item, index) => (
                <div key={index}>
                    <img src={item.image[0]}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            marginRight: -10,
                            boxShadow: active === 'user' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                        onMouseOver={() => setActive('user')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { handleShow() }}
                    />
                </div>
            ))}
        </div>
    );

}


