import React, { useEffect, useState } from "react";
import { DataGrid } from '@material-ui/data-grid';
import DoneIcon from '@material-ui/icons/Done';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import axios from "axios";
import moment from "moment";
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ListusingRequestSettingIn from "../componentsmobile/ListusingRequestSettingIn";
import DescriptionIcon from '@material-ui/icons/Description';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import { ModalTimeline } from "../components/ModalTimeline";
import LoadingOverlay from 'react-loading-overlay';
import { ModalDataIncoming } from "../components/ModalDataIncoming";

export const ApprovedRequestInmobile = () => {
  const [dataList, setdataList] = useState([])
  const [dataLavel, setDataLavel] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [loading, setLoading] = useState(true);
  const [listallemployee, setListallemployee] = useState([]);
  const [listdepartment, setListdepartment] = useState([])
  const [showtimeline, setShowtimeline] = useState(false)
  const [fileList, setFileList] = useState([])
  const [showmodal, setShowmodal] = useState(false)
  const handleClose = () => {
    setShowtimeline(false)
  }
  const handleshowmodal = () => setShowmodal(true)
  const handleClosemodal = () => setShowmodal(false)
  const handleShow = () => setShowtimeline(true);
  const OnloadFormData = () => {
    axios.get('/api/document-management/incoming/setting/view-request').then((data) => {
      console.log("dataLoading=", data)

      if (data?.data?.results === null) {
        setLoading(false)
      } else {
        setdataList([...data?.data?.results])
        setLoading(false)
      }

    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadInformationsTimeline = (e) => {
    axios.get(`/api/document-management/incoming/get-approval-incoming/${e}`).then((data) => {
      setDataLavel([...data?.data?.approvals])
      setDataUser([...data?.data?.users])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadAllEmployee = () => {
    axios.get('/api/document-management/setting/all-employee').then((data) => {
      setListallemployee([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadDepartment = () => {
    axios.get('/api/document-management/no/get-department').then((data) => {
      setListdepartment([...data?.data?.results])
    }).catch((err) => (
      console.log(err)
    ))
  }
  const OnloadDataInformations = (e) => {
    axios.get(`/api/document-management/incoming/incoming-form/${e}`).then((data) => {
      setFileList([...data?.data?.results?.detail])

    }).catch((err) => {
      console.log(err)
    })
  }
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ເອກະສານ</small>
      ),
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <DescriptionIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
          <h6 style={{ cursor: 'pointer' }} onClick={() => { OnloadDataInformations(params.row.req_id); handleshowmodal() }}>
            {params.row.title}
          </h6>
        </div>
      )
    },

    {
      field: 'doc_no',
      headerName: 'ເລກທີ່ເອກະສານ',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ເລກທີ່</small>
      ),
      renderCell: (params) => (
        <Chip

          label="ເລກທີ່ເອກະສານ"
          avatar={<Avatar>{params.row.doc_no}</Avatar>}
          clickable
          color="primary"
          variant="outlined"
        />
      )
    },
    {
      field: 'created_at',
      headerName: 'ວັນທີ່',
      width: 100,

      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່</small>
      ),
      renderCell: (params) => (
        <>
          {
            moment(params.row.created_at).format('DD-MM-YYYY')
          }
        </>
      )
    },
    {
      field: 'department_name',
      headerName: 'ພະແນກ',
      width: 200,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
      ),
      renderCell: (params) => (
        <small>{params.row.department_name}</small>
      )
    },
    {
      field: 'employee_name',
      width: 200,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຜູ້ຮັບຜິຊອບ</small>
      ),
      renderCell: (params) => (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
        }}
        >
          <ComponentUser
            req_id={params.row.req_id}
            OnloadInformationsTimeline={OnloadInformationsTimeline}
            handleShow={handleShow}
          />
        </div>
      )
    },
    {
      field: 'req_status',
      headerName: 'Status',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນາ</small>
      ),

      renderCell: (params) => (
        <>
          {
            params.row.req_status === 1 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <AutorenewIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff9800' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff9800' }}>In progress ....</small>
              </div>
            )}
          {
            params.row.req_status === 2 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#10b981' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#10b981' }}>received....</small>
              </div>
            )}
          {
            params.row.req_status === 3 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ErrorIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>rejected....</small>
              </div>
            )}
          {
            params.row.req_status === 4 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <RepeatOneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#f000b9' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#f000b9' }}>Requested....</small>
              </div>
            )}

        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>######</small>
      ),
      renderCell: (params) => (
        <div>
          <ListusingRequestSettingIn
            req_id={params.row.req_id}
            approval_id={params.row.approval_id}
            req_status={params.row.req_status}
            signature_id={params.row.signature_id}
            doc_no={params.row.doc_no}
            docno_status={params.row.docno_status}
            OnloadFormData={OnloadFormData}
            listallemployee={listallemployee}
            listdepartment={listdepartment}
          />
        </div>
      )
    },
  ];
  useEffect(() => {
    OnloadFormData()
    OnloadAllEmployee()
    OnloadDepartment();
  }, [])
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading..."
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 40 }}>
          <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ອະນຸມັດ</h5>
          <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
          <h5 style={{ color: '#007bff' }}>ເອກະສານຂາເຂົ້າ</h5>
        </div>
        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <input
              type="text"
              placeholder="Search field"
              style={{
                width: '50%',
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5
              }}
              // onChange={(e) => filterForSearch(e.target.value)}
            />
            <select
              style={{
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5,
              }}
              // onChange={(e) => filterFormData(e.target.value)}
              // value={filterstatus}
            >
              <option value='reset'>=ໃຫ້ເລື່ອກສະຖານະ=</option>
              <option value='3'>Requested</option>
              <option value='4'>In Progress</option>
              <option value='5'>Approval</option>
              <option value='6'>Rejected</option>
            </select>
          
        </div>
        {
          showmodal === true ? (
            <>
              <ModalDataIncoming
                showmodal={showmodal}
                handleClosemodal={handleClosemodal}
                fileList={fileList}
              />
            </>) : null
        }
        <ModalTimeline
          showtimeline={showtimeline}
          handleClose={handleClose}
          dataUser={dataUser}
          dataLavel={dataLavel}
          req_status='request'
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' ,marginTop:10}}>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={dataList}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
            />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}
function ComponentUser({ req_id, OnloadInformationsTimeline, handleShow }) {
  const [datauser, setDataUser] = useState()
  const [active, setActive] = useState('')
  useEffect(() => {
    axios.get(`/api/document-management/incoming/get-approval-incoming/${req_id}`)
      .then((data) => {
        setDataUser([...data?.data?.users]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [req_id]);
  return (
    <div style={{
      display: 'flex', flexDirection: 'row', width: "100%"
    }}
      onClick={() => { OnloadInformationsTimeline(req_id) }}
    >
      {datauser?.map((item, index) => (
        <div key={index}>
          <img src={item.image[0]}
            style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: -10,
              boxShadow: active === 'user' ? '1px 2px 9px #F4AAB9' : ''
            }}
            onMouseOver={() => setActive('user')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { handleShow() }}
          />
        </div>
      ))}
    </div>
  );

}

