import React, { useState } from "react";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
export const ImageMenu = (props) => {
    const [active, setActive] = useState('')
    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Frame</h6>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>X</small>
                        <input

                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            value={props.selectedImage[0]?.positionX}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>Y</small>
                        <input
                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            value={props.selectedImage[0]?.positionY}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>W</small>
                        <input
                            value={props.ing_width}
                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            onChange={(e) => props.changeTextImage_width(e.target.value, 'width', props.get_index)}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>H</small>
                        <input
                           value={props.ing_heigh}
                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            onChange={(e) => props.changeTextImage_height(e.target.value, 'height', props.get_index)}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Size</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <input
                            placeholder='Size'
                            style={{

                                marginLeft: 10,
                                width: '100%',
                                marginRight: 20,
                                borderRadius: 5,
                                border: '1px solid #5EBFE3',

                            }}

                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                    <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Color</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <input
                            placeholder='#000000'
                            style={{
                                border: '1px solid #5EBFE3',
                                marginLeft: 10,
                                marginRight: 10,
                                borderRadius: 5,
                                width: '100%'
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Family</h6>
                <select
                    style={{ border: '1px solid #5EBFE3', marginLeft: 10, marginRight: 10, borderRadius: 5 }}
                >
                    <option>BebasNeue-Regular</option>
                    <option>NotoSans-Bold</option>
                </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Style</h6>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'b' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('b')}
                            onMouseLeave={() => setActive(null)}
                        >
                            <FormatBoldIcon />
                        </div>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'I' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('I')}
                            onMouseLeave={() => setActive(null)}
                        >
                            <FormatItalicIcon />
                        </div>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'C' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('C')}
                            onMouseLeave={() => setActive(null)}
                        >
                            <FormatLineSpacingIcon />
                        </div>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'D' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('D')}
                            onMouseLeave={() => setActive(null)}
                            onClick={() => { props.deletecheckbox(props.get_index) }}
                        >
                            <DeleteSweepIcon />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
