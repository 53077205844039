import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export const ModalTable = (props) => {
    const [active, setActive] = useState("");
    return (
        <Modal show={props.show} onHide={props.handleClosedel} style={{ paddingTop: 50 }}>
            <Modal.Header closeButton>
                <span style={{ fontSize: 25, fontWeight: 25, paddingTop: 10 }}>ສ້າງຕະຕາລາງ</span>
            </Modal.Header>
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10, marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', marginBottom: 20, marginRight: 10 }}>
                        <small style={{
                            border: '1px solid #5EBFE3',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '30%',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            fontWeight: 'bold',
                            backgroundColor: '#dbeafe'
                        }}>Column</small>
                        <input style={{
                            border: '1px solid #5EBFE3',
                            width: '70%',
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            borderLeft: 'none',
                        }}
                            onChange={e => props.setTableRow(e.target.value)}

                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', marginBottom: 20, marginLeft: 10 }}>
                        <small style={{
                            border: '1px solid #5EBFE3',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '30%',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            fontWeight: 'bold',
                            backgroundColor: '#dbeafe'
                        }}>Row</small>
                        <input style={{
                            border: '1px solid #5EBFE3',
                            width: '70%',
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            borderLeft: 'none',
                            marginRight: 20
                        }}
                            onChange={e => props.setTableColumn(e.target.value)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                    <div
                        style={{
                            border: '1px solid #5EBFE3',
                            marginRight: 10,
                            borderRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: active === 'm' ? '1px 2px 9px #F4AAB9' : '',
                            cursor:'pointer'
                        }}
                        onMouseOver={() => setActive('m')}
                        onMouseLeave={() => setActive(null)}
                        onClick={()=>{props.onCreateTable()}}
                    >
                        <small style={{ marginLeft: 10, marginRight: 10 }}>ບັນທືກ</small>
                    </div>

                </div>

            </div>



        </Modal>
    )
}
