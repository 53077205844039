import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function CustomizedTimeline(props) {
    const classes = useStyles();
    return (
        <Timeline align="alternate">
            {props.dataLaveldefualt && props.dataLaveldefualt.map((item, index) => {
                return (<>
                    <TimelineItem key={index}>
                        <TimelineOppositeContent>
                            <Typography variant="body2" color="textSecondary">
                                ວັນທີ່ສ້າງ:{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                            </Typography>
                            {
                                item?.levels == 0 ? (
                                    <>
                                        <Typography color="textSecondary" style={{ marginLeft: 10 }} >
                                            ສ້າງແບບຟອມ..
                                        </Typography>

                                    </>
                                ) : item?.levels == 1 ? (
                                    <>
                                        {
                                            item?.formstatus == 0 || item?.formstatus == 1 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                          <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                                            <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting</small>
                                                        </div>
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 2 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                                            <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress</small>
                                                        </div>
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 3 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10, color: '#10b981' }} >

                                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                                            <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approve</small>
                                                        </div>
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 4 ? (<>
                                                <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                    Rejected....
                                                </Typography>

                                            </>) : item?.formstatus === 6 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Draft....
                                                    </Typography>
                                                </>) : null
                                        }
                                    </>
                                ) : item?.levels == 2 ? (
                                    <>
                                        {
                                            item?.formstatus == 0 || item?.formstatus == 1 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Waiting..
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 2 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        In Progress
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 3 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Approve
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 4 ? (<>
                                                <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                    Rejected....
                                                </Typography>

                                            </>) : item?.formstatus === 6 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Draft....
                                                    </Typography>
                                                </>) : null
                                        }
                                    </>
                                ) : item?.levels == 3 ? (
                                    <>
                                        {
                                            item?.formstatus == 0 || item?.formstatus == 1 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Waiting..
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 2 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        In Progress
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 3 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Approve
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 4 ? (<>
                                                <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                    Rejected....
                                                </Typography>

                                            </>) : item?.formstatus === 6 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Draft....
                                                    </Typography>
                                                </>) : null
                                        }
                                    </>
                                ) : item?.levels == 4 ? (
                                    <>
                                        {
                                            item?.formstatus == 0 || item?.formstatus == 1 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Waiting..
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 2 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        In Progress
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 3 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Approve
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 4 ? (<>
                                                <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                    Rejected....
                                                </Typography>

                                            </>) : item?.formstatus === 6 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Draft....
                                                    </Typography>
                                                </>) : null
                                        }
                                    </>
                                ) : item?.levels == 5 ? (
                                    <>
                                        {
                                            item?.formstatus == 0 || item?.formstatus == 1 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Waiting..
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 2 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        In Progress
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 3 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Approve
                                                    </Typography>
                                                </>
                                            ) : item?.formstatus === 4 ? (<>
                                                <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                    Rejected....
                                                </Typography>

                                            </>) : item?.formstatus === 6 ? (
                                                <>
                                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                                        Draft....
                                                    </Typography>
                                                </>) : null
                                        }
                                    </>
                                ) : null
                            }
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot style={{ backgroundColor: "#007bff" }}>
                                {
                                    item?.levels == 0 ? (
                                        <>
                                            <AccessAlarmIcon />

                                        </>
                                    ) : item?.levels == 1 ? (
                                        <>
                                            {
                                                item?.formstatus == 0 || item?.formstatus == 1 ?
                                                    (
                                                        <>
                                                            <AccessAlarmIcon />
                                                        </>
                                                    ) : item?.formstatus === 2 ? (
                                                        <>
                                                            <AutorenewIcon />
                                                        </>
                                                    ) : item?.formstatus === 3 ? (
                                                        <>
                                                            <CheckCircleOutlineIcon />
                                                        </>
                                                    ) : item.formstatus === 4 ? (
                                                        <>
                                                            <ErrorIcon />
                                                        </>

                                                    ) : item.formstatus === 6 ? (
                                                        <>
                                                            <ReportProblemIcon />
                                                        </>
                                                    ) : null
                                            }
                                        </>
                                    ) : item?.levels == 2 ? (
                                        <>
                                            {
                                                item?.formstatus == 0 || item?.formstatus == 1 ?
                                                    (
                                                        <>
                                                            <AccessAlarmIcon />
                                                        </>
                                                    ) : item?.formstatus === 2 ? (
                                                        <>
                                                            <AutorenewIcon />
                                                        </>
                                                    ) : item?.formstatus === 3 ? (
                                                        <>
                                                            <CheckCircleOutlineIcon />
                                                        </>
                                                    ) : item.formstatus === 4 ? (
                                                        <>
                                                            <ErrorIcon />
                                                        </>

                                                    ) : item.formstatus === 6 ? (
                                                        <>
                                                            <ReportProblemIcon />
                                                        </>
                                                    ) : null
                                            }

                                        </>
                                    ) : item?.levels == 3 ? (
                                        <>
                                            {
                                                item?.formstatus == 0 || item?.formstatus == 1 ?
                                                    (
                                                        <>
                                                            <AccessAlarmIcon />
                                                        </>
                                                    ) : item?.formstatus === 2 ? (
                                                        <>
                                                            <AutorenewIcon />
                                                        </>
                                                    ) : item?.formstatus === 3 ? (
                                                        <>
                                                            <CheckCircleOutlineIcon />
                                                        </>
                                                    ) : item.formstatus === 4 ? (
                                                        <>
                                                            <ErrorIcon />
                                                        </>

                                                    ) : item.formstatus === 6 ? (
                                                        <>
                                                            <ReportProblemIcon />
                                                        </>
                                                    ) : null
                                            }

                                        </>
                                    ) : item?.levels == 4 ? (
                                        <>
                                            {
                                                item?.formstatus == 0 || item?.formstatus == 1 ?
                                                    (
                                                        <>
                                                            <AccessAlarmIcon />
                                                        </>
                                                    ) : item?.formstatus === 2 ? (
                                                        <>
                                                            <AutorenewIcon />
                                                        </>
                                                    ) : item?.formstatus === 3 ? (
                                                        <>
                                                            <CheckCircleOutlineIcon />
                                                        </>
                                                    ) : item.formstatus === 4 ? (
                                                        <>
                                                            <ErrorIcon />
                                                        </>

                                                    ) : item.formstatus === 6 ? (
                                                        <>
                                                            <ReportProblemIcon />
                                                        </>
                                                    ) : null
                                            }
                                        </>
                                    ) : item?.levels == 5 ? (
                                        <>
                                            {
                                                item?.formstatus == 0 || item?.formstatus == 1 ?
                                                    (
                                                        <>
                                                            <AccessAlarmIcon />
                                                        </>
                                                    ) : item?.formstatus === 2 ? (
                                                        <>
                                                            <AutorenewIcon />
                                                        </>
                                                    ) : item?.formstatus === 3 ? (
                                                        <>
                                                            <CheckCircleOutlineIcon />
                                                        </>
                                                    ) : item.formstatus === 4 ? (
                                                        <>
                                                            <ErrorIcon />
                                                        </>

                                                    ) : item.formstatus === 6 ? (
                                                        <>
                                                            <ReportProblemIcon />
                                                        </>
                                                    ) : null
                                            }

                                        </>
                                    ) : null
                                }
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                                <ComponentCell
                                    item={item}
                                    dataUser={props.dataUserdefualt}
                                />
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                </>)
            })}
        </Timeline>
    );
}
function ComponentCell({ item, dataUser }) {
    const filter = dataUser.filter((el) => el.level_uid == item?.level_uid);

    return (<>
        {
            filter.map((data, index) => {

                return (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, width: '100%' }} key={index}>
                            <div style={{ backgroundColor: '#e9eef5', width: 50, height: 50, borderRadius: '50%', }}>
                                <img src={data.image[0]} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <small>{data?.create_employee_name}</small>
                            </div>

                            {/* <Typography style={{ marginLeft: 10, marginBottom: 10, marginTop: 5 }}>{data?.create_employee_name}</Typography>  */}
                        </div>
                    </>

                )
            })
        }
    </>)
}