
import React, { useState, useContext, useEffect, useRef } from "react";
import axios from 'axios'
import { DataGrid } from '@material-ui/data-grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import moment from "moment";
import DoneIcon from '@material-ui/icons/Done';
import MenuActions from "../components/MenuActions";
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DescriptionIcon from '@material-ui/icons/Description';
import LoadingOverlay from 'react-loading-overlay';
import SearchIcon from '@material-ui/icons/Search';
import { ModalTimeline } from "../components/ModalTimeline";
import { ModalDataIncoming } from "../components/ModalDataIncoming";
import SendIcon from '@material-ui/icons/Send';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export const IncomingDocumentmobile = () => {
    const classes = useStyles();
    const [active, setActive] = useState("");
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading,] = useState(false);
    const [title, setTitle] = useState('')
    const [company_name, setCompany_name] = useState('')
    const [contact, setContact] = useState('')
    const [address, setAddress] = useState('')
    const [doc_no, setDoc_no] = useState('')
    const [file, setFile] = useState('')
    const [image, setImage] = useState('')
    const [conditionsfilter, setConditionsfilter] = useState('')
    const [dataListFilter, setDataListFilter] = useState([])
    const [dataLavel, setDataLavel] = useState([])
    const [dataUser, setDataUser] = useState([])
    const [filterstatus, setFilterstatus] = useState('')
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [req_status, setReq_status] = useState('')
    const [loading, setLoading] = useState(true);
    const [dataList, setDataList] = useState([])
    const [listdepartment, setlistdepartment] = useState('')
    const [listallemployee, setListallemployee] = useState([]);
    const [getvalues, setGetvalues] = useState('')
    const [showtimeline, setShowtimeline] = useState(false)
    const [fileList, setFileList] = useState([])
    const [showmodal, setShowmodal] = useState(false)
    const handleshowmodal = () => {
        console.log("ddd")
        setShowmodal(true)
    }
    const handleClosemodal = () => setShowmodal(false)
    const handleClose = () => {
        setShowtimeline(false)
    }
    const handleShow = () => setShow(true);
    const handleshowtimeline = () => setShowtimeline(true)
    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        setChecked2(false)
        setReq_status(1)
    };
    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
        setChecked1(false)
        setReq_status(0)
    };
    const Onloaddata = () => {
        axios.get('/api/document-management/incoming/incoming-forms-user').then((data) => {
            console.log("Oload=", data)
            if (data?.data?.results === null) {
                setLoading(false)
            } else {
                setDataList([...data?.data?.results])
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnloadInformationsTimeline = (e) => {
        axios.get(`/api/document-management/incoming/get-approval-incoming/${e}`).then((data) => {
            setDataLavel([...data?.data?.approvals])
            setDataUser([...data?.data?.users])
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnloadFormDataForFilter = () => {
        axios.get('/api/document-management/incoming/incoming-forms-user').then((data) => {
            setDataListFilter([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const filterFormData = (e) => {
        setFilterstatus(e)
        setConditionsfilter(1)
        if (e === 'reset') {
            Onloaddata()
        } else {
            let filterlist = dataListFilter.filter((el) => el.req_status == e)
            setDataList([...filterlist])
        }
    }
    const OnloadDataInformations = (e) => {
        axios.get(`/api/document-management/incoming/incoming-form/${e}`).then((data) => {
            setFileList([...data?.data?.results?.detail])
            console.log("loading=", data?.data?.results?.detail)
        }).catch((err) => {
            console.log(err)
        })
    }
    const handlePictureChange = (event) => {
        setFile(event.target.files);
        const selectedFiles = event.target.files;
        const file_attachment = Array.from(selectedFiles);
        setImage(file_attachment)

    };
    const filterForSearch = (e) => {
        setGetvalues(e)
        if (conditionsfilter === 1) {
            let searchname = dataListFilter.filter((el) => el.title.toLowerCase().includes(e.toLowerCase()));
            if (!e) {
                Onloaddata()
            } else {
                setDataList([...searchname])
            }

        } else {
            let searchname = dataList.filter((el) => el.title.toLowerCase().includes(e.toLowerCase()));
            if (!e) {
                Onloaddata()
            } else {
                setDataList([...searchname])
            }

        }
    }
    const CreateData = async () => {

        setIsLoading(true)
        let images
        if (!file) {
            images = 0
        } else {
            let formData = new FormData();
            for (const key of Object.keys(file)) {
                formData.append("file_name", file[key]);
            }
            formData.append("file_attachment", file);
            let profileImageReturnName = await axios.post("/api/document-management/incoming/upload", formData);
            images = profileImageReturnName.data;
        }
        let dataInform = {
            title,
            company_name,
            contact,
            address,
            doc_no,
            req_status,
            file_name: images,
        }
        axios.post('/api/document-management/incoming/insert-incoming-form', dataInform).then((data) => {

            setTitle('')
            setAddress('')
            setCompany_name('')
            setDoc_no('')
            setAddress('')
            setContact('')
            setImage('')
            setIsLoading(false)
            setShow(false)
            setChecked1(false)
            setChecked2(false)
            Onloaddata();
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleClosedel = () => {
        setShow(false);
    }
    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 300,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຫົວຂໍເອກະສານ</small>
            ),
            renderCell: (params) => (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <DescriptionIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
                    <h6 style={{ cursor: 'pointer' }} onClick={() => { OnloadDataInformations(params.row.req_uid); handleshowmodal() }}>
                        {params.row.title}
                    </h6>
                </div>
            )
        },
        {
            field: 'created_at',
            headerName: 'Last Modified',
            width: 170,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່ສ້າງ</small>
            ),
            renderCell: (params) => (
                <>
                    {
                        moment(params.row.created_at).format('DD-MM-YYYY')
                    }
                </>
            )
        },
        {
            field: 'branch_name',
            headerName: 'Branch_name',
            width: 200,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ບ່ອນປະຈຳການ</small>
            )
        },
        {
            field: 'department_name',
            headerName: 'Department_name',
            width: 250,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
            )
        },
        {
            field: 'docno_status',
            headerName: 'ສະຖະນາ',
            width: 150,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນະ</small>
            ),
            renderCell: (params) => (
                <>
                    {
                        params.row.req_status === 0 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <ScreenRotationIcon style={{ fontSize: 15, marginLeft: 5, marginTop: 4, color: '#4f46e5' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#4f46e5' }}>Create....</small>
                            </div>
                        )}
                    {
                        params.row.req_status === 1 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <AutorenewIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff9800' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff9800' }}>In progress ....</small>
                            </div>
                        )}
                    {
                        params.row.req_status === 2 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}> Received ....</small>
                            </div>
                        )}
                    {
                        params.row.req_status === 3 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Rejected....</small>
                            </div>
                        )}
                    {
                        params.row.req_status === 4 && (
                            <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                                <RepeatOneIcon style={{ fontSize: 15, marginLeft: 5, marginTop: 2, color: '#f000b9' }} />
                                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#f000b9' }}>Requested....</small>
                            </div>
                        )}

                </>
            ),
        },
        {
            field: 'employee_name',
            width: 200,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຜູ້ຮັບຜິຊອບ</small>
            ),
            renderCell: (params) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                }}
                >
                    <ComponentUser
                        req_id={params.row.req_uid}
                        OnloadInformationsTimeline={OnloadInformationsTimeline}
                        handleShow={handleshowtimeline}
                    />
                </div>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>#####</small>
            ),
            renderCell: (params) => (
                <MenuActions
                    listdepartment={listdepartment}
                    listallemployee={listallemployee}
                    req_uid={params.row.req_uid}
                    approval_id={params.row.approval_id}
                    signature_id={params.row.signature_id}
                    Onloaddata={Onloaddata}
                    req_status={params.row.req_status}
                />
            )
        },
    ];
    const OnlaodDepartment = () => {
        axios.get('/api/document-management/setting/all-department').then((data) => {
            setlistdepartment([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnloadAllEmployee = () => {
        axios.get('/api/document-management/setting/all-employee').then((data) => {
            setListallemployee([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        Onloaddata();
        OnloadFormDataForFilter();
        OnlaodDepartment();
        OnloadAllEmployee();
    }, [])

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text="Loading..."
        >
            <div style={{ width: "100%" }}>
                <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
                    <Modal.Header closeButton>
                        <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>
                            Setting </span>
                    </Modal.Header>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
                            <div style={{
                                width: '30%',
                                border: '1px solid #5ebfe3',
                                marginLeft: 10,
                                height: 40,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderRight: 'none',
                                backgroundColor: "#e9eef5",
                            }}>
                                <small >ຫົວຂໍ້ເອກະສານ:</small>
                            </div>
                            <div style={{ width: '70%', marginRight: 10 }}>
                                <input type="text" style={{
                                    width: '100%',
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderLeft: 'none',

                                }}
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
                            <div style={{
                                width: '30%',
                                border: '1px solid #5ebfe3',
                                marginLeft: 10,
                                height: 40,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderRight: 'none',
                                backgroundColor: "#e9eef5",
                            }}>
                                <small>ຊື່ບໍລິສັດ:</small>
                            </div>
                            <div style={{ width: '70%', marginRight: 10 }}>
                                <input type="text" style={{
                                    width: '100%',
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderLeft: 'none',

                                }}
                                    onChange={(e) => setCompany_name(e.target.value)}
                                    value={company_name}
                                />
                            </div>


                        </div>



                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
                            <div style={{
                                width: '30%',
                                border: '1px solid #5ebfe3',
                                marginLeft: 10,
                                height: 40,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderRight: 'none',
                                backgroundColor: "#e9eef5",
                            }}>
                                <small>ເບີໂທ:</small>
                            </div>
                            <div style={{ width: '70%', marginRight: 10 }}>
                                <input type="text" style={{
                                    width: '100%',
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderLeft: 'none',

                                }}
                                    onChange={(e) => setContact(e.target.value)}
                                    value={contact}
                                />
                            </div>


                        </div>




                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
                            <div style={{
                                width: '30%',
                                border: '1px solid #5ebfe3',
                                marginLeft: 10,
                                height: 40,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderRight: 'none',
                                backgroundColor: "#e9eef5",
                            }}>
                                <small>ທີ່ຢູ່:</small>
                            </div>
                            <div style={{ width: '70%', marginRight: 10 }}>
                                <input type="text" style={{
                                    width: '100%',
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderLeft: 'none',

                                }}
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                />
                            </div>


                        </div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
                            <div style={{
                                width: '30%',
                                border: '1px solid #5ebfe3',
                                marginLeft: 10,
                                height: 40,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderRight: 'none',
                                backgroundColor: "#e9eef5",
                            }}>
                                <small>ເລກທີເອກະສານ:</small>
                            </div>
                            <div style={{ width: '70%', marginRight: 10 }}>
                                <input type="text" style={{
                                    width: '100%',
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderLeft: 'none',

                                }}
                                    onChange={(e) => setDoc_no(e.target.value)}
                                    value={doc_no}
                                />
                            </div>


                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <div style={{ marginLeft: 10 }}>
                                <Checkbox
                                    checked={checked1}
                                    onChange={handleChange1}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <small>ປະຕິເສດ</small>
                            </div>
                            <div>
                                <Checkbox
                                    checked={checked2}
                                    onChange={handleChange2}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <small>ຮັບເອກະສານ</small>

                            </div>

                        </div>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                            <div className={classes.root} style={{ marginLeft: 10 }}>
                                <input

                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    onChange={handlePictureChange}
                                    type="file"
                                />
                                <label htmlFor="contained-button-file">
                                    <div style={{ border: '1px solid #5ebfe3', borderRadius: 5, cursor: 'pointer', height: 30 }}>
                                        <small style={{ marginLeft: 10, marginRight: 10, marginTop: 5, display: 'flex', flex: 1 }}>Upload Picture</small>
                                    </div>

                                </label>
                            </div>
                            <div style={{ width: '100%', border: '1px solid #5ebfe3', }}>
                                <div style={{ width: '100%', height: 100, display: 'flex', flexDirection: 'column' }}>
                                    {image &&
                                        image.map((data, index) => {
                                            let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                            return (
                                                <div key={index}>
                                                    <small style={{ fontSize: 15 }}>{data?.name} ({sizes}) kb</small>
                                                </div>
                                            );
                                        })}
                                </div>


                            </div>

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{ display: 'flex', width: '70%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '20%',
                                    flexDirection: 'row',
                                    border: '1px solid #5ebfe3',
                                    justifyContent: 'space-between',
                                    borderRadius: 5,
                                    marginTop: 10,
                                    marginBottom: 20,
                                    marginRight: 10
                                }}
                                onClick={() => { CreateData() }}
                                >

                                <small style={{ marginTop: 3, marginLeft: 3, fontWeight: 'bold', color: "#007bff" }}>

                                    {!isLoading ? (
                                        <>
                                            Send
                                        </>
                                    ) : (
                                        <>
                                            {
                                                <Spinner animation="border" variant="light" size='sm' />
                                            }
                                        </>)
                                    }
                                </small>
                                <SendIcon style={{ color: "007bff" }} />

                            </div>
                 
                        </div>
                    </div>
                </Modal>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 50 }}>
                    <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ເອກະສານ</h5>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
                    <h5 style={{ color: '#007bff' }}>ຂໍ້ມູນເອກະສານຂາເຂົ້າ</h5>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid #5ebfe3', borderRadius: 5, cursor: 'pointer', height: 25, marginTop: 2, backgroundColor: active === 'Dashboard' ? '#e9eef5' : '' }}
                        onMouseOver={() => setActive('Dashboard')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { handleShow() }}
                    >
                        <AddIcon style={{ color: '#007bff' }} />
                        <small style={{ marginTop: 2, marginLeft: 2, marginRight: 2, color: '#007bff' }}>ສ້າງ</small>
                    </div>
                </div>
                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-start' }}>
                        <input
                            type="text"
                            placeholder="Search field"
                            style={{
                                width: '100%',
                                border: '1px solid #5EBFE3',
                                height: 35,
                                borderRadius: 5
                            }}
                            value={getvalues}
                            onChange={(e) => filterForSearch(e.target.value)}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-end' }}>
                        <select
                            style={{
                                border: '1px solid #5EBFE3',
                                height: 35,
                                borderRadius: 5,
                       
                            }}
                            onChange={(e) => filterFormData(e.target.value)}
                            value={filterstatus}
                        >
                            <option value='reset'>=ໃຫ້ເລື່ອກສະຖານະ=</option>
                            <option value='0'>Create</option>
                            <option value='1'>In Progress</option>
                            <option value='2'>Received</option>
                            <option value='3'>Rejected</option>
                            <option value='4'>Requested</option>
                        </select>
                    </div>
                </div>
                {
                    showmodal === true ? (<>
                        <ModalDataIncoming
                            showmodal={showmodal}
                            handleClosemodal={handleClosemodal}
                            fileList={fileList}
                        />
                    </>) : null
                }

                <ModalTimeline
                    showtimeline={showtimeline}
                    handleClose={handleClose}
                    dataUser={dataUser}
                    dataLavel={dataLavel}
                    req_status='request'
                />
                <div style={{ width: "100%",marginTop:5 }}>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={dataList}
                            columns={columns}
                            pageSize={20}
                            disableSelectionOnClick
                        // loading={loading} // Pass the loading state to the DataGrid
                        />
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )
}
function ComponentUser({ req_id, OnloadInformationsTimeline, handleShow }) {
    const [datauser, setDataUser] = useState()
    const [active, setActive] = useState('')
    useEffect(() => {
        axios.get(`/api/document-management/incoming/get-approval-incoming/${req_id}`)
            .then((data) => {
                setDataUser([...data?.data?.users]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [req_id]);
    return (
        <div style={{
            display: 'flex', flexDirection: 'row', width: "100%"
        }}
            onClick={() => { OnloadInformationsTimeline(req_id) }}
        >
            {datauser?.map((item, index) => (
                <div key={index}>
                    <img src={item.image[0]}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            marginRight: -10,
                            boxShadow: active === 'user' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                        onMouseOver={() => setActive('user')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { handleShow() }}
                    />
                </div>
            ))}
        </div>
    );

}
