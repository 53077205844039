import axios from 'axios';
import { httpErrorHandler } from './middleware/httpErrorHandler';
export default function setAuthToken(token, setIsModalOpen) {
    try {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    } catch (err) {
        httpErrorHandler(err, setIsModalOpen)
    }
}