import React, {useState } from "react";

export default function Formmain() {
    return(
        <>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width:'100%'}}>
            <div style={{background:'#2eb85c',width:200,height:200,borderRadius:5}}>
                d
            </div>
            <div style={{background:'#e55353',width:200,borderRadius:5}}>
                d

            </div>
            <div style={{background:'#f9b115',width:200,borderRadius:5}}>
                d

           </div>
           <div style={{background:'#3399ff',width:200,borderRadius:5}}>
            d
           </div>

        </div>
       
        
        </>
    )
 

}
