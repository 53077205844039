import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
function MadalSaveusingForm(props) {
  
  
    const [active, setActive] = useState("");

    return (
        <div style={{width:'100%'}}>
            <Modal show={props.saveforusing} onHide={props.handleCloseSavle} style={{ paddingTop: 50 }} size="sm">
                <Modal.Header closeButton>
                    <span style={{ fontSize: 20, paddingTop: 10 }}>
                        Save As</span>
                </Modal.Header>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 10 }}>
                    <TextField
                        style={{ width: '90%', marginLeft: 10 }}
                        id="standard-select-currency-native"
                        select
                        value={props.saveconditions}
                        onChange={(e) => {props.handleChange(e.target.value) }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="ກະລຸກນາເລືອກປະເພດເອກະສານ"
                    >
                        <option >ກະລຸກນາເລືອກປະເພດເອກະສານ</option>
                        <option value='1'>ປະເພດເອກະສານໃຜໃນ</option>
                        <option value='2'>ປະເພດເອກະສານຂາອອກ</option>
                    </TextField>
                    <TextField
                        style={{ width: '90%', marginLeft: 10 }}
                        value={props.saveAs}
                        onChange={e => props.setSaveAs(e.target.value)}
                        id="standard-basic"
                        label="ຊື່ເອກສານ" />

                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, marginLeft: 10,marginTop:20 }}>
                    <div style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'20%',
                        cursor:'pointer',
                        border:'1px solid #5EBFE3',
                        borderRadius:5,
                        marginRight:20,
                        marginBottom:20,
                        height:30,
                        backgroundColor: active === 's' ? '#e9eef5' : ''
                        }}
                        onMouseOver={() => setActive('s')}
                        onMouseLeave={() => setActive(null)}
                        onClick={()=>{props.OnCreate()}}
                        >
                        <small>ບັນທືກ</small>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default MadalSaveusingForm