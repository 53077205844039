import React, { useEffect, useState } from "react";
import { DataGrid } from '@material-ui/data-grid';
import DoneIcon from '@material-ui/icons/Done';
import DescriptionIcon from '@material-ui/icons/Description';
import axios from "axios";
import moment from "moment";
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import ListusingRequestSettingOut from "../components/ListusingRequestSettingOut";
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SearchIcon from '@material-ui/icons/Search';
import { ModalTimeline } from "../components/ModalTimeline";
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export const ApprovedRequesOut = () => {
  const classes = useStyles();
  const [dataList, setDataList] = useState([])
  const [dataLavel, setDataLavel] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [loading, setLoading] = useState(true);
  const [listallemployee, setListallemployee] = useState([]);
  const [listdepartment, setListdepartment] = useState([])
  const [filterstatus, setFilterstatus] = useState('')
  const [conditionsfilter, setConditionsfilter] = useState('')
  const [dataListFilter, setDataListFilter] = useState([])
  const [active, setActive] = useState("");
  const [showtimeline, setShowtimeline] = useState(false)
  const handleClose = () => {
    setShowtimeline(false)
  }
  const handleShow = () => setShowtimeline(true);
  const OnloadFormData = () => {
    axios.get('/api/document-management/outgoing/setting/view-request').then((data) => {
      if (data?.data?.results === null) {
        setLoading(false)
      } else {
        setDataList([...data?.data?.results])
        setLoading(false)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadFormDataForFilter = () => {
    axios.get('/api/document-management/outgoing/setting/view-request').then((data) => {
      setDataListFilter([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }

  const OnloadInformationsTimeline = (e) => {
    console.log("request_id=", e)
    axios.get(`/api/document-management/outgoing/get-approval-outgoing/${e}`).then((data) => {
      console.log("data=", data)
      setDataLavel([...data?.data?.approvals])
      setDataUser([...data?.data?.users])
    }).catch((err) => {
      console.log(err)
    })
  }
  const filterForSearch = (e) => {
    if (conditionsfilter === 1) {
      let searchname = dataListFilter.filter((el) => el.title.toLowerCase().includes(e.toLowerCase()));
      if (!e) {
        OnloadFormData()
      } else {
        setDataList([...searchname])
      }

    } else {
      let searchname = dataList.filter((el) => el.title.toLowerCase().includes(e.toLowerCase()));
      if (!e) {
        OnloadFormData()
      } else {
        setDataList([...searchname])
      }

    }

  }
  const filterFormData = (e) => {
    setFilterstatus(e)
    setConditionsfilter(1)
    if (e === 'reset') {
      OnloadFormData()
    } else {
      let filterlist = dataListFilter.filter((el) => el.req_status == e)
      setDataList([...filterlist])
    }
  }
  const OnloadAllEmployee = () => {
    axios.get('/api/document-management/setting/all-employee').then((data) => {
      setListallemployee([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadDepartment = () => {
    axios.get('/api/document-management/no/get-department').then((data) => {
      setListdepartment([...data?.data?.results])
    }).catch((err) => (
      console.log(err)
    ))
  }
  const columns = [
    {
      field: 'title',
      headerName: 'ຊື່ເອກະສານ',
      width: 300,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ເອກະສານ</small>
      ),
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <DescriptionIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
          <h6 style={{ cursor: 'pointer' }} onClick={() => { OnloadInformationsTimeline(params.row.req_id) }}>
            {params.row.title}
          </h6>
        </div>
      )
    },
    {
      field: 'doc_no',
      headerName: 'No',
      width: 200,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ເລກທີ່</small>
      ),
      renderCell: (params) => (
        <Chip
          label="ເລກທີ່ເອກະສານ"
          avatar={<Avatar>{params.row.doc_no}</Avatar>}
          clickable
          color="primary"
          variant="outlined"
        />
      )
    },
    {
      field: 'created_at',
      headerName: 'ວັນທີ່',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່</small>
      ),
      renderCell: (params) => (
        <>
          {
            moment(params.row.created_at).format('DD-MM-YYYY')
          }
        </>
      )
    },
    {
      field: 'department_name',
      headerName: 'ພະແນກ',
      width: 250,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
      ),
      renderCell: (params) => (
        <small>{params.row.department_name}</small>
      )
    },

    {
      field: 'req_status',
      headerName: 'ສະຖານະ',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນາ</small>
      ),
      renderCell: (params) => (
        <>
          {
            params.row.req_status === 3 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <RepeatOneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#f000b9' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#f000b9' }}>Requested....</small>
              </div>
            )}
          {
            params.row.req_status === 4 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <AutorenewIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff9800' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff9800' }}>In Progress....</small>
              </div>
            )}
          {
            params.row.req_status === 5 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#10b981' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#10b981' }}>Approved....</small>
              </div>
            )}
          {
            params.row.req_status === 6 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Rejected....</small>
              </div>
            )}
          {
            params.row.req_status === 7 && (
              <div style={{ border: '1px solid #0288d1', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Canceled....</small>
              </div>
            )}
          {
            params.row.req_status === 8 && (
              <div style={{ border: '1px solid #0288d1', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>draft....</small>
              </div>
            )}

        </>
      ),
    },
    {
      field: 'employee_name',
      width: 200,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຜູ້ຮັບຜິຊອບ</small>
      ),
      renderCell: (params) => (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
        }}
        >
          <ComponentUser
            req_id={params.row.req_id}
            OnloadInformationsTimeline={OnloadInformationsTimeline}
            handleShow={handleShow}

          />
        </div>
      )
    },
    {
      field: 'actions',
      headerName: '#####',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}></small>
      ),
      renderCell: (params) => (
        <div >
          <ListusingRequestSettingOut
            req_id={params.row.req_id}
            approval_id={params.row.approval_id}
            doc_id={params.row.doc_id}
            docno_status={params.row.docno_status}
            signature_id={params.row.signature_id}
            req_status={params.row.req_status}
            OnloadFormData={OnloadFormData}
            listallemployee={listallemployee}
            listdepartment={listdepartment}
          />
        </div>
      )
    },
  ];

  useEffect(() => {
    OnloadFormData()
    OnloadFormDataForFilter()
    OnloadAllEmployee()
    OnloadDepartment();
  }, [])
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Loading..."
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ອະນຸມັດ</h5>
          <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
          <h5 style={{ color: '#007bff' }}>ເອກະສານຂາອອກ</h5>
        </div>
        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-start' }}>
            <input
              type="text"
              placeholder="Search field"
              style={{
                width: '50%',
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5
              }}
              onChange={(e) => filterForSearch(e.target.value)}

            />
            <div style={{ cursor: 'pointer', backgroundColor: active === 's' ? '#e9eef5' : '', width: '20%', border: '1px solid #5EBFE3', borderRadius: 5, marginLeft: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onMouseOver={() => setActive('s')}
              onMouseLeave={() => setActive(null)}
            >
              <SearchIcon style={{ color: '#5EBFE3' }} />
              <small>ຄົ້ນຫາ</small>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '60%', justifyContent: 'flex-end' }}>
            <select
              style={{
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5,
              }}
              onChange={(e) => filterFormData(e.target.value)}
              value={filterstatus}
            >
              <option value='reset'>=ໃຫ້ເລື່ອກສະຖານະ=</option>
              <option value='3'>Requested</option>
              <option value='4'>In Progress</option>
              <option value='5'>Approval</option>
              <option value='6'>Rejected</option>
            </select>
          </div>
        </div>
        <ModalTimeline
          showtimeline={showtimeline}
          handleClose={handleClose}
          dataUser={dataUser}
          dataLavel={dataLavel}
          req_status='request'
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={dataList}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
            // loading={loading} // Pass the loading state to the DataGrid
            />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}
function ComponentUser({ req_id, OnloadInformationsTimeline, handleShow }) {
  const [datauser, setDataUser] = useState()
  const [active, setActive] = useState('')
  useEffect(() => {
    axios.get(`/api/document-management/outgoing/get-approval-outgoing/${req_id}`)
      .then((data) => {
        setDataUser([...data?.data?.users]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [req_id]);
  return (
    <div style={{
      display: 'flex', flexDirection: 'row', width: "100%"
    }}
      onClick={() => { OnloadInformationsTimeline(req_id) }}
    >
      {datauser?.map((item, index) => (
        <div key={index}>
          <img src={item.image[0]}
            style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: -10,
              boxShadow: active === 'user' ? '1px 2px 9px #F4AAB9' : ''
            }}
            onMouseOver={() => setActive('user')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { handleShow() }}
          />
        </div>
      ))}
    </div>
  );

}
