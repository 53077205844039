import React, { useState } from "react";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import BorderHorizontalIcon from '@material-ui/icons/BorderHorizontal';
import BorderVerticalIcon from '@material-ui/icons/BorderVertical';
import GridOffIcon from '@material-ui/icons/GridOff';
export const TableMenu = (props) => {
    const [active, setActive] = useState('')
    return (
        <div style={{ width: '100%' }}>
            {
                props.showdeltable === true ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Frame</h6>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>X</small>
                                    <input

                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        value={props.datatable[0]?.positionX}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>Y</small>
                                    <input

                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        value={props.datatable[0]?.positionY}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>W</small>
                                    <input
                                        value={props.widthsize}
                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        onChange={(e) => props.changeTextwidth(e.target.value, 'width', props.index, props.index1,'one')}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>H</small>
                                    <input
                                        value={props.heightsize}
                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        onChange={(e) => props.changeTextheight(e.target.value, 'height', props.index, props.index1,'one')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Family</h6>
                            <select
                                style={{ border: '1px solid #5EBFE3', marginLeft: 10, marginRight: 10, borderRadius: 5 }}
                            >
                                <option>BebasNeue-Regular</option>
                                <option>NotoSans-Bold</option>
                            </select>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Color</h6>
                            <input
                                placeholder='#000000'
                                style={{
                                    border: '1px solid #5EBFE3',
                                    marginLeft: 10,
                                    marginRight: 10,
                                    borderRadius: 5
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Style</h6>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'b' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('b')}
                                        onMouseLeave={() => setActive(null)}
                                    >
                                        <FormatBoldIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'I' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('I')}
                                        onMouseLeave={() => setActive(null)}
                                    >
                                        <FormatItalicIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'C' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('C')}
                                        onMouseLeave={() => setActive(null)}
                                    >
                                        <FormatLineSpacingIcon />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Size</h6>
                            <input
                                placeholder='000000 PX'
                                style={{
                                    border: '1px solid #5EBFE3',
                                    marginLeft: 10,
                                    marginRight: 10,
                                    borderRadius: 5
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Control Panel</h6>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'm' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('m')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.MergeCellcolumn('one')}
                                    >
                                        <BorderVerticalIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'ms' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('ms')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.MergeCellRow('one')}
                                    >
                                        <BorderHorizontalIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'Cl' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('Cl')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.MergeCellRowAll('one')}
                                    >
                                        <FormatLineSpacingIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'T' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('T')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.deletetable(props.get_index,'one')}
                                    >
                                        <GridOffIcon />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>) : props.showdeltable1 === true ? (<>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Frame</h6>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>X</small>
                                    <input

                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        value={props.datatable1[0]?.positionX}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>Y</small>
                                    <input

                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        value={props.datatable1[0]?.positionY}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>W</small>
                                    <input
                                        value={props.widthsize}
                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        onChange={(e) => props.changeTextwidth(e.target.value, 'width', props.index, props.index1,'two')}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <small style={{
                                        marginLeft: 10,
                                        backgroundColor: 'white',
                                        width: 50,
                                        border: '1px solid #5EBFE3',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>H</small>
                                    <input
                                        value={props.heightsize}
                                        style={{
                                            width: '100%',
                                            marginRight: 10,
                                            border: '1px solid #5EBFE3',
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderLeft: 'none',
                                        }}
                                        onChange={(e) => props.changeTextheight(e.target.value, 'height', props.index, props.index1,'two')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Family</h6>
                            <select
                                style={{ border: '1px solid #5EBFE3', marginLeft: 10, marginRight: 10, borderRadius: 5 }}
                            >
                                <option>BebasNeue-Regular</option>
                                <option>NotoSans-Bold</option>
                            </select>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Color</h6>
                            <input
                                placeholder='#000000'
                                style={{
                                    border: '1px solid #5EBFE3',
                                    marginLeft: 10,
                                    marginRight: 10,
                                    borderRadius: 5
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Style</h6>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'b' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('b')}
                                        onMouseLeave={() => setActive(null)}
                                    >
                                        <FormatBoldIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'I' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('I')}
                                        onMouseLeave={() => setActive(null)}
                                    >
                                        <FormatItalicIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'C' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('C')}
                                        onMouseLeave={() => setActive(null)}
                                    >
                                        <FormatLineSpacingIcon />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Size</h6>
                            <input
                                placeholder='000000 PX'
                                style={{
                                    border: '1px solid #5EBFE3',
                                    marginLeft: 10,
                                    marginRight: 10,
                                    borderRadius: 5
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Control Panel</h6>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'm' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('m')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.MergeCellcolumn('two')}
                                    >
                                        <BorderVerticalIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'ms' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('ms')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.MergeCellRow('two')}
                                    >
                                        <BorderHorizontalIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'Cl' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('Cl')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.MergeCellRowAll('two')}
                                    >
                                        <FormatLineSpacingIcon />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 10,
                                        cursor: 'pointer',
                                        boxShadow: active === 'T' ? '1px 2px 9px #F4AAB9' : ''
                                    }}
                                        onMouseOver={() => setActive('T')}
                                        onMouseLeave={() => setActive(null)}
                                        onClick={() => props.deletetable(props.get_index,'two')}
                                    >
                                        <GridOffIcon />
                                    </div>
                                </div>

                            </div>
                        </div>


                    </>) : null
            }

        </div>
    )
}
