import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import SendIcon from '@material-ui/icons/Send';
import { LoginContext } from "../page/contexts/LoginContext";
export default function Notifications(props) {
    const {
        countitem
    } = useContext(LoginContext)
    console.log("countitem=", countitem)
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.openNotifications} onHide={props.handleCloseOpentNoti} style={{ paddingTop: 50 }}>
                <Modal.Header closeButton>
                    <span style={{ fontSize: 20, paddingTop: 10, color: "#007bff" }}>ລາຍການທີ່ຕ້ອງອະນຸມັດ</span>
                </Modal.Header>
                <div style={{ width: '100%' }}>
                    <ComponentRow
                        Navigate={props.Navigate}
                        handleCloseOpentNoti={props.handleCloseOpentNoti}
                        number_form={countitem.form}
                        request_form={countitem.request}
                        incoming={countitem.incoming}
                        outgoing={countitem.outgoing}
                        outgoing_no={countitem.outgoing_no}
                        request_no={countitem.request_no}
                    />
                </div>
            </Modal>
        </div>
    )
}
function ComponentRow({ Navigate, handleCloseOpentNoti, number_form, request_form, incoming, outgoing, outgoing_no, request_no }) {
    const [active, setActive] = useState('')
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
                number_form !== 0 ? (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#DBEAFE',
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <div style={{ backgroundColor: 'red', height: 50, width: 10, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid red' }}>
                                </div>
                                <small
                                    style={{
                                        fontSize: 20,
                                        color: "#007bff",
                                        marginTop: 10,
                                        marginLeft: 10
                                    }}>ສ້າງແບບຟອມມີ {number_form} ລາຍກັນ</small>
                            </div>
                            <div style={{
                                marginRight: 5,
                                borderRadius: '50%',
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: active === 'd' ? '1px 2px 9px #F4AAB9' : '',

                            }}
                                onMouseOver={() => setActive('d')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { handleCloseOpentNoti(); Navigate("/RequestForm") }}
                            >
                                <SendIcon style={{ color: '#007bff' }} />
                            </div>
                        </div>

                    </>) : null
            }
            {
                request_form !== 0 ? (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#DBEAFE',
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <div style={{ backgroundColor: 'red', height: 50, width: 10, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid red' }}>
                                </div>
                                <small
                                    style={{
                                        fontSize: 20,
                                        color: "#007bff",
                                        marginTop: 10,
                                        marginLeft: 10
                                    }}>ເອກະສານໃຜໃນ {request_form} ລາຍກັນ</small>
                            </div>
                            <div style={{
                                marginRight: 5,
                                borderRadius: '50%',
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: active === 're' ? '1px 2px 9px #F4AAB9' : '',

                            }}
                                onMouseOver={() => setActive('re')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { handleCloseOpentNoti(); Navigate("/ApprovedRequest") }}
                            >
                                <SendIcon style={{ color: '#007bff' }} />
                            </div>
                        </div>

                    </>) : null
            }
            {
                incoming !== 0 ? (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#DBEAFE',
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <div style={{ backgroundColor: 'red', height: 50, width: 10, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid red' }}>
                                </div>
                                <small
                                    style={{
                                        fontSize: 20,
                                        color: "#007bff",
                                        marginTop: 10,
                                        marginLeft: 10
                                    }}>ເອກະສານຂາເຂົ້າ {incoming} ລາຍກັນ</small>
                            </div>
                            <div style={{
                                marginRight: 5,
                                borderRadius: '50%',
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: active === 'in' ? '1px 2px 9px #F4AAB9' : '',

                            }}
                                onMouseOver={() => setActive('in')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { handleCloseOpentNoti(); Navigate("/ApprovedRequestIn") }}
                            >
                                <SendIcon style={{ color: '#007bff' }} />
                            </div>
                        </div>

                    </>) : null
            }
            {
                outgoing !== 0 ? (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#DBEAFE',
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <div style={{ backgroundColor: 'red', height: 50, width: 10, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid red' }}>
                                </div>
                                <small
                                    style={{
                                        fontSize: 20,
                                        color: "#007bff",
                                        marginTop: 10,
                                        marginLeft: 10
                                    }}>ເອກະສານຂາອອກ {outgoing} ລາຍກັນ</small>
                            </div>
                            <div style={{
                                marginRight: 5,
                                borderRadius: '50%',
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: active === 'our' ? '1px 2px 9px #F4AAB9' : '',

                            }}
                                onMouseOver={() => setActive('our')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { handleCloseOpentNoti(); Navigate("/ApprovedRequestOut") }}
                            >
                                <SendIcon style={{ color: '#007bff' }} />
                            </div>
                        </div>

                    </>) : null
            }
            {
                outgoing_no !== 0 ? (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#DBEAFE',
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <div style={{ backgroundColor: 'red', height: 50, width: 10, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid red' }}>
                                </div>
                                <small
                                    style={{
                                        fontSize: 20,
                                        color: "#007bff",
                                        marginTop: 10,
                                        marginLeft: 10
                                    }}>ໃສ່ເລກທີ່ເອກະສານຂາອອກ {outgoing_no} ລາຍກັນ</small>
                            </div>
                            <div style={{
                                marginRight: 5,
                                borderRadius: '50%',
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: active === 'dd' ? '1px 2px 9px #F4AAB9' : '',

                            }}
                                onMouseOver={() => setActive('dd')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { handleCloseOpentNoti(); Navigate("/doc_noOut") }}
                            >
                                <SendIcon style={{ color: '#007bff' }} />
                            </div>
                        </div>

                    </>) : null
            }
            {
                request_no !== 0 ? (
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#DBEAFE',
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <div style={{ backgroundColor: 'red', height: 50, width: 10, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: '1px solid red' }}>
                                </div>
                                <small
                                    style={{
                                        fontSize: 20,
                                        color: "#007bff",
                                        marginTop: 10,
                                        marginLeft: 10
                                    }}>ໃສ່ເລກທີ່ເອກະສານໃຜໃນ {request_no} ລາຍກັນ</small>
                            </div>
                            <div style={{
                                marginRight: 5,
                                borderRadius: '50%',
                                width: 50,
                                height: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: active === 'co' ? '1px 2px 9px #F4AAB9' : '',

                            }}
                                onMouseOver={() => setActive('co')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { handleCloseOpentNoti(); Navigate("/Doc_no") }}
                            >
                                <SendIcon style={{ color: '#007bff' }} />
                            </div>
                        </div>

                    </>) : null
            }


        </div>
    );

}

