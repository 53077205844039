import React, { useEffect, useState, useContext } from "react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import { Modal } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from "react-bootstrap";
import Checkbox from '@material-ui/core/Checkbox';
import { LoginContext } from "../page/contexts/LoginContext";
import { ModalOpenNotdata } from "./ModalOpenNotdata";
import { ModalOpenSetting } from "./ModalOpenSetting";
import { ModalOpenRequestSuccessully } from "./ModalOpenRequestSuccessully";
import { ModalSave } from "./ModalSave";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));


const ITEM_HEIGHT = 48;

export default function ListusingRequestSettingIn(props) {
  const classes = useStyles();
  const req_uid = props.req_id
  const approval_id = props.approval_id
  const doc_no = props.doc_no
  const docno_status = props.docno_status
  const OnloadFormData = props.OnloadFormData
  const req_status = props.req_status
  const signature_id = props.signature_id
  const listdepartment = props.listdepartment

  const listallemployee = props.listallemployee
  const [ducument_no, setDucument_no] = useState(false)
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [openingsetting, setOpeningsetting] = useState(false)
  const [showSave, setShowSave] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading,] = useState(false);
  const [employee_id, setEmployee_id] = useState('')
  const [number, setNumber] = useState()
  const [g_department_id, setG_department_id] = useState('')
  const [employee, setEmployee] = useState([]);
  const [data_emp, setData_emp] = useState([]);
  const [listshowemployee, setListshowemployee] = useState([])
  const { setId, setShowUserEditFormScreen } = useContext(LoginContext)
  const [isdisabled1, setIsdisabled1] = useState(false)
  const [isdisabled2, setIsdisabled2] = useState(false)
  const [isdisabled3, setIsdisabled3] = useState(false)
  const [isdisabled5, setIsdisabled5] = useState(false)
  const [IsModalOpen, setIsModalOpen] = useState(false)
  const [modalstatus, setModalstatus] = useState('')
  const [successfully, setSuccessfully] = useState(false)
  const [active, setActive] = useState("");
  const OnChooseNumber = (e) => {
    setNumber(e)

  }

  const [createSetting, setCreateSetting] = useState(3)
  const [approval_status, setApproval_status] = useState('')
  const [status, setStatus] = useState('')

  const handleApprove = e => {
    if (e.target.checked) {
      setCreateSetting(1)
      setIsdisabled2(true)
      setIsdisabled3(true)
      setIsdisabled5(true)

      setApproval_status(1)
    } else {
      setIsdisabled2(false)
      setIsdisabled3(false)
      setIsdisabled5(false)

      setCreateSetting('')
      setApproval_status('')
    }
  }
  const handleChecked = e => {
    if (e.target.checked) {
      setIsdisabled1(true)
      setIsdisabled3(true)
      setIsdisabled5(true)
      setCreateSetting(1)
      setApproval_status(0)
    } else {
      setIsdisabled1(false)
      setIsdisabled3(false)
      setIsdisabled5(false)

      setApproval_status('')
      setCreateSetting('')
    }
  }
  const handleReject = e => {
    if (e.target.checked) {
      setCreateSetting(2)
      setIsdisabled1(true)
      setIsdisabled2(true)
      setIsdisabled3(true)

    } else {
      setIsdisabled1(false)
      setIsdisabled2(false)
      setIsdisabled3(false)


    }
  }
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOpenting = () => {
    setOpeningsetting(false)

  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosedel = () => {
    setShow(false);
    setIsdisabled1(false)
    setIsdisabled2(false)
    setIsdisabled3(false)
    setIsdisabled5(false)
    setEmployee([])
    setData_emp([])
    setListshowemployee([])
    setG_department_id([])
  }
  const OnOptionsDepart = (e) => {
    setCreateSetting(3)
    setIsdisabled1(true)
    setIsdisabled2(true)

    setIsdisabled5(true)
    setG_department_id(e)
    let list = listallemployee.filter((el) => el.department_id == e)
    setListshowemployee([...list])
  }
  const handleShow = () => {
    setAnchorEl(null)
    setShow(true)
  };
  const Successfully = () => {
    setSuccessfully(false)
    setModalstatus('')
  }
  const CreateSenddate = (req_id, approval_id, doc_no, req_status, signature_id) => {
    if (doc_no === null) {
      setAnchorEl(null)
      setDucument_no(true)
      return;
    } else {
      if (approval_id === null) {
        if (req_status === 4) {
          setAnchorEl(null)
          setOpeningsetting(true)
        } else if (req_status === 1) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(4)
          return;

        } else if (req_status === 2) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(7)
          return;

        } else if (req_status === 3) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(6)
          return;

        }
        return;
      } else {
        if (req_status == 1) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(4)
          return;

        } else if (req_status === 2) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(7)
          return;

        } else if (req_status === 3) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(6)
          return;

        } else {
          setAnchorEl(null)
          setShowSave(true)
          setIsLoading(true)
          let informdata = {
            req_id: req_id,
            approver: approval_id,
            comment: '',
          }
          axios.post('/api/document-management/incoming/setting/insert-setting', informdata).then((data) => {

            OnloadFormData();
          }).catch((err) => {
            console.log(err)

          }).finally(() => {
            const autoCloseTimeLoading = 1000;
            const timeoutId = setTimeout(() => {
              setIsLoading(false)
            }, autoCloseTimeLoading);
            const autoCloseTime = 2000;
            const timeout = setTimeout(() => {
              setShowSave(false)
            }, autoCloseTime);
            return () => clearTimeout(timeout, timeoutId);
          });

        }
      }
    }
  }
  const handleCloseNotdata = () => {
    setIsModalOpen(false)
  }
  const Creatappoved = (req_uid) => {
    let dataApp = {
      req_uid: req_uid,
      approval_status: approval_status,
      comments: ''
    }
    console.log('dataApp=', dataApp)
    setIsLoading(true)
    axios.post('/api/document-management/incoming/setting/update-approve-status', dataApp).then((data) => {
      OnloadFormData()
      setIsdisabled1(false)
      setIsdisabled2(false)
      setIsdisabled3(false)
      setShow(false)
      setCreateSetting('')
      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const CreatRejected = (req_uid) => {
    let datainf = {
      req_uid: req_uid,
      comment: ''
    }
    console.log("Rejectdata=", datainf)
    setIsLoading(true)
    axios.post('/api/document-management/incoming/setting/update-Rejected-Status', datainf).then((data) => {
      OnloadFormData();
      setShow(false)
      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const onEditform = (id) => {
    // Navigate(`/UserEditFrom/${id}`)
    setId(id)
    setShowUserEditFormScreen(true)
  }


  const CreateEmployeeApproved = (get_req_uid) => {
    setIsLoading(true)
    if (createSetting === 1) {
      console.log("appoved")
      Creatappoved(get_req_uid)
    } else if (createSetting === 2) {
      CreatRejected(get_req_uid)

    } else {
      let createdate = {
        max_approval: number,
        req_id: get_req_uid,
        details: employee
      }

      if (createdate.details.length === 0) {
        handleClosedel();
        setIsModalOpen(true);
        setIsLoading(false)
        return;
      } else {
        axios.post('/api/document-management/incoming/apprval/create-approval', createdate).then((data) => {
          console.log("Data=", data)
          setShow(false);
          setIsLoading(false)
          setNumber('');
          setG_department_id('');
          setEmployee_id('');
          OnloadFormData();
        }).catch((err) => {
          console.log(err)
        })

      }
    }
  }
  return (
    <div>
      <ModalOpenNotdata
        IsModalOpen={IsModalOpen}
        handleCloseNotdata={handleCloseNotdata}
      />
      <ModalOpenSetting
        openingsetting={openingsetting}
        handleCloseOpenting={handleCloseOpenting}
      />
      <ModalOpenRequestSuccessully
        successfully={successfully}
        Successfully={Successfully}
        modalstatus={modalstatus}
      />
      {
        showSave === true ? (<>
          <ModalSave
            showSave={showSave}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </>) : null
      }

      <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
        <Modal.Header closeButton>
          <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>Setting</span>
        </Modal.Header>
        {/* {JSON.stringify(data_emp)} */}
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
              <input type="checkbox"
                disabled={isdisabled1}
                onChange={handleApprove}
                style={{
                  height: 20,
                  width: 20,
                  marginTop: 20,
                  cursor: 'pointer',
                  border: '2px solid #5ebfe3',
                }}
              />
              <small style={{ marginTop: 15, fontSize: 20, marginLeft: 10 }}>ອະນຸມັດ</small>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input type="checkbox"
                disabled={isdisabled2}
                onChange={handleChecked}
                style={{
                  height: 20,
                  width: 20,
                  marginTop: 20,
                  cursor: 'pointer',
                  border: '2px solid #5ebfe3',
                }} />
              <small style={{ marginTop: 15, fontSize: 20, marginLeft: 10 }}>ກວດເອກະສານ</small>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input type="checkbox"
                disabled={isdisabled5}
                onChange={handleReject}
                style={{
                  height: 20,
                  width: 20,
                  marginTop: 20,
                  cursor: 'pointer',
                  border: '2px solid #5ebfe3',
                }}
              />
              <small style={{ marginTop: 15, fontSize: 20, marginLeft: 10, marginRight: 10 }}>ປະຕິເສດ</small>

            </div>
          </div>
          <div style={{ width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 10, marginRight: 10 }}>
              <select
                onChange={(e) => OnChooseNumber(e.target.value)}
                value={number}
                disabled={isdisabled3}
                style={{
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderRadius: 5,
                  width:'50%',
                  marginRight:10


                }}
              >
                <option>ກະລຸນາເລື່ອກຈຳນວນຄົນ</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>

              <select
                style={{
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderRadius: 5,
                  width:'50%',
                  marginLeft:10

                }}
                disabled={isdisabled3}
                onChange={(e) => OnOptionsDepart(e.target.value)}
                value={g_department_id}
              >
                <option>ກະລຸນາເລື່ອກພະແນກ</option>
                {
                  listdepartment && listdepartment.map((item, index) => {
                    return (
                      <option key={index} value={item?.department_id}>{item?.department_name}</option>
                    )
                  })
                }
              </select>
            </div>

          </div>

          <div style={{ height: 300, width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginLeft: 10, marginRight: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                listshowemployee.map((data, index) => {

                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <small style={{ cursor: 'pointer' }} >

                        <ReadCheckbox
                          data={data}
                          setEmployee={setEmployee}
                          setData_emp={setData_emp}
                        />
                      </small>
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginRight: 10, marginLeft: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                data_emp.map((data, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <Checkbox
                        defaultChecked
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #5ebfe3',
              borderRadius: 3,
              marginRight: 10,
              marginBottom: 20,
              width: 100,
              height: 35,
              cursor: 'pointer',
              backgroundColor: active === 'save' ? '#e9eef5' : '', // Fix the condition here
            }}
              onMouseOver={() => setActive('save')}
              onMouseLeave={() => setActive(null)}
              onClick={() => { CreateEmployeeApproved(req_uid) }}
            >
              <small>
                {
                  !isLoading ? (<>
                    ບັນທືກ
                  </>) : (
                    <>
                      {
                        <Spinner animation="border" variant="light" size='sm' style={{ color: 'green' }} />
                      }
                    </>)
                }
              </small>
            </div>
          </div>
        </div>
      </Modal>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          docno_status === 1 ? (<>
            <MenuItem ><SettingsIcon style={{ color: "#5EBFE3" }} /><small style={{ marginLeft: 10, }}>Setting</small> </MenuItem>
            <MenuItem ><EditIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }}>Edit</small></MenuItem>
          </>) : (<>
            {
              docno_status == 2 ? (<>
                <MenuItem ><SettingsIcon style={{ color: "#5EBFE3" }} /><small style={{ marginLeft: 10, }} onClick={() => { handleShow(req_uid) }}>Setting</small> </MenuItem>
                <MenuItem ><EditIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }} onClick={() => onEditform(req_uid)}>Edit</small></MenuItem>
              </>) : (<>
                <MenuItem ><SettingsIcon style={{ color: "#5EBFE3" }} /><small style={{ marginLeft: 10, }} onClick={() => { handleShow(req_uid) }}>Setting</small> </MenuItem>
                <MenuItem ><EditIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }}>Edit</small></MenuItem>
              </>)
            }

          </>)
        }
        <MenuItem ><SendIcon style={{ color: "#5EBFE3" }} /> <small style={{ marginLeft: 10, }} onClick={() => CreateSenddate(req_uid, approval_id, doc_no, req_status, signature_id)}  >Send</small></MenuItem>
        <MenuItem><EditAttributesIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }}>Rename</small></MenuItem>
        <MenuItem><GetAppIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }}>Downlaod</small></MenuItem>
      </Menu>
    </div>
  );

}
function ReadCheckbox({ data, setEmployee, setData_emp }) {
  const [Ischecked, setIschecked] = useState(false)

  const handleChange = (event, employee_id, name) => {
    if (event.target.checked === true) {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => [...prevEmployee, { approver_id: employee_id }]);
      setData_emp(prevEmployee_data => [...prevEmployee_data, { approver_id: employee_id, name: name }]);
    } else {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
      setData_emp(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
    }
  };

  return (
    <Checkbox
      checked={Ischecked}
      onChange={(event) => handleChange(event, data?.employee_id, data?.name)}
      color="primary"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};