import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export const ModalDataIncoming = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.showmodal} onHide={props.handleClosemodal} style={{ paddingTop: 50 }}>
                <Modal.Header closeButton>
                    <span style={{ fontSize: 25, fontWeight: 'bold', paddingTop: 10 ,color:"#007bff"}}>ເອກະສານ</span>
                </Modal.Header>
                <div style={{ width: '100%' }}>

                    {
                        props.fileList.map((item, index) => {
                            return (
                                <ComponentRow
                                    key={index}
                                    item={item}
                                />
                            )
                        })

                    }

                </div>

            </Modal>
        </div>
    )
}
function ComponentRow({ item }) {
    const [active, setActive] = useState('')
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: '#DBEAFE',
                marginLeft: 10,
                marginRight: 10,
                marginTop: 5,
                marginBottom: 10,
                borderRadius: 5
            }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {
                        item.file_type.startsWith('image/') ? (
                            <>
                                <small><img src='/assets/images/file-png-solid-240.png' style={{ width: 50, height: 50 }} /></small>
                            </>) : item.file_type === 'application/pdf' ? (<>
                                <small><img src='/assets/images/file-pdf-solid-240.png' style={{ width: 50, height: 50 }} /></small>
                            </>) : item.file_type === 'application/zip' ? (
                                <>
                                    <small><img src='/assets/images/file-zip-solid-240.jpeg' style={{ width: 50, height: 50 }} /></small>
                                </>) : (<>
                                    <small><img src='/assets/images/file-xlsx-solid-240.jpeg' style={{ width: 50, height: 50 }} /></small>
                                </>)
                    }

                    <small
                        style={{
                            fontSize: 20,
                            color: "#007bff",

                            marginTop: 10,
                            marginLeft: 10
                        }}>{item?.original_name}</small>
                </div>
                <a href={item?.image} style={{ textDecoration: 'none', display: 'inline-block',marginTop:10 }}>
                    <small style={{
                        fontSize: 20,
                        color: "#007bff",
                        fontWeight: 'bold',
                        marginTop: 10,
                        marginRight: 10,
                        cursor: 'pointer',
                        marginBottom: 10,
                        marginLeft: 10,
                        borderRadius: 3,
                        textDecoration: active === 'd' ? 'underline' : 'none', // Add underline on hover
                    }}
                        onMouseOver={() => setActive('d')}
                        onMouseLeave={() => setActive(null)}
                    >
                        Download
                    </small></a>
            </div>
        </div>
    );

}
