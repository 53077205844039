import React, { useContext } from "react";
import { DataGrid } from '@material-ui/data-grid';
import { useParams } from "react-router-dom";
import { LoginContext } from "../page/contexts/LoginContext";
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useNavigate } from "react-router-dom";

import moment from "moment";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
        maxWidth: 200,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
export const Departmentmobile = () => {
    
    const classes = useStyles();
    const Navigate = useNavigate();
    const { dataList, setShowUserusingFormScreen, setId } = useContext(LoginContext)
    console.log("dataList=", dataList)
    const onViewForm = (id) => {
        // setShowUserusingFormScreen(true)
        Navigate(`/EditFormMobile/${id}`)
        setId(id)
    }
    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 250,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ເອກະສານ</small>
            ),

            renderCell: (params) => (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <FileCopyIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
                    <h6 style={{ cursor: 'pointer' }} onClick={() => { onViewForm(params.row.form_uid) }}>
                        {params.row.form_name}
                    </h6>
                </div>
            )
        },
        {
            field: 'req_status',
            headerName: 'Status',
            width: 150,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນາ</small>
            ),
            renderCell: (params) => (
                <>
                    <div style={{
                        backgroundColor: '#3399ff',
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        height: 25,
                        cursor: 'pointer'

                    }}>

                        <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: 'white' }}>Dwonload</small>
                    </div>

                </>
            ),
        },
    ];
    return (
        <>
            <div style={{ width: '100%', marginTop: 20, borderRadius: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',paddingTop:20 }}>
                    <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ແບບຟອມທີ່ພ້ອມໃຊ້</h5>
                    <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
                    <h5 style={{ color: '#007bff' }}>ແບບຟອມ</h5>
                </div>
                <div style={{ width: '100%', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={dataList}
                            columns={columns}
                            pageSize={20}
                            disableSelectionOnClick
                        />
                    </div>
                </div>
            </div>
        </>

    )
}
