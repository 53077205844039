import { Modal } from "react-bootstrap";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export const ModalTimeline = (props) => {
  const classes = useStyles();
  return (
    <Modal show={props.showtimeline} onHide={props.handleClose} style={{ paddingTop: 50 }}>
      <Modal.Header closeButton>
        <span style={{ fontSize: 25, fontWeight: 25, paddingTop: 10 }}>ຜູ້ອະນຸມັດ</span>
      </Modal.Header>
      <div style={{ width: '100%' }}>
        <Timeline align="alternate">
          {
            props.req_status === 'request' ? (
              <>
                {props.dataLavel && props.dataLavel.map((item, index) => {
                  console.log("item=", item)
                  return (<>
                    <TimelineItem key={index}>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          ວັນທີ່:{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                        </Typography>
                        {
                          item?.levels == 0 ? (
                            <>
                              <Typography color="textSecondary" style={{ marginLeft: 10 }} >
                                ສ້າງແບບຟອມ..
                              </Typography>
                            </>
                          ) : item?.levels == 1 ? (
                            <>
                              {
                                item?.req_status == 0 || item?.req_status == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>

                                  </>
                                ) : item?.req_status === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                    </div>
                                  </Typography>

                                </>) : item?.req_status === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 2 ? (
                            <>
                              {
                                item?.req_status == 0 || item?.req_status == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>

                                  </>
                                ) : item?.req_status === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                    </div>
                                  </Typography>

                                </>) : item?.req_status === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 3 ? (
                            <>
                              {
                                item?.req_status == 0 || item?.req_status == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>

                                  </>
                                ) : item?.req_status === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                    </div>
                                  </Typography>

                                </>) : item?.req_status === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 4 ? (
                            <>
                              {
                                item?.req_status == 0 || item?.req_status == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                    </div>
                                  </Typography>

                                </>) : item?.req_status === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 5 ? (
                            <>
                              {
                                item?.req_status == 0 || item?.req_status == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.req_status === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                    </div>
                                  </Typography>

                                </>) : item?.req_status === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : null
                        }
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot style={{ backgroundColor: "#007bff" }}>
                          {
                            item?.levels == 0 ? (
                              <>
                                <AccessAlarmIcon />

                              </>
                            ) : item?.levels == 1 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    )
                                }
                              </>
                            ) : item?.levels == 2 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    )
                                }

                              </>
                            ) : item?.levels == 3 ? (
                              <>
                                {
                                  item?.formstatus == 0 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    )
                                }

                              </>
                            ) : item?.levels == 4 ? (
                              <>
                                {
                                  item?.formstatus == 0 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    )
                                }

                              </>
                            ) : item?.levels == 5 ? (
                              <>
                                {
                                  item?.formstatus == 0 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    )
                                }
                              </>

                            ) : null
                          }
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <ComponentCell
                            item={item}
                            dataUser={props.dataUser}
                          />
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  </>)
                })}
              </>) : (
              <>
                {props.dataLavel && props.dataLavel.map((item, index) => {
                  return (<>
                    <TimelineItem key={index}>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          ວັນທີ່ສ້າງ:{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                        </Typography>
                        {
                          item?.levels == 0 ? (
                            <>
                              <Typography color="textSecondary" style={{ marginLeft: 10 }} >
                                ສ້າງແບບຟອມ..
                              </Typography>

                            </>
                          ) : item?.levels == 1 ? (
                            <>
                              {
                                item?.formstatus == 0 || item?.formstatus == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >


                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                    </div>
                                  </Typography>

                                </>) : item?.formstatus === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >


                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 2 ? (
                            <>
                              {
                                item?.formstatus == 0 || item?.formstatus == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Rejected</small>
                                    </div>
                                  </Typography>

                                </>) : item?.formstatus === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                      Draft
                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Draft</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 3 ? (
                            <>
                              {
                                item?.formstatus == 0 || item?.formstatus == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> In Progress</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected</small>
                                    </div>
                                  </Typography>

                                </>) : item?.formstatus === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Draft</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 4 ? (
                            <>
                              {
                                item?.formstatus == 0 || item?.formstatus == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Waiting</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> In Progress</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Approval</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Rejected</small>
                                    </div>
                                  </Typography>

                                </>) : item?.formstatus === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Draft</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : item?.levels == 5 ? (
                            <>
                              {
                                item?.formstatus == 0 || item?.formstatus == 1 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 2 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> In Progress</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 3 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval</small>
                                      </div>
                                    </Typography>
                                  </>
                                ) : item?.formstatus === 4 ? (<>
                                  <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                    <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                      <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Rejected</small>
                                    </div>
                                  </Typography>

                                </>) : item?.formstatus === 6 ? (
                                  <>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Draft</small>
                                      </div>
                                    </Typography>
                                  </>) : null
                              }
                            </>
                          ) : null
                        }
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot style={{ backgroundColor: "#007bff" }}>
                          {
                            item?.levels == 0 ? (
                              <>
                                <AccessAlarmIcon />

                              </>
                            ) : item?.levels == 1 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : item?.formstatus === 2 ? (
                                      <>
                                        <AutorenewIcon />
                                      </>
                                    ) : item?.formstatus === 3 ? (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    ) : item.formstatus === 4 ? (
                                      <>
                                        <ErrorIcon />
                                      </>

                                    ) : item.formstatus === 6 ? (
                                      <>
                                        <ReportProblemIcon />
                                      </>
                                    ) : null
                                }
                              </>
                            ) : item?.levels == 2 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : item?.formstatus === 2 ? (
                                      <>
                                        <AutorenewIcon />
                                      </>
                                    ) : item?.formstatus === 3 ? (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    ) : item.formstatus === 4 ? (
                                      <>
                                        <ErrorIcon />
                                      </>

                                    ) : item.formstatus === 6 ? (
                                      <>
                                        <ReportProblemIcon />
                                      </>
                                    ) : null
                                }

                              </>
                            ) : item?.levels == 3 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : item?.formstatus === 2 ? (
                                      <>
                                        <AutorenewIcon />
                                      </>
                                    ) : item?.formstatus === 3 ? (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    ) : item.formstatus === 4 ? (
                                      <>
                                        <ErrorIcon />
                                      </>

                                    ) : item.formstatus === 6 ? (
                                      <>
                                        <ReportProblemIcon />
                                      </>
                                    ) : null
                                }

                              </>
                            ) : item?.levels == 4 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : item?.formstatus === 2 ? (
                                      <>
                                        <AutorenewIcon />
                                      </>
                                    ) : item?.formstatus === 3 ? (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    ) : item.formstatus === 4 ? (
                                      <>
                                        <ErrorIcon />
                                      </>

                                    ) : item.formstatus === 6 ? (
                                      <>
                                        <ReportProblemIcon />
                                      </>
                                    ) : null
                                }
                              </>
                            ) : item?.levels == 5 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ?
                                    (
                                      <>
                                        <AccessAlarmIcon />
                                      </>
                                    ) : item?.formstatus === 2 ? (
                                      <>
                                        <AutorenewIcon />
                                      </>
                                    ) : item?.formstatus === 3 ? (
                                      <>
                                        <CheckCircleOutlineIcon />
                                      </>
                                    ) : item.formstatus === 4 ? (
                                      <>
                                        <ErrorIcon />
                                      </>

                                    ) : item.formstatus === 6 ? (
                                      <>
                                        <ReportProblemIcon />
                                      </>
                                    ) : null
                                }

                              </>
                            ) : null
                          }
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <ComponentCell
                            item={item}
                            dataUser={props.dataUser}
                          />
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  </>)
                })}

              </>)
          }

        </Timeline>
      </div>
    </Modal>
  )
}
function ComponentCell({ item, dataUser }) {
  const filter = dataUser.filter((el) => el.level_uid == item?.level_uid);
  return (<>
    {
      filter.map((data, index) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} key={index}>
              <div style={{ backgroundColor: '#e9eef5', width: 50, height: 50, borderRadius: '50%', }}>
                <img src={data.image[0]} style={{ width: 50, height: 50, borderRadius: '50%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <small>{data?.create_employee_name}</small>
              </div>
            </div>
          </>

        )
      })
    }
  </>)
}
