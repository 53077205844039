import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DeleteIcon from '@material-ui/icons/Delete';
export const ModalOpendel = (props) => {
    const [active, setActive] = useState("");
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.opendel} onHide={props.handleCloseDelete} style={{ paddingTop: 50 }} >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ border: '3px solid red', width: 50, height: 50, borderRadius: '50%', marginTop: 40, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <DeleteIcon style={{ color: 'red', marginLeft: 10, marginTop: 10 }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <small style={{ fontSize: 20 }}>ທ່ານຕ້ອງການລືບເເບບຟອມເອກສານສະບັບນີ້ອອກແທ້ບໍ...?</small>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 40, marginTop: 40 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: 50,
                                height: 40,
                                width: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 5,
                                backgroundColor: active === 'd' ? '#2196F3' : '#007BFF', // Fix the condition here
                            }}
                            onMouseOver={() => setActive('d')}
                            onMouseLeave={() => setActive(null)}
                            onClick={()=>{props.CreateDeleted(props.form_uid)}}
                        >
                            <small style={{ color: 'white', fontSize: 19 }}>ຕ້ອງການລືບ</small>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginRight: 50,
                                height: 40,
                                width: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 5,
                                backgroundColor: active === 'N' ? '#2196F3' : '#007BFF', // Fix the condition here
                            }}
                            onMouseOver={() => setActive('N')}
                            onMouseLeave={() => setActive(null)}
                            onClick={() => { props.handleCloseDelete() }}
                        >
                            <small style={{ color: 'white', fontSize: 19 }}>ບໍ່ຕ້ອງານ</small>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}
