import React, { useState, useContext } from "react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import { Modal } from "react-bootstrap";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";
import { Spinner } from "react-bootstrap";

import { LoginContext } from "../page/contexts/LoginContext";


import { ModalDocument } from "./ModalDocument";
import { ModalOpenRequestSuccessully } from "./ModalOpenRequestSuccessully";
import { ModalOpenSetting } from "./ModalOpenSetting";
import Checkbox from '@material-ui/core/Checkbox';
import { ModalOpenNotdata } from "./ModalOpenNotdata";
import { ModalSignature } from "./ModalSignature";
import { ModalSave } from "./ModalSave";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;

export default function ListusingSettingOut(props) {
  const classes = useStyles();
  const req_uid = props.req_uid
  const approval_id = props.approval_id
  const signature_id = props.signature_id
  const doc_no = props.doc_no
  const listallemployee = props.listallemployee
  const listdepartment = props.listdepartment
  const req_status = props.req_status
  const docno_status = props.docno_status
  const OnloadFormData = props.OnloadFormData
  const [ducument_no, setDucument_no] = useState(false)
  const [signature_user, setSignature_user] = useState(false)
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [openingsetting, setOpeningsetting] = useState(false)
  const [employee, setEmployee] = useState([]);
  const [data_emp, setData_emp] = useState([]);
  const [active, setActive] = useState("");
  const [showSave, setShowSave] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading,] = useState(false);
  const [listempl, setListempl] = useState([])
  const [depart_id, setDepart_id] = useState('')
  const [employee_id, setEmployee_id] = useState('')
  const [number, setNumber] = useState()
  const [g_department_id, setG_department_id] = useState('')
  const [listshowemployee, setListshowemployee] = useState([])
  const { setId, setShowUserEditFormScreen,setDocument_status } = useContext(LoginContext)
  const [modalstatus, setModalstatus] = useState('')
  const [IsModalOpen, setIsModalOpen] = useState(false)
  const [successfully, setSuccessfully] = useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosedel = () => {
    setShow(false);
  }
  const handleClosedel1 = () => {
    setShow1(false);
  }

  const handleCloseOpenting = () => {
    setOpeningsetting(false)

  }
  const Successfully = () => {

    setSuccessfully(false)
    setModalstatus('')
  }
  const handleCloseDucument_on = () => {
    setDucument_no(false)
  }
  const handleClosesignature_user = () => {
    setSignature_user(false)
  }
  const OnOptions = (e) => {
    setDepart_id(e)
    axios.get(`/api/document-management/no/Get-Employee-By-DepartmentID/${e}`).then((data) => {
      setListempl([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnOptionsDepart = (e) => {
    setG_department_id(e)
    let list = listallemployee.filter((el) => el.department_id == e)
    setListshowemployee([...list])
  }

  const OnOptions1 = (e) => {
    setEmployee_id(e)
  }
  const handleShow = () => {
    setAnchorEl(null)
    setShow(true)
  };
  const handleShow1 = () => {
    setAnchorEl(null)
    setShow1(true)
  }
  const CreateSenddate = (req_id, approval_id, doc_no, signature_id, req_status) => {
    if (doc_no === null) {
      if (req_status === 0) {
        setAnchorEl(null)
        setDucument_no(true)
      } else {
        setAnchorEl(null)
        setSuccessfully(true)
        setModalstatus(1)
      }
      return;
    } else {
      if (approval_id === null) {
        if (req_status === 2) {
          setAnchorEl(null)
          setOpeningsetting(true)
        } else if (req_status === 3) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(3)
        } else if (req_status === 4) {
          setAnchorEl(null)
          setSuccessfully(true)
          setModalstatus(4)
        }
        return;
      } else {
        if (signature_id === null) {
          setAnchorEl(null)
          setSignature_user(true)
        } else {
          if (req_status === 3) {
            setAnchorEl(null)
            setSuccessfully(true)
            setModalstatus(3)
          } else if (req_status === 4) {
            setAnchorEl(null)
            setSuccessfully(true)
            setModalstatus(4)
          } else if (req_status === 5) {
            setAnchorEl(null)
            setSuccessfully(true)
            setModalstatus(5)
          } else if (req_status === 6) {
            setAnchorEl(null)
            setSuccessfully(true)
            setModalstatus(6)
          } else {
            setAnchorEl(null)
            setShowSave(true)
            setIsLoading(true)
            let informdata = {
              req_id: req_id,
              approver: approval_id,
              comment: '',
            }
            axios.post('/api/document-management/outgoing/setting/insert-setting', informdata).then((data) => {
              OnloadFormData();
           
            }).catch((err) => {
              console.log(err)

            }).finally(() => {
              const autoCloseTimeLoading = 1000;
              const timeoutId = setTimeout(() => {
                setIsLoading(false)
              }, autoCloseTimeLoading);
              const autoCloseTime = 2000;
              const timeout = setTimeout(() => {
                setShowSave(false)
              }, autoCloseTime);
              return () => clearTimeout(timeout, timeoutId);
            });

          }

        }
      }
    }
  }
  const onEditform = (id) => {
    setId(id)
    setShowUserEditFormScreen(true)
    setDocument_status(2)
  }
  const Oncreate = (get_req_uid) => {
    setIsLoading(true)
    let createdata = {
      req_id: get_req_uid,
      employee_id: employee_id,
    }
    axios.post('/api/document-management/outgoing/number/Insert-Request-Numbers', createdata).then((data) => {
      setShow(false)
      OnloadFormData()
      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnChooseNumber = (e) => {
    setNumber(e)
  }
  const handleCloseNotdata = () => {
    setIsModalOpen(false)
  }
  const CreateEmployeeApproved = (get_req_uid) => {
    setIsLoading(true)
    let createdate = {
      max_approval: number,
      req_id: get_req_uid,
      details: employee
    }
    if (createdate.details.length === 0) {
      handleClosedel();
      setIsModalOpen(true);
      setIsLoading(false)
      return;
    }
    
    axios.post('/api/document-management/outgoing/apprval/create-approval', createdate).then((data) => {
      setShow1(false);
      setIsLoading(false)
      setNumber('');
      setG_department_id('');
      setEmployee_id('');
      OnloadFormData();
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div>
      <ModalOpenNotdata
        IsModalOpen={IsModalOpen}
        handleCloseNotdata={handleCloseNotdata}
      />
      <ModalDocument
        ducument_no={ducument_no}
        handleCloseDucument_on={handleCloseDucument_on}
      />
      <ModalOpenRequestSuccessully
        successfully={successfully}
        Successfully={Successfully}
        modalstatus={modalstatus}
      />
      <ModalOpenSetting
        openingsetting={openingsetting}
        handleCloseOpenting={handleCloseOpenting}
      />
      <ModalSignature
        signature_user={signature_user}
        handleClosesignature_user={handleClosesignature_user}
        OnloadFormData={OnloadFormData}
      />
      {
        showSave === true ? (<>
          <ModalSave
            showSave={showSave}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </>) : null
      }
      <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
        <Modal.Header closeButton>
          <small style={{ fontSize: 20, paddingTop: 10,color:"#007bff",fontWeight:'bold' }}>ເລືອກຜູ້ໃສ່ເລກທີ່ </small>
        </Modal.Header>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10, marginTop: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
            <small style={{fontSize: 15, marginTop: 25 }}>ໃຫ້ເລືອກພະແນກ:</small>
            <div style={{ width: '60%' }}>
              <FormControl className={classes.formControl} style={{ width: '90%' }}>
                <InputLabel id="demo-simple-select-label">ເລືອກພະແນກ</InputLabel>
                <Select
                  onChange={(e) => OnOptions(e.target.value)}
                  value={depart_id}
                >
                  {
                    listdepartment && listdepartment.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.department_id}>{item?.department_name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginLeft: 10, marginTop: 10, width: '80%' }}>
            <small style={{fontSize: 15, marginTop: 20 }}>ໃຫ້ເລື່ອກຜູ້ໃສ່ເລກທີ່:</small>
            <div style={{ width: '50%', marginTop: -10 }}>
              <FormControl className={classes.formControl} style={{ width: '90%', marginRight: 10 }}>
                <InputLabel id="demo-simple-select-label">ເລື່ອກຜູ້ໃສ່ເລກທີ່</InputLabel>
                <Select
                  onChange={(e) => OnOptions1(e.target.value)}
                  value={employee_id}
                >
                  {
                    listempl && listempl.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.employee_id}>{item?.employee_name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, marginLeft: 10, marginRight: 15 }}>
          <Button
            style={{ marginBottom: 20, marginRight: 20 }}
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon>ສົ່ງ</SendIcon>}
            onClick={() => { Oncreate(req_uid) }}
          >
            {!isLoading ? (
              <>
                ສົ່ງ
              </>
            ) : (
              <>
                {
                  <Spinner animation="border" variant="light" size='sm' />
                }
              </>)
            }
          </Button>
        </div>
      </Modal>
      <Modal show={show1} onHide={handleClosedel1} style={{ paddingTop: 50 }} size="lg">
        <Modal.Header closeButton>
          <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>Setting</span>
        </Modal.Header>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 50, marginRight: 10 }}>
              <div
                style={{
                  border: '1px solid #5ebfe3',
                  backgroundColor: "#e9eef5",
                  borderTopLeftRadius: 5,
                  borderRight: 'none',
                  borderBottomLeftRadius: 5,
                  height: 40,

                  marginTop: 20
                }}>
                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກຈຳນວນຄົນ:</small>
              </div>
              <select
                onChange={(e) => OnChooseNumber(e.target.value)}
                value={number}
                style={{
                  display: 'flex',
                  flex: 1,
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderLeft: 'none',
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <option>ກະລຸນາເລື່ອກຈຳນວນຄົນ</option>
                <option>1</option>
                <option>2</option>
                <option>3g</option>
              </select>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: 50, marginLeft: 10 }}>
              <div
                style={{
                  border: '1px solid #5ebfe3',
                  backgroundColor: "#e9eef5",
                  borderTopLeftRadius: 5,
                  borderRight: 'none',
                  borderBottomLeftRadius: 5,
                  height: 40,
                  marginTop: 20
                }}>
                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກພະແນກ:</small>
              </div>
              <select
                style={{
                  display: 'flex',
                  flex: 1,
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderLeft: 'none',
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  width: '30%'
                }}
                onChange={(e) => OnOptionsDepart(e.target.value)}
                value={g_department_id}
              >
                <option>ກະລຸນາເລື່ອກພະແນກ</option>
                {
                  listdepartment && listdepartment.map((item, index) => {
                    return (
                      <option key={index} value={item?.department_id}>{item?.department_name}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>
          <div style={{ height: 300, width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginLeft: 50, marginRight: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                listshowemployee.map((data, index) => {

                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <small style={{ cursor: 'pointer' }} >
                        <ReadCheckbox
                          data={data}
                          setEmployee={setEmployee}
                          setData_emp={setData_emp}
                        />
                      </small>
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginRight: 50, marginLeft: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                data_emp.map((data, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <Checkbox
                        defaultChecked
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #5ebfe3',
              borderRadius: 3,
              marginRight: 50,
              marginBottom: 20,
              width: 100,
              height: 35,
              cursor: 'pointer',
              backgroundColor: active === 'save' ? '#e9eef5' : '', // Fix the condition here
            }}
              onMouseOver={() => setActive('save')}
              onMouseLeave={() => setActive(null)}
              onClick={() => { CreateEmployeeApproved(req_uid) }}
            >
              <small>
                {
                  !isLoading ? (<>
                    ບັນທືກ
                  </>) : (
                    <>
                      {
                        <Spinner animation="border" variant="light" size='sm' />
                      }
                    </>)
                }
              </small>
            </div>

          </div>
        </div>
      </Modal>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          docno_status === 1 ? (<>
            <MenuItem ><SettingsIcon style={{ color: '#5ebfe3',fontSize:15 }} /><small style={{ marginLeft: 10, }}>ເລືອກຜູ້ອະນຸມັດ</small> </MenuItem>
            <MenuItem onClick={() => onEditform(req_uid)} ><EditIcon style={{ color: '#5ebfe3' ,fontSize:15}} />  <small style={{ marginLeft: 10, }}>ແກ້ໄຂ</small></MenuItem>
          </>) : (<>
            {
              docno_status == 2 ? (<>
                <MenuItem onClick={() => { handleShow1(req_uid) }}><SettingsIcon style={{ color: '#5ebfe3',fontSize:15 }} /><small style={{ marginLeft: 10,}} >ເລືອກຜູ້ອະນຸມັດ</small> </MenuItem>
                <MenuItem onClick={() => onEditform(req_uid)} ><EditIcon style={{ color: '#5ebfe3',fontSize:15 }} />  <small style={{ marginLeft: 10, }} >ແກ້ໄຂ</small></MenuItem>
              </>) : (<>
                <MenuItem onClick={() => { handleShow(req_uid) }}><SettingsIcon style={{ color: '#5ebfe3',fontSize:15 }} /><small style={{ marginLeft: 10,  }} >ເລືອກຜູ້ໃສ່ເລກທີ່</small> </MenuItem>
                <MenuItem onClick={() => onEditform(req_uid)}><EditIcon style={{ color: '#5ebfe3',fontSize:15 }} />  <small style={{ marginLeft: 10, }} >ແກ້ໄຂ</small></MenuItem>
              </>)
            }

          </>)
        }
        <MenuItem ><SendIcon style={{ color: '#5ebfe3' ,fontSize:15}} /> <small style={{ marginLeft: 10,}} onClick={() => CreateSenddate(req_uid, approval_id, doc_no, signature_id, req_status)}  >ສົ່ງຕໍ່</small></MenuItem>

      </Menu>
    </div>
  );
}
function ReadCheckbox({ data, setEmployee, setData_emp }) {
  const [Ischecked, setIschecked] = useState(false)
  const handleChange = (event, employee_id, name) => {
    if (event.target.checked === true) {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => [...prevEmployee, { approver_id: employee_id }]);
      setData_emp(prevEmployee_data => [...prevEmployee_data, { approver_id: employee_id, name: name }]);
    } else {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
      setData_emp(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
    }
  };

  return (
    <Checkbox
      checked={Ischecked}
      onChange={(event) => handleChange(event, data?.employee_id, data?.name)}
      color="primary"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};