import React, { useEffect, useState, useContext } from "react";
import { DataGrid } from '@material-ui/data-grid';
import DoneIcon from '@material-ui/icons/Done';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import axios from "axios";
import moment from "moment";

const columns = [
  {
    field: 'form_name',
    headerName: 'Form_name',
    width: 150,
    editable: true,
  },
  {
    field: 'created_at',
    headerName: 'Last Modified',
    width: 150,
    renderCell: (params) => (
      <>
        {
          moment(params.row.created_at).format('DD-MM-YYYY')
        }
      </>
    )
  },
  {
    field: 'req_status',
    headerName: 'Req_status',
    width: 150,
    renderCell: (params) => (
      <>

        {
          params.row.req_status === 3 && (
            <div style={{ border: '1px solid #d32f2f', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
              <ScreenRotationIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#c62828' }} />
              <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#c62828' }}>Requested....</small>
            </div>
          )}
        {
          params.row.req_status === 4 && (
            <div style={{ border: '1px solid #ed6c02', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
              <ScreenRotationIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#e65100' }} />
              <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#e65100' }}>In Progress....</small>
            </div>
          )}
        {
          params.row.req_status === 5 && (
            <div style={{ border: '1px solid #0288d1', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
              <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
              <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Aproved....</small>
            </div>
          )}
        {
          params.row.req_status === 6 && (
            <div style={{ border: '1px solid #0288d1', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
              <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
              <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Rejected....</small>
            </div>
          )}
        {
          params.row.req_status === 7 && (
            <div style={{ border: '1px solid #0288d1', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
              <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
              <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Canceled....</small>
            </div>
          )}
        {
          params.row.req_status === 8 && (
            <div style={{ border: '1px solid #0288d1', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
              <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
              <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>draft....</small>
            </div>
          )}


      </>
    ),
  },

];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


export default function Testing() {
  const [dataList, setDataList] = useState([])
  const OnloadFormData = () => {
    axios.get('api/form/forms/').then((data) => {
      console.log("ListForm=", data)
      setDataList([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    OnloadFormData();
  }, [])
  return (
    <div style={{ height: 500, width: '100%',display:'flex',flexDirection:'row' }}>
      <div>
        
      </div>
    
    </div>
  );
}
