import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import GestureIcon from '@material-ui/icons/Gesture';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from "@material-ui/core/ListItemText";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DvrIcon from '@material-ui/icons/Dvr';
import Collapse from "@material-ui/core/Collapse";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../page/contexts/LoginContext";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import axios from "axios";
import Cookies from 'js-cookie';
import SettingsIcon from "@material-ui/icons/Settings";
import { CreateFormTest } from "../components/CreateFormTest"
import { CreateForm } from "../components/CreateForm";
import Badge from '@material-ui/core/Badge';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import { EditFormTest } from "../components/EditFormTest";
import { ViewFormTest } from "../components/ViewFormTest";
import { UserusingFormTest } from "../components/UserusingFormTest";
import { UserEditformTest } from "../components/UserEditformTest";
import { MySinature } from "../components/MySinature";
import { UserCheckDoc_noTest } from "../components/UserCheckDoc_noTest";
import { UserCheckDoc_noOutTest } from "../components/UserCheckDoc_noOutTest";
import PostAddIcon from '@material-ui/icons/PostAdd';
import Notifications from "../components/Notifications";
import { useMediaQuery } from 'react-responsive';


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),

        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    //css for moblie
    appBarShiftmoblie: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButtonmoble: {
        marginRight: theme.spacing(2),
    },

}));

export default function Home(props) {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isDesktop = useMediaQuery({ minWidth: 992 });

    const Navigate = useNavigate();
    const classes = useStyles();
    const theme = useTheme();
    const [listform, setListform] = useState([]);

    const [openingApproved, setOpeningApproved] = useState(false)
    const [opneingrequest, setOpneingrequest] = useState(false)
    const [openingDoc_no, setOpeningDoc_no] = useState(false)
    const [active, setActive] = useState("");
    const [open1, setOpen1] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false)
    const anchorRef = useRef(null);
    const {
        setDataList,
        open,
        setOpen,
        listOpent,
        setListOpent,
        listopenForm,
        setListopenForm,
        id,
        showforcreateform,
        showformscreen,
        setShowformscreen,
        showformEditScreen,
        showViewFormScreen,
        showUserusingFormScreen,
        showUserEditFormScreen,
        showUserCheckFormScreen,
        showUserCheckFormScreenOut,
        document_status,
        notifications,
        openingsetting,
        handleCloseOpenting,
        opening

    } = useContext(LoginContext)
    let users = Cookies.get("user");
    let data = JSON.parse(users)

    const handlOpenNotifications = () => setOpenNotifications(true)
    const handleCloseOpentNoti = () => {
        setOpenNotifications(false)
    }

    // let user_id = data?.user?.user_id
    let user_name = data?.name
    const handleToggle = () => {
        setOpen1((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen1(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen1(false);
        }
    }
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open1);
    React.useEffect(() => {
        if (prevOpen.current === true && open1 === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open1;
    }, [open]);
    const Showfullscreen = () => {
        setShowformscreen(true)
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const onloadDepartment = () => {
        axios.get('/api/document-management/form/get-departments').then((data) => {
            setListform([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        setListOpent(!listOpent);
    };
    const handleClickform = () => {
        setListopenForm(!listopenForm)
    }
    const handleClickMyApprove = () => {
        setOpeningApproved(!openingApproved)
    }
    const handleClickMyrequest = () => {
        setOpneingrequest(!opneingrequest)
    }
    const handlClickDoc_no = () => {
        setOpeningDoc_no(!openingDoc_no)
    }

    const OnloadListViewrequest = () => {
        axios.get('/api/document-management/setting/view-form-request/').then((data) => {
            // setCount([...data?.data?.count][0].count)
            // setRole_id(data?.data?.datastatus)
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnLogout = () => {
        Cookies.remove('user');
        window.location.href = '/Login';
    }
    const Ondepartment = (id) => {
        let data = {
            department_id: id
        }
        axios.post('/api/document-management/form/get-formBy-departments', data).then((data) => {
            setDataList([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadListViewrequest()
        onloadDepartment();
    }, [])
    useEffect(() => {
        if (notifications !== 0) {
            const timeoutId = setTimeout(() => {
                setOpenNotifications(true)
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [notifications]);
    return (
        <div>
            {showformscreen === true ? (
                <>
                    <CreateFormTest />
                    {/* < CreateForm /> */}
                </>) : (showformEditScreen === true) ? (
                    <>
                        {/* <EdiForm
                        id={id}
                        /> */}
                        <EditFormTest
                            id={id} />
                    </>) : (showViewFormScreen === true) ? (
                        <>
                            {/* <ViewForm
                id={id}
              /> */}
                            <ViewFormTest
                                id={id}
                            />
                        </>) : (showUserusingFormScreen === true) ? (
                            <>
                                {/* <UserusingForm
                  id={id}

                /> */}
                                <UserusingFormTest
                                    id={id}
                                />

                            </>) : (showUserEditFormScreen === true) ? (<>
                                {/* <UserEditform
                  id={id}
                /> */}
                                <UserEditformTest
                                    id={id}
                                    document_status={document_status}
                                />

                            </>) : (showUserCheckFormScreen === true) ? (
                                <>
                                    {/* <UserCheckDoc_no
                    id={id}
                  /> */}
                                    <UserCheckDoc_noTest
                                        id={id}

                                    />
                                </>
                            ) : (showUserCheckFormScreenOut === true) ? (
                                <>
                                    {/* <UserCkeckDoc_noOut
                    id={id}
                  /> */}
                                    <UserCheckDoc_noOutTest
                                        id={id}
                                    />


                                </>) : (showforcreateform === true) ? (
                                    <>
                                        <EditFormTest />
                                    </>) : (
                <>

                    <MySinature
                        openingsetting={openingsetting}
                        handleCloseOpenting={handleCloseOpenting}
                    />
                    <Notifications
                        openNotifications={openNotifications}
                        handleCloseOpentNoti={handleCloseOpentNoti}
                        Navigate={Navigate}
                    />
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: open,
                            })}
                            style={{ backgroundColor: 'white' }}
                        >
                            <Toolbar>
                                <IconButton
                                    color="primary"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: open,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                    <Typography variant="h5" noWrap style={{ color: '#007bff' }}>
                                        Phongsavanh Group
                                    </Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}

                                    >
                                        <Badge badgeContent={notifications} color="secondary"
                                            style={{ marginRight: 50, marginTop: 10, cursor: 'pointer' }}
                                            onClick={() => { handlOpenNotifications() }}
                                        >
                                            <NotificationsNoneIcon style={{ color: '#007bff' }} />
                                        </Badge>
                                        <Typography variant="h6" noWrap
                                            style={{ cursor: "pointer" }} >
                                            <AccountCircleIcon style={{ color: '#007bff' }} />
                                            <Button
                                                ref={anchorRef}
                                                aria-controls={open1 ? 'menu-list-grow' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle}
                                                style={{ color: '#007bff' }}
                                            >
                                                {user_name}
                                            </Button>
                                        </Typography>
                                    </div>
                                    <Popper open={open1} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose} style={{ with: '50%' }}>
                                                        <MenuList autoFocusItem={open1} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                            <MenuItem onClick={handleClose} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                                <AccountCircleIcon />
                                                                <span style={{ fontSize: 20, marginLeft: 10 }} onClick={() => { opening() }}>My Profiled</span>

                                                            </MenuItem>
                                                            <MenuItem onClick={() => { OnLogout() }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                                <ExitToAppIcon />
                                                                <span style={{ fontSize: 20, marginLeft: 10 }}>Logout</span>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                </div>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open,
                                }),
                            }}
                            style={{ backgroundColor: '#0f172a' }}
                        >
                            <div className={classes.toolbar} style={{ backgroundColor: '#0f172a' }}>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === "rtl" ? (
                                        <ChevronRightIcon style={{ color: 'white' }} />
                                    ) : (
                                        <ChevronLeftIcon style={{ color: 'white' }} />
                                    )}
                                </IconButton>
                            </div>
                            <List style={{ backgroundColor: '#0f172a' }}
                            >
                                <ListItem button onClick={() => Navigate("/Index")}
                                    style={{ backgroundColor: active === 'Dashboard' ? 'gray' : '' }}
                                    onMouseOver={() => setActive('Dashboard')}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    <ListItemIcon>
                                        <AppsIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <span style={{ fontSize: 18, fontWeight: 'bold', color: 'white' }}>ໜ້າຫຼັກ</span>
                                </ListItem>
                            </List>
                            <List style={{ backgroundColor: '#0f172a' }}
                            >
                                <ListItem button onClick={handleClick}
                                    style={{ backgroundColor: active === 'Settings' ? 'gray' : '' }}
                                    onMouseOver={() => setActive('Settings')}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    <ListItemIcon>
                                        <SettingsIcon style={{ color: 'white' }} />

                                    </ListItemIcon>
                                    <small style={{ fontSize: 18, color: 'white' }}>ຈັດການຂໍ້ມູນ</small>
                                    <ListItemText />
                                </ListItem>
                                <Collapse in={listOpent} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.nested}
                                            onClick={() => { Showfullscreen() }}
                                            style={{ backgroundColor: active === 'CreateForm' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('CreateForm')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <AddCircleIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 18, marginLeft: 5, color: 'white' }}>ສ້າງແບບຟອມ</span>
                                        </ListItem>
                                        <ListItem button className={classes.nested} onClick={() => Navigate("/FormListTest")}
                                            style={{ backgroundColor: active === 'ListForm' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('ListForm')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon>
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <small style={{ fontSize: 18, marginLeft: 5, color: 'white' }} >ແບບຟອມ</small>
                                        </ListItem>
                                        <ListItem button className={classes.nested} onClick={() => Navigate("/AddEmployee")}

                                            style={{ backgroundColor: active === 'em' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('em')}
                                            onMouseLeave={() => setActive(null)}

                                        >
                                            <ListItemIcon>
                                                <GroupAddIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <small style={{ fontSize: 18, marginLeft: 5, color: 'white' }}>ສ້າງພະນັກງານ</small>
                                        </ListItem>
                                        <ListItem button className={classes.nested} onClick={() => Navigate("/Signature")}
                                            style={{ backgroundColor: active === 'Signature' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('Signature')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon>
                                                <GestureIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <small style={{ fontSize: 18, marginLeft: 5, color: 'white' }}>ລາຍເຊັນ</small>
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <ListItem button onClick={handleClickform}
                                    style={{ backgroundColor: active === 'Department' ? 'gray' : '' }}
                                    onMouseOver={() => setActive('Department')}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    <ListItemIcon>
                                        <Filter9PlusIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <small style={{ fontSize: 18, color: 'white' }}>ແບບຟອມທີ່ພ້ອມໃຊ້</small>
                                    <ListItemText />
                                </ListItem>
                                <Collapse in={listopenForm} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {
                                            listform && listform.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <ListItem button className={classes.nested} onClick={() => { Navigate(`/Department/${item?.department_id}`); Ondepartment(item?.department_id) }}
                                                            style={{ backgroundColor: active === item?.department_name ? 'gray' : '' }}
                                                            onMouseOver={() => setActive(item?.department_name)}
                                                            onMouseLeave={() => setActive(null)}
                                                        >
                                                            <ListItemIcon>
                                                            </ListItemIcon>
                                                            <small style={{ fontWeight: 'bold', fontSize: 15, color: 'white' }}>{item?.department_name}</small>
                                                        </ListItem>
                                                    </div>
                                                )
                                            })
                                        }
                                    </List>
                                </Collapse>
                                <ListItem button onClick={handleClickMyrequest}
                                    style={{ backgroundColor: active === 'MyRequest' ? 'gray' : '' }}
                                    onMouseOver={() => setActive('MyRequest')}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    <ListItemIcon>
                                        <PostAddIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <small style={{ fontSize: 18, color: 'white' }}>ເອກະສານ</small>
                                    <ListItemText />
                                </ListItem>
                                <Collapse in={opneingrequest} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.nested}
                                            onClick={() => { Navigate("/IncomingDocument") }}
                                            style={{ backgroundColor: active === 'Incoming' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('Incoming')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ເອກະສານຂາເຂົ້າ</span>
                                        </ListItem>

                                        <ListItem button className={classes.nested}
                                            onClick={() => { Navigate("/UserFormListOut") }}
                                            style={{ backgroundColor: active === 'FormIn' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('FormIn')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ເອກະສານຂາອອກ</span>
                                        </ListItem>
                                        <ListItem button className={classes.nested}
                                            onClick={() => { Navigate("/UserFormList") }}
                                            style={{ backgroundColor: active === 'FormOut' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('FormOut')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ເອກະສານຂາໃຜໃນ</span>
                                        </ListItem>
                                    </List>
                                </Collapse>
                                <ListItem button onClick={handleClickMyApprove}
                                    style={{ backgroundColor: active === 'Myapproved' ? 'gray' : '' }}
                                    onMouseOver={() => setActive('Myapproved')}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    <ListItemIcon>
                                        <RecentActorsIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <small style={{ fontSize: 18, color: 'white' }}>ອະນຸມັດ</small>
                                    <ListItemText />
                                </ListItem>
                                <Collapse in={openingApproved} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.nested}
                                            onClick={() => { Navigate("/RequestForm") }}
                                            style={{ backgroundColor: active === 'ApprovedForm' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('ApprovedForm')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ສ້າງແບບຟອມ</span>
                                        </ListItem>
                                        <ListItem button className={classes.nested} onClick={() => Navigate("/ApprovedRequestIn")}
                                            style={{ backgroundColor: active === 'ApprovedRequestIn' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('ApprovedRequestIn')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon>
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <small style={{ fontSize: 15, marginLeft: 5, color: 'white' }} >ເອກະສານຂາເຂົ້າ</small>
                                        </ListItem>
                                        <ListItem button className={classes.nested} onClick={() => Navigate("/ApprovedRequestOut")}
                                            style={{ backgroundColor: active === 'ApprovedRequestOut' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('ApprovedRequestOut')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon>
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <small style={{ fontSize: 15, marginLeft: 5, color: 'white' }} >ເອກະສານຂາອອກ</small>
                                        </ListItem>
                                        <ListItem button className={classes.nested} onClick={() => Navigate("/ApprovedRequest")}
                                            style={{ backgroundColor: active === 'ApprovedRequest' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('ApprovedRequest')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon>
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <small style={{ fontSize: 15, marginLeft: 5, color: 'white' }} >ເອກະສານໃຜໃນ</small>
                                        </ListItem>

                                    </List>
                                </Collapse>
                                <ListItem button onClick={handlClickDoc_no}
                                    style={{ backgroundColor: active === 'Document' ? 'gray' : '' }}
                                    onMouseOver={() => setActive('Document')}
                                    onMouseLeave={() => setActive(null)}
                                >
                                    <ListItemIcon>
                                        <DvrIcon style={{ color: 'white' }} />
                                    </ListItemIcon>
                                    <small style={{ fontSize: 18, color: 'white' }}>ໃສ່ເລກທີ່</small>
                                    <ListItemText />
                                </ListItem>

                                <Collapse in={openingDoc_no} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className={classes.nested}
                                            onClick={() => { Navigate("/Doc_noOut") }}
                                            style={{ backgroundColor: active === 'doc_noOut' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('doc_noOut')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ເອກະສານຂາອອກ</span>
                                        </ListItem>
                                        <ListItem button className={classes.nested}
                                            onClick={() => { Navigate("/Doc_no") }}
                                            style={{ backgroundColor: active === 'doc_noIn' ? 'gray' : '' }}
                                            onMouseOver={() => setActive('doc_noIn')}
                                            onMouseLeave={() => setActive(null)}
                                        >
                                            <ListItemIcon >
                                                <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                                            </ListItemIcon>
                                            <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ເອກສານໃຜໃນ</span>
                                        </ListItem>

                                    </List>
                                </Collapse>
                                {/* <ListItem button onClick={() => Navigate("/IncomingDocument")}
                  style={{ backgroundColor: active === 'Incoming' ? 'gray' : '' }}
                  onMouseOver={() => setActive('Incoming')}
                  onMouseLeave={() => setActive(null)}
                >
                  <ListItemIcon>
                    <DvrIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <small style={{ fontSize: 18, color: 'white' }}>ສ້າງເອກະສານຂາເຂົ້າ</small>
                  </div>
                  <ListItemText />
                </ListItem> */}
                                {/* <ListItem button onClick={() => { setShowforcreateform(true) }}
                  style={{ backgroundColor: active === 'Testing' ? 'gray' : '' }}
                  onMouseOver={() => setActive('Testing')}
                  onMouseLeave={() => setActive(null)}
                >
                  <ListItemIcon>
                    <DvrIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <small style={{ fontSize: 18, color: 'white' }}>Testing</small>
                  </div>
                  <ListItemText />
                </ListItem> */}
                                <ListItem style={{ height: 500 }}>
                                    <ListItemText />
                                </ListItem>
                            </List>
                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.toolbar} />

                            {props.children}
                        </main>
                    </div>
                </>
            )
            }

        </div>
    );
}
