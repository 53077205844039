import React, {useState, useContext } from "react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import { Modal } from "react-bootstrap";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Button from "@material-ui/core/Button";
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Spinner } from "react-bootstrap";
import SaveIcon from '@material-ui/icons/Save';
import { LoginContext } from "../page/contexts/LoginContext";
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ModalOpenSetting } from "./ModalOpenSetting";
import { ModalOpenNotdata } from "./ModalOpenNotdata";
import { ModalOpenSuccessully } from "./ModalOpenSuccessully";
import { ModalSave } from "./ModalSave";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
        maxWidth: 200,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const ITEM_HEIGHT = 48;
export default function ListSettingRequestForm(props) {
    const classes = useStyles();
    const form_id = props.form_id;
    const listdepartment = props.listdepartment
    const listallemployee = props.listallemployee
    const approval_id = props.approval_id;
    const OnloadListViewrequest = props.OnloadListViewrequest;
    const formstatus = props.formstatus;
    const [isdisabled1, setIsdisabled1] = useState(false)
    const [isdisabled2, setIsdisabled2] = useState(false)
    const [isdisabled3, setIsdisabled3] = useState(false)
    const [isdisabled4, setIsdisabled4] = useState(true)
    const [createSetting, setCreateSetting] = useState(3)
    const [active, setActive] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [number, setNumber] = useState()
    const [g_department_id, setG_department_id] = useState('')
    const [listshowemployee, setListshowemployee] = useState([])
    const [listpersion, setListpersion] = useState([]);
    const [listcartdata, setListcartdata] = useState([]);
    const [isLoading, setIsLoading,] = useState(false);
    const [IsModalOpen, setIsModalOpen] = useState(false)

    const [employee_id, setEmployee_id] = useState('')
    const [employee, setEmployee] = useState([]);
    const [data_emp, setData_emp] = useState([]);
    const { setId, setShowViewFormScreen,Onloadnotifications } = useContext(LoginContext)
    const [show, setShow] = useState(false);
    const [openingsetting, setOpeningsetting] = useState(false)
    const [successfully, setSuccessfully] = useState(false)
    const [showSave, setShowSave] = useState(false);
    const [modalstatus, setModalstatus] = useState('')
    const handleClosedel = () => {
        setShow(false);
        setIsdisabled1(false)
        setIsdisabled2(false)
        setIsdisabled3(false)
        setEmployee([])
        setData_emp([])
        setListshowemployee([])
        setNumber('')
        setG_department_id('')
    }
    const onEditform = (id) => {
        setId(id)
        setShowViewFormScreen(true)
    }

    const handleCloseOpenting = () => {
        setOpeningsetting(false)

    }
    const handleCloseNotdata = () => {
        setIsModalOpen(false)
    }
    const handleShow = () => {
        setAnchorEl(null);
        setShow(true)
    };
    const Successfully = () => {
        setSuccessfully(false)
    }
    const OnOptionsDepart = (e) => {
        setIsdisabled1(true)
        setIsdisabled2(true)
        setIsdisabled4(false)
        setG_department_id(e)
        let list = listallemployee.filter((el) => el.department_id == e)
        setListshowemployee([...list])
    }
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose22=()=>{

    }
    const CreateEmployeeApproved = (form_id) => {
       
        setIsLoading(true)
        if (createSetting === 1) {
            Creatappoved(form_id)
        } else if (createSetting === 2) {
            CreatRejected(form_id)
        } else {
            let createdate = {
                max_approval: number,
                form_id: form_id,
                details: employee
            }
            if (createdate.details.length === 0) {
                handleClosedel();
                setIsModalOpen(true);
                setIsLoading(false);

                return;
            }
            axios.post('/api/document-management/approval-setting/create-approval', createdate).then((data) => {
                setShow(false);
                setNumber('');
                setListpersion('');
                setG_department_id('');
                setEmployee_id('');
                setListcartdata('');
                OnloadListViewrequest();
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const Creatappoved = (form_id) => {
        setShowSave(true)
        let dataApp = {
            form_id: form_id,
        }
        axios.post('/api/document-management/setting/update-To-Approved-Status', dataApp).then((data) => {
            OnloadListViewrequest()
            Onloadnotifications()
            setIsdisabled1(false)
            setIsdisabled2(false)
            setIsdisabled3(false)
            setShow(false)
            setCreateSetting(3)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            const autoCloseTimeLoading = 1000;
            const timeoutId = setTimeout(() => {
                setIsLoading(false)
            }, autoCloseTimeLoading);
            const autoCloseTime = 2000;
            const timeout = setTimeout(() => {
                setShowSave(false)
            }, autoCloseTime);
            return () => clearTimeout(timeout,timeoutId);
        });
    }
    const CreatRejected = (form_id) => {
        setShowSave(true)
        let datainf = {
            form_id: form_id,
            comment: ''
        }
        axios.post('/api/document-management/setting/update-To-Rejected-Status', datainf).then((data) => {
            OnloadListViewrequest()
            Onloadnotifications()
            setShow(false)
           
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            const autoCloseTimeLoading = 1000;
            const timeoutId = setTimeout(() => {
                setIsLoading(false)
            }, autoCloseTimeLoading);
            const autoCloseTime = 2000;
            const timeout = setTimeout(() => {
                setShowSave(false)
            }, autoCloseTime);
            return () => clearTimeout(timeout,timeoutId);
        });
    }
    const CreateSenddate = (get_form_id, approval_id, formstatus) => {
        if (approval_id == null) {
            if (formstatus === 1) {
                setAnchorEl(null);
                setOpeningsetting(true)
              } else if (formstatus === 2) {
                setAnchorEl(null);
                setSuccessfully(true);
                setModalstatus(2)
              } else if (formstatus === 3) {
                setAnchorEl(null);
                setSuccessfully(true)
                setModalstatus(3)
              } else if (formstatus === 4) {
                setAnchorEl(null);
                setSuccessfully(true);
                setModalstatus(4)
              }

        } else {
             if (formstatus === 3) {
                setAnchorEl(null);
                setSuccessfully(true)
                setModalstatus(3)
            } else if (formstatus === 4) {
                setAnchorEl(null);
                setSuccessfully(true);
                setModalstatus(4)
                return ;
            } else {
                setAnchorEl(null);
                setShowSave(true)
                setIsLoading(true)
                let informdata = {
                    form_id: get_form_id,
                    approval_uid: approval_id
                }
                axios.post('/api/document-management/setting/insert-setting', informdata).then((data) => {
                    OnloadListViewrequest();
                    Onloadnotifications()
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    const autoCloseTimeLoading = 1000;
                    const timeoutId = setTimeout(() => {
                        setIsLoading(false)
                    }, autoCloseTimeLoading);
                    const autoCloseTime = 2000;
                    const timeout = setTimeout(() => {
                        setShowSave(false)
                    }, autoCloseTime);
                    return () => clearTimeout(timeout,timeoutId);
                });

            }

        }
    }
    const handleApprove = e => {
        if (e.target.checked) {
            setCreateSetting(1)
            setIsdisabled2(true)
            setIsdisabled3(true)
        } else {
            setIsdisabled2(false)
            setIsdisabled3(false)
            setCreateSetting(3)
        }
    }
    const handleReject = e => {
        if (e.target.checked) {
            setCreateSetting(2)
            setIsdisabled1(true)
            setIsdisabled3(true)
        } else {
            setIsdisabled1(false)
            setIsdisabled3(false)
            setCreateSetting(3)
        }
    }
    const OnChooseNumber = (e) => {
        setNumber(e)
    }
    return (
        <div>

            <ModalOpenSetting
                openingsetting={openingsetting}
                handleCloseOpenting={handleCloseOpenting}
            />
            <ModalOpenNotdata
                IsModalOpen={IsModalOpen}
                handleCloseNotdata={handleCloseNotdata}
            />
            {
                showSave === true ? (<>
                    <ModalSave
                        showSave={showSave}
                        handleClose={handleClose22}
                        isLoading={isLoading}
                    />

                </>) : null
            }
            <ModalOpenSuccessully
                successfully={successfully}
                Successfully={Successfully}
                modalstatus={modalstatus}
            />

            <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
                <Modal.Header closeButton>
                    <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>ເລືອກຄົນອະນຸມັດ</span>
                </Modal.Header>
                {/* {JSON.stringify(data_emp)} */}
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', marginLeft: 50, marginRight: 10 }}>
                            <input type="checkbox"
                                disabled={isdisabled1}
                                onChange={handleApprove}
                                style={{
                                    height: 30,
                                    width: 30,
                                    marginTop: 20,
                                    cursor: 'pointer',
                                    border: '2px solid #5ebfe3',
                                }}
                            />
                            <small style={{ marginTop: 20, fontSize: 20, marginLeft: 10 }}>ທ່ານຕ້ອງການອະນຸມັດບໍ?</small>

                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', marginRight: 50, marginLeft: 10 }}>
                            <input type="checkbox"
                                disabled={isdisabled2}
                                onChange={handleReject}
                                style={{
                                    height: 30,
                                    width: 30,
                                    marginTop: 20,
                                    cursor: 'pointer',
                                    border: '2px solid #5ebfe3',
                                }} />
                            <small style={{ marginTop: 20, fontSize: 20, marginLeft: 10 }}>ທ່ານຕ້ອງການປະຕິເສດບໍ?</small>

                        </div>
                    </div>
                    <div style={{ width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 50, marginRight: 10 }}>
                            <div
                                style={{
                                    border: '1px solid #5ebfe3',
                                    backgroundColor: "#e9eef5",
                                    borderTopLeftRadius: 5,
                                    borderRight: 'none',
                                    borderBottomLeftRadius: 5,
                                    height: 40,

                                    marginTop: 20
                                }}>
                                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກຈຳນວນຄົນ:</small>
                            </div>
                            <select
                                onChange={(e) => OnChooseNumber(e.target.value)}
                                value={number}
                                disabled={isdisabled3}
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    marginTop: 20,
                                    borderLeft: 'none',
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                }}
                            >
                                <option>ກະລຸນາເລື່ອກຈຳນວນຄົນ</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                            </select>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: 50, marginLeft: 10 }}>
                            <div
                                style={{
                                    border: '1px solid #5ebfe3',
                                    backgroundColor: "#e9eef5",
                                    borderTopLeftRadius: 5,
                                    borderRight: 'none',
                                    borderBottomLeftRadius: 5,
                                    height: 40,
                                    marginTop: 20
                                }}>
                                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກພະແນກ:</small>
                            </div>
                            <select
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    border: '1px solid #5ebfe3',
                                    height: 40,
                                    marginTop: 20,
                                    borderLeft: 'none',
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5,
                                    width: '30%'
                                }}
                                disabled={isdisabled3}
                                onChange={(e) => OnOptionsDepart(e.target.value)}
                                value={g_department_id}
                            >
                                <option>ກະລຸນາເລື່ອກພະແນກ</option>
                                {
                                    listdepartment && listdepartment.map((item, index) => {
                                        return (
                                            <option key={index} value={item?.department_id}>{item?.department_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div style={{ height: 300, width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginLeft: 50, marginRight: 10, marginBottom: 10, overflowY: 'auto' }}>
                            {
                                listshowemployee.map((data, index) => {

                                    return (
                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                                            <small style={{ cursor: 'pointer' }} >

                                                <ReadCheckbox
                                                    data={data}
                                                    setEmployee={setEmployee}
                                                    setData_emp={setData_emp}
                                                />
                                            </small>
                                            <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginRight: 50, marginLeft: 10, marginBottom: 10, overflowY: 'auto' }}>
                            {
                                data_emp.map((data, index) => {
                                    return (
                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                                            <Checkbox
                                                defaultChecked
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                            <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #5ebfe3',
                            borderRadius: 3,
                            marginRight: 50,
                            marginBottom: 20,
                            width: 100,
                            height: 35,
                            cursor: 'pointer',
                            backgroundColor: active === 'save' ? '#e9eef5' : '', // Fix the condition here
                        }}
                            onMouseOver={() => setActive('save')}
                            onMouseLeave={() => setActive(null)}
                            onClick={() => { CreateEmployeeApproved(form_id) }}
                        >
                            <small>
                                {
                                    !isLoading ? (<>
                                        ບັນທືກ
                                    </>) : (
                                        <>
                                            {
                                                <Spinner animation="border" variant="light" size='sm' style={{ color: 'green' }} />
                                            }
                                        </>)
                                }
                            </small>
                        </div>
                    </div>
                </div>
            </Modal>

            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={() => { handleShow() }}><SettingsIcon style={{ color: "#5EBFE3",fontSize:15 }} /><small style={{ marginLeft: 10 }}>ເລືອກຄົນອະນຸມັດ</small> </MenuItem>
                <MenuItem onClick={() => { onEditform(form_id) }}><ListAltIcon style={{ color: "#5EBFE3",fontSize:15  }} />  <small style={{ marginLeft: 10 }}>ແບບຟອມ</small></MenuItem>
                <MenuItem onClick={() => { CreateSenddate(form_id, approval_id, formstatus) }}><SendIcon style={{ color: "#5EBFE3" ,fontSize:15 }} /> <small style={{ marginLeft: 10 }}>ສົ່ງຕໍ່</small></MenuItem>
            </Menu>
        </div>
    );
}
function ReadCheckbox({ data, setEmployee, setData_emp }) {
    const [Ischecked, setIschecked] = useState(false)

    const handleChange = (event, employee_id, name) => {
        if (event.target.checked === true) {
            setIschecked(event.target.checked)
            setEmployee(prevEmployee => [...prevEmployee, { approver_id: employee_id }]);
            setData_emp(prevEmployee_data => [...prevEmployee_data, { approver_id: employee_id, name: name }]);
        } else {
            setIschecked(event.target.checked)
            setEmployee(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
            setData_emp(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
        }
    };

    return (
        <Checkbox
            checked={Ischecked}
            onChange={(event) => handleChange(event, data?.employee_id, data?.name)}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
    );
};