import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import WarningIcon from '@material-ui/icons/Warning';
export const ModalOpenNotdata = (props) => {
    const [active, setActive] = useState("");
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.IsModalOpen} onHide={props.handleCloseNotdata} style={{ paddingTop: 50 }} >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ border: '3px solid red', width: 50, height: 50, borderRadius: '50%', marginTop: 40, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <WarningIcon style={{ color: 'red', marginLeft: 10, marginTop: 10 }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <small style={{ fontSize: 20 }}>ກະລຸນາກວດຂໍ້ມູນໃຫ້ຄົບກ່ອນບັນທືກ?</small>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#2196F3',
                            width: 60,
                            height: 60,
                            borderRadius: '50%',
                            marginTop: 40,
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            backgroundColor: active === 'N' ? '#2196F3' : '#007BFF', // Fix the condition here
                        }}
                        onMouseOver={() => setActive('N')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { props.handleCloseNotdata() }}
                        >
                        <small style={{ fontSize: 25, fontWeight: 'bold', color: 'white', cursor: 'pointer' }}>ປິດ</small>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
