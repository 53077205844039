import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import GestureIcon from '@material-ui/icons/Gesture';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from "@material-ui/core/ListItemText";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DvrIcon from '@material-ui/icons/Dvr';
import Collapse from "@material-ui/core/Collapse";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../page/contexts/LoginContext";
import  TemplateBorwserview  from "./TemplateBorwserview"
import  TemplateMobile  from "./TemplateMobile"
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import axios from "axios";
import Cookies from 'js-cookie';
import SettingsIcon from "@material-ui/icons/Settings";
import { CreateFormTest } from "../components/CreateFormTest"
import { CreateForm } from "../components/CreateForm";
import Badge from '@material-ui/core/Badge';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import { EditFormTest } from "../components/EditFormTest";
import { ViewFormTest } from "../components/ViewFormTest";
import { UserusingFormTest } from "../components/UserusingFormTest";
import { UserEditformTest } from "../components/UserEditformTest";
import { MySinature } from "../components/MySinature";
import { UserCheckDoc_noTest } from "../components/UserCheckDoc_noTest";
import { UserCheckDoc_noOutTest } from "../components/UserCheckDoc_noOutTest";
import { EdiForm } from "../components/EdiForm";
import { BrowserView, MobileView } from 'react-device-detect';
import Notifications from "../components/Notifications";
import { useMediaQuery } from 'react-responsive';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Home(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const Navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [listform, setListform] = useState([]);
  const [openingsetting, setOpeningsetting] = useState(false)
  const [openingApproved, setOpeningApproved] = useState(false)
  const [opneingrequest, setOpneingrequest] = useState(false)
  const [openingDoc_no, setOpeningDoc_no] = useState(false)
  const [active, setActive] = useState("");
  const [open1, setOpen1] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false)
  const anchorRef = useRef(null);
  const {
    setDataList,
    open,
    setOpen,
    listOpent,
    setListOpent,
    listopenForm,
    setListopenForm,
    id,
    showforcreateform,
    showformscreen,
    setShowformscreen,
    showformEditScreen,
    showViewFormScreen,
    showUserusingFormScreen,
    showUserEditFormScreen,
    showUserCheckFormScreen,
    showUserCheckFormScreenOut,
    document_status,
    notifications
  } = useContext(LoginContext)
  let users = Cookies.get("user");
  let data = JSON.parse(users)
  const handleCloseOpenting = () => {
    setOpeningsetting(false)
  }
  const handlOpenNotifications = () => setOpenNotifications(true)
  const handleCloseOpentNoti = () => {
    setOpenNotifications(false)
  }
  const opening = () => {
    setOpeningsetting(!openingsetting)
  }
  // let user_id = data?.user?.user_id
  let user_name = data?.name
  const handleToggle = () => {
    setOpen1((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen1(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen1(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open1);
  React.useEffect(() => {
    if (prevOpen.current === true && open1 === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open1;
  }, [open]);
  const Showfullscreen = () => {
    setShowformscreen(true)
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const onloadDepartment = () => {
    axios.get('/api/form/get-departments').then((data) => {
      setListform([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setListOpent(!listOpent);
  };
  const handleClickform = () => {
    setListopenForm(!listopenForm)
  }
  const handleClickMyApprove = () => {
    setOpeningApproved(!openingApproved)
  }
  const handleClickMyrequest = () => {
    setOpneingrequest(!opneingrequest)
  }
  const handlClickDoc_no = () => {
    setOpeningDoc_no(!openingDoc_no)
  }

  const OnloadListViewrequest = () => {
    axios.get('/api/setting/view-form-request/').then((data) => {
      // setCount([...data?.data?.count][0].count)
      // setRole_id(data?.data?.datastatus)
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnLogout = () => {
    Cookies.remove('user');
    window.location.href = '/Login';
  }
  const Ondepartment = (id) => {
    let data = {
      department_id: id
    }
    axios.post('/api/form/get-formBy-departments', data).then((data) => {
      setDataList([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    OnloadListViewrequest()
    onloadDepartment();
  }, [])
  useEffect(() => {
    if (notifications !== 0) {
      const timeoutId = setTimeout(() => {
        setOpenNotifications(true)
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [notifications]);

  return (
    <div>
      <BrowserView>
        <TemplateBorwserview/>
      </BrowserView>
      <MobileView>
        <TemplateMobile/>
      </MobileView>
    </div>
  );
}
