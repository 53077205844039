import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { DataGrid } from '@material-ui/data-grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie';
import Pagination from '@material-ui/lab/Pagination';
import Button from "@material-ui/core/Button";
import SendIcon from '@material-ui/icons/Send';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import GrainIcon from '@material-ui/icons/Grain';
import AddIcon from '@material-ui/icons/Add';
import { Spinner } from "react-bootstrap";
import EmployeeActions from "../components/EmployeeActions";
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        width: '100%',
        maxWidth: 200,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const AddEmployee = () => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [listdata, setListdata] = useState([])
    const [employee_id, setEmployee_id] = useState('')
    const [employee_name, setEmployee_name] = useState('')
    const [userList, setUserList] = useState([])
    const [isLoading, setIsLoading,] = useState(false);
    const [active, setActive] = useState("");

    let users = Cookies.get("user");
    let data_user = JSON.parse(users)
    let user_id = data_user?.user?.user_id

    const handleShow = () => setShow(true);
    const Onsetting = (e) => {
        let infordata = {
            employee_id: e,
        }
        axios.post('/api/document-management/setting/all-employee-in-department', infordata).then((data) => {
            setListdata([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnOptions = (e) => {
        setEmployee_id(e)
        let list = listdata.filter((el) => el.employee_id.includes(e))
        setEmployee_name([...list][0].employee_name)
    }

    const handleClosedel = () => {
        setShow(false);
    }
    const CreateRole_id = () => {
        setIsLoading(true);
        let infordata = {
            employee_id: employee_id,
            status: 1
        }
        axios.post('/api/document-management/no/create-setting-doc', infordata).then((data) => {
            setShow(false);
            OnloadUserRole();
            setEmployee_id('')
            setIsLoading(false);
        }).catch((err) => {
            console.log(err)
        })
    }
    const columns = [
        {
            field: 'employee_name',
            headerName: 'Employee_name',
            width: 150,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ພະນັກງານ</small>
            )
        },
        {
            field: 'created_at',
            headerName: 'ວັນທີ່',
            width: 170,
            renderCell: (params) => (
                <>
                    {
                        moment(params.row.created_at).format('DD-MM-YYYY')
                    }
                </>
            ),
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່</small>
            )
        },
        {
            field: 'department_name',
            headerName: 'ພະແນກ',
            width: 200,
            renderHeader: () => (
                <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
            )
        },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                < EmployeeActions />
            )
        },
    ];
    const OnloadUserRole = () => {
        axios.get('/api/document-management/no/Get-All-User-Role').then((data) => {
            console.log("userRole=", data)
            setUserList([...data?.data?.results])
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadUserRole()
        Onsetting()
    }, [])
    return (
        <div style={{ widthx: '100%' }}>
            <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
                <Modal.Header closeButton>
                    <span style={{ fontSize: 25, fontWeight: 'bold', paddingTop: 10,color:'#007bff' }}>
                        Setting </span>
                </Modal.Header>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10, marginTop: 10, marginBottom: 20 }}>
                    <FormControl className={classes.formControl} style={{ width: '80%', marginRight: 10 }}>
                        <InputLabel id="demo-simple-select-label">ພະນັກງານ </InputLabel>
                        <Select

                            onChange={(e) => OnOptions(e.target.value)}
                            value={employee_id}
                        >
                            {
                                listdata && listdata.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item?.employee_id}>{item?.employee_name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <Button
                        style={{ marginTop: 10, marginRight: 10 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        endIcon={<SendIcon>send</SendIcon>}
                        onClick={() => { CreateRole_id() }}
                    >
                        {!isLoading ? (
                            <>
                                Send
                            </>
                        ) : (
                            <>
                                {
                                    <Spinner animation="border" variant="light" size='sm' />
                                }
                            </>)
                        }

                    </Button>
                </div>
            </Modal>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'flex-start', 
                marginTop: 50, width: "100%",

                }}>
                <h5 style={{ color: '#007bff', fontWeight: 'bold',marginTop: 2, }}>ຂໍ້ມູນ</h5>
                <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
                <h5 style={{ color: '#007bff' }}>ພະນັກງານ</h5>
                <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: '1px solid #5ebfe3',
                    borderRadius: 5,
                    cursor: 'pointer',
                    justifyContent:'center',
                    
                    alignItems:'center',
                    height: 25, marginTop: 2,
                    backgroundColor: active === 'Dashboard' ? '#e9eef5' : ''
                }}
                    onMouseOver={() => setActive('Dashboard')}
                    onMouseLeave={() => setActive(null)}
                    onClick={() => { handleShow() }}
                >
                    <AddIcon style={{ color: '#007bff' }} />
                    <h5 style={{ marginTop: 8, marginLeft: 2, marginRight: 2, color: '#007bff' }}>ສ້າງພະນັກງານ</h5>
                </div>
            </div>
            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start' }}>
                    <input
                        type="text"
                        placeholder="Search field"
                        style={{
                            width: '100%',
                            border: '1px solid #5EBFE3',
                            height: 35,
                            borderRadius: 5
                        }}
                    />

                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 10 }}>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={userList}
                        columns={columns}
                        pageSize={20}
                        disableSelectionOnClick
                    // loading={loading} // Pass the loading state to the DataGrid
                    />
                </div>
            </div>
        </div>
    )
}
