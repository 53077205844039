import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Rnd } from 'react-rnd';
import ReactToPrint from "react-to-print";


export const ModalPrintForm = (props) => {
    let componentRef = useRef(null)
    const [uselable, setUselable] = useState([])
    const [usecheckbox, setUsecheckbox] = useState([])
    const [usetextlist, setUsetextlist] = useState([])
    const [usetable, setUsetable] = useState([])
    const [tablechidren, setTablechidren] = useState([])
    const [usetable1, setUsetable1] = useState([])
    const [usetable3, setUsetable3] = useState([])
    const [tablechidren1, setTablechidren1] = useState([])
    const [tablechidren3, setTablechidren3] = useState([])
    const [selectedImage, setSelectedImage] = useState([])
    const [active, setActive] = useState("");
    const OnloadListData = () => {
        axios.get(`/api/document-management/form/get-form/${props.id}`).then((data) => {
            if (data?.data?.resuGetTable_sizeforinputlts !== null) {
                setUsetextlist([...data?.data?.resuGetTable_sizeforinputlts])
            }
            if (data?.data?.GetImage_positions !== null) {
                setSelectedImage([...data?.data?.GetImage_positions])
            }
            if (data?.data?.GetTable_checkbox !== null) {
                setUsecheckbox([...data?.data?.GetTable_checkbox])
            }
            if (data?.data?.GetTable_label !== null) {
                setUselable([...data?.data?.GetTable_label])

            }
            if (data?.data?.GetTable_position_one !== null) {
                setUsetable([...data?.data?.GetTable_position_one])
                setTablechidren(JSON.parse([...data?.data?.GetTable_position_one][0].body_table))

            }
            if (data?.data?.GetTable_positions_three !== null) {

                setUsetable3([...data?.data?.GetTable_positions_three])
                setTablechidren3(JSON.parse([...data?.data?.GetTable_positions_three][0].body_table))
            }
            if (data?.data?.GetTable_positions_two !== null) {

                setUsetable1([...data?.data?.GetTable_positions_two])
                setTablechidren1(JSON.parse([...data?.data?.GetTable_positions_two][0].body_table))
            }

        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadListData()

    }, [])
    return (
        <Modal show={props.openprint} fullscreen={props.fullscreen} onHide={props.handleCloseprint}
        >
            <Modal.Header>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <ReactToPrint
                        trigger={() =>
                            <div
                                style={{
                                    border: '1px solid #5EBFE3',
                                    borderRadius: 5,
                                    width: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: active === 'Dashboard' ? '#e9eef5' : ''
                                }}
                                onMouseOver={() => setActive('Dashboard')}
                                onMouseLeave={() => setActive(null)}
                            >
                                <small >Print</small>
                            </div>
                        }
                        content={() => componentRef}
                    />
                    <div
                        style={{
                            border: '1px solid #5EBFE3',
                            borderRadius: 5,
                            width: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginLeft:10,
                            backgroundColor: active === 'close' ? '#e9eef5' : ''
                        }}
                        onMouseOver={() => setActive('close')}
                        onMouseLeave={() => setActive(null)}
                        onClick={()=>{props.handleCloseprint()}}
                    >
                        <small >Close</small>
                    </div>

                </div>


            </Modal.Header>
            <div style={{ width: '100%', backgroundColor: '#ebedef' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div ref={(el) => (componentRef = el)}

                        style={{ display: 'flex', width: '210mm', height: 2000, backgroundColor: 'white', marginLeft: 10 }}>
                        {
                            usetable && usetable.map((el, index) => {
                                return (
                                    <div key={index}>
                                        <Rnd
                                            default={{
                                                x: el.positionX,
                                                y: el.positionY,

                                            }}

                                        >
                                            <table style={{ border: '1px solid gray' }}>
                                                {
                                                    tablechidren && tablechidren.map((item, index1) => {
                                                        return (
                                                            <>
                                                                <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                    {
                                                                        item.map((data, index2) => {
                                                                            return (
                                                                                <>
                                                                                    <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                        <textarea value={data?.name}

                                                                                            style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                    </td>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Rnd>
                                    </div>
                                )
                            })
                        }
                        {
                            usetable1 && usetable1.map((el, index) => {
                                return (
                                    <div key={index}>
                                        <Rnd
                                            default={{
                                                x: el.positionX,
                                                y: el.positionY,

                                            }}

                                        >
                                            <table style={{ border: '1px solid gray' }}>
                                                {
                                                    tablechidren1 && tablechidren1.map((item, index1) => {
                                                        return (
                                                            <>
                                                                <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                    {
                                                                        item.map((data, index2) => {
                                                                            return (
                                                                                <>
                                                                                    <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                        <textarea value={data?.name}

                                                                                            style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                    </td>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Rnd>
                                    </div>
                                )
                            })
                        }

                        {
                            usetable3 && usetable3.map((el, index) => {
                                return (
                                    <div key={index}>
                                        <Rnd
                                            default={{
                                                x: el.positionX,
                                                y: el.positionY,

                                            }}
                                        >
                                            <table style={{ border: '1px solid gray' }}>
                                                {
                                                    tablechidren3 && tablechidren3.map((item, index1) => {
                                                        return (
                                                            <>
                                                                <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                    {
                                                                        item.map((data, index2) => {
                                                                            return (
                                                                                <>
                                                                                    <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                        <textarea value={data?.name}

                                                                                            // onClick={()=>{OnclickTable(index,index1)}}
                                                                                            style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                    </td>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Rnd>
                                    </div>
                                )
                            })
                        }
                        {
                            uselable && uselable.map((el, index) => {
                                return (
                                    <Rnd
                                        key={index}
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}

                                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >

                                        <small style={{ fontSize: el.font, fontWeight: el.fontWeight }}>{el.name}</small>
                                    </Rnd>
                                )

                            })
                        }
                        {
                            usecheckbox && usecheckbox.map((el, index) => {
                                return (
                                    <Rnd
                                        key={index}
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}

                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <input type="checkbox" style={{ cursor: 'pointer' }} />
                                            <small style={{ marginLeft: 5 }}>{el.name}</small>

                                        </div>
                                    </Rnd>
                                )
                            })
                        }
                        {
                            usetextlist && usetextlist.map((e, index) => {
                                return (
                                    < Rnd
                                        default={{
                                            x: e.positionX,
                                            y: e.positionY,
                                        }}

                                    >
                                        <RowComponent
                                            index={index}

                                            e={e}

                                        />
                                    </Rnd>
                                )
                            })
                        }
                        {
                            selectedImage && selectedImage.map((e, index) => {


                                return (
                                    <Rnd
                                        key={index}
                                        default={{
                                            x: e.positionX,
                                            y: e.positionY,
                                        }}
                                    >
                                        <img src={e.images[0]} alt="Selected Picture" style={{ width: `${e?.width}px`, height: `${e?.height}px` }} />
                                    </Rnd>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </Modal>

    )
}
function RowComponent({ e }) {
    return (
        <>
            <input
                placeholder="......................................................................................................................................................................"
                value={e?.name}

                style={{
                    width: `${e.width}px`,
                    height: `${e.height}px`,
                    border: 'none',
                }}
            />
        </>
    )
}


