import React, { useEffect, useState, useContext } from "react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from "axios";
import { Modal } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from "react-bootstrap";
import { LoginContext } from "../page/contexts/LoginContext";
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import Checkbox from '@material-ui/core/Checkbox';
import { ModalOpenNotdata } from "./ModalOpenNotdata";
import { ModalOpenSetting } from "./ModalOpenSetting";
import { ModalOpendel } from "./ModalOpendel";
import { ModalOpenSuccessully } from "./ModalOpenSuccessully";
import { ModalSave } from "./ModalSave";
import Badge from '@material-ui/core/Badge';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ITEM_HEIGHT = 48;
export default function LongMenu(props) {
  const classes = useStyles();
  const form_uid = props.form_id;
  const approval_id = props.approval_id;
  const OnloadFormData = props.OnloadFormData
  const formstatus = props.formstatus;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [number, setNumber] = useState()
  const [g_department_id, setG_department_id] = useState('')
  const [listshowemployee, setListshowemployee] = useState([])
  const [isLoading, setIsLoading,] = useState(false);
  const [listallemployee, setListallemployee] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [data_emp, setData_emp] = useState([]);
  const { setId, setshowformEditScreen } = useContext(LoginContext)
  const [show, setShow] = useState(false);
  const [listdepartment, setlistdepartment] = useState('')
  const [openingsetting, setOpeningsetting] = useState(false)
  const [successfully, setSuccessfully] = useState(false)
  const [showSave, setShowSave] = useState(false);
  const [opendel, setOpendel] = useState(false)
  const [IsModalOpen, setIsModalOpen] = useState(false)
  const [active, setActive] = useState("");
  const [modalstatus, setModalstatus] = useState('')
  const handleClosedel = () => {
    setShow(false);
    setData_emp([]);
    setListshowemployee([]);
    setNumber('')
    setG_department_id('')
  }
  const onEditform = (id) => {
    setId(id)
    setshowformEditScreen(true)
  }
  const handleCloseOpenting = () => {
    setOpeningsetting(false)
  }
  const handleCloseDelete = () => {
    setOpendel(false)
  }
  const handleCloseNotdata = () => {
    setIsModalOpen(false)
  }
  const handleOpenDeleted = () => {
    setAnchorEl(null);
    setOpendel(true)
  }
  const handleShow = () => {
    setAnchorEl(null);
    setShow(true)
  };
  const Successfully = () => {
    setSuccessfully(false)
  }
  const OnOptionschoose_number = (e) => {
    setNumber(e)
  }

  const OnChooseNumber = (e) => {
    setNumber(e)

  }
  const OnloadAllEmployee = () => {
    axios.get('/api/document-management/setting/all-employee').then((data) => {
      setListallemployee([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnlaodDepartment = () => {
    axios.get('/api/document-management/setting/all-department').then((data) => {
      setlistdepartment([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnOptionsDepart = (e) => {

    setG_department_id(e)
    let list = listallemployee.filter((el) => el.department_id == e)
    setListshowemployee([...list])
  }
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const CreateEmployeeApproved = (form_uid) => {

    setIsLoading(true)
    let Create_data = {
      max_approval: number,
      form_id: form_uid,
      details: employee
    }

    if (Create_data.details.length === 0) {
      handleClosedel();
      setIsModalOpen(true);
      return;
    }
    axios.post('/api/document-management/approval-setting/create-approval', Create_data).then((data) => {
      OnloadFormData();
      setShow(false);
      setNumber('');
      setG_department_id('');
      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const CreateSenddate = (get_form_id, approval_id, formstatus) => {
    if (approval_id == null) {
      if (formstatus === 0) {
        setAnchorEl(null);
        setOpeningsetting(true)
      } else if (formstatus === 2) {
        setAnchorEl(null);
        setSuccessfully(true);
        setModalstatus(2)
      } else if (formstatus === 3) {
        setAnchorEl(null);
        setSuccessfully(true)
        setModalstatus(3)
      } else if (formstatus === 4) {
        setAnchorEl(null);
        setSuccessfully(true);
        setModalstatus(4)
      }

    } else {
      if (formstatus === 1) {
        setAnchorEl(null);
        setSuccessfully(true)
        setModalstatus(1)
      } else if (formstatus === 2) {
        setAnchorEl(null);
        setSuccessfully(true);
        setModalstatus(2)
      } else if (formstatus === 3) {
        setAnchorEl(null);
        setSuccessfully(true)
        setModalstatus(3)
      } else if (formstatus === 4) {
        setAnchorEl(null);
        setSuccessfully(true);
        setModalstatus(4)
      } else {

        setAnchorEl(null);
        setShowSave(true)
        setIsLoading(true)
        let informdata = {
          form_id: get_form_id,
          approval_uid: approval_id
        }
        axios.post('/api/document-management/setting/insert-setting', informdata).then((data) => {
          OnloadFormData();
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          const autoCloseTimeLoading = 1000;
          const timeoutId = setTimeout(() => {
            setIsLoading(false)
          }, autoCloseTimeLoading);
          const autoCloseTime = 2000;
          const timeout = setTimeout(() => {
            setShowSave(false)
          }, autoCloseTime);
          return () => clearTimeout(timeout, timeoutId);
        });
      }
    }
  }
  const CreateDeleted = (form_uid) => {
    axios.delete(`/api/document-management/form/delete-form/${form_uid}`).then((data) => {
      handleCloseDelete()
      OnloadFormData();
    }).catch((err) => {
      console.log(err)
    })
  }


  useEffect(() => {
    OnloadAllEmployee()
    OnlaodDepartment()
  }, [])


  return (
    <div>
      <ModalOpenNotdata
        IsModalOpen={IsModalOpen}
        handleCloseNotdata={handleCloseNotdata}
      />
      <ModalOpenSetting
        openingsetting={openingsetting}
        handleCloseOpenting={handleCloseOpenting}
      />

      <ModalOpendel
        opendel={opendel}
        handleCloseDelete={handleCloseDelete}
        CreateDeleted={CreateDeleted}
        form_uid={form_uid}
      />
      <ModalOpenSuccessully
        successfully={successfully}
        Successfully={Successfully}
        modalstatus={modalstatus}
      />
      {
        showSave === true ? (<>
          <ModalSave
            showSave={showSave}
            handleClose={handleClose}
            isLoading={isLoading}
          />

        </>) : null
      }
      <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
        <Modal.Header closeButton>
          <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>ເລືອກຄົນອະນຸມັດ</span>
        </Modal.Header>
        {/* {JSON.stringify(data_emp)} */}
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 50, marginRight: 10 }}>
              <div
                style={{
                  border: '1px solid #5ebfe3',
                  backgroundColor: "#e9eef5",
                  borderTopLeftRadius: 5,
                  borderRight: 'none',
                  borderBottomLeftRadius: 5,
                  height: 40,

                  marginTop: 20
                }}>
                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກຈຳນວນຄົນ:</small>
              </div>
              <select
                onChange={(e) => OnChooseNumber(e.target.value)}
                value={number}
                style={{
                  display: 'flex',
                  flex: 1,
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderLeft: 'none',
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <option>ກະລຸນາເລື່ອກຈຳນວນຄົນ</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: 50, marginLeft: 10 }}>
              <div
                style={{
                  border: '1px solid #5ebfe3',
                  backgroundColor: "#e9eef5",
                  borderTopLeftRadius: 5,
                  borderRight: 'none',
                  borderBottomLeftRadius: 5,
                  height: 40,
                  marginTop: 20
                }}>
                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກພະແນກ:</small>
              </div>
              <select
                style={{
                  display: 'flex',
                  flex: 1,
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderLeft: 'none',
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  width: '30%'
                }}
                onChange={(e) => OnOptionsDepart(e.target.value)}
                value={g_department_id}
              >

                <option>ກະລຸນາເລື່ອກພະແນກ</option>
                {
                  listdepartment && listdepartment.map((item, index) => {
                    return (
                      <option key={index} value={item?.department_id}>{item?.department_name}</option>
                    )
                  })
                }
              </select>

            </div>
          </div>
          <div style={{ height: 300, width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginLeft: 50, marginRight: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                listshowemployee.map((data, index) => {

                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <small style={{ cursor: 'pointer' }} >

                        <ReadCheckbox
                          data={data}
                          setEmployee={setEmployee}
                          setData_emp={setData_emp}
                        />
                      </small>
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginRight: 50, marginLeft: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                data_emp.map((data, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <Checkbox
                        defaultChecked
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #5ebfe3',
              borderRadius: 3,
              marginRight: 50,
              marginBottom: 20,
              width: 100,
              height: 35,
              cursor: 'pointer',
              backgroundColor: active === 'save' ? '#e9eef5' : '', // Fix the condition here
            }}
              onMouseOver={() => setActive('save')}
              onMouseLeave={() => setActive(null)}
              onClick={() => { CreateEmployeeApproved(form_uid) }}
            >
              <small style={{ color: '#007bff' }}>
                {
                  !isLoading ? (<>
                    ບັນທືກ
                  </>) : (
                    <>
                      {
                        <Spinner animation="border" variant="light" size='sm' style={{ color: '#007bff' }} />
                      }
                    </>)
                }
              </small>
            </div>

          </div>
        </div>
      </Modal>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {
        props.massage === 0 ? (

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={() => { handleShow() }}
              style={{
                backgroundColor: active === '1' ? '#e9eef5' : '', // Fix the condition here
              }}
              onMouseOver={() => setActive('1')}
              onMouseLeave={() => setActive(null)}
            ><SettingsIcon style={{ color: "#5EBFE3", fontSize: 15 }} /><small style={{ marginLeft: 10, }}>ເລືອກຄົນອະນຸມັດ</small> </MenuItem>
            <MenuItem onClick={() => { onEditform(form_uid) }}
              style={{
                backgroundColor: active === '2' ? '#e9eef5' : '', // Fix the condition here
              }}
              onMouseOver={() => setActive('2')}
              onMouseLeave={() => setActive(null)}
            ><EditIcon style={{ color: "#5EBFE3", fontSize: 15 }} /><small style={{ marginLeft: 10 }}>ແກ້ໄຂ</small></MenuItem>
            <MenuItem onClick={() => { CreateSenddate(form_uid, approval_id, formstatus) }}
              style={{
                backgroundColor: active === '3' ? '#e9eef5' : '', // Fix the condition here
              }}
              onMouseOver={() => setActive('3')}
              onMouseLeave={() => setActive(null)}

            ><SendIcon style={{ color: "#5EBFE3", fontSize: 15 }} /> <small style={{ marginLeft: 10 }}>ສົ່ງ</small></MenuItem>
            <MenuItem
              style={{
                backgroundColor: active === '4' ? '#e9eef5' : '', // Fix the condition here
              }}
              onMouseOver={() => setActive('4')}
              onMouseLeave={() => setActive(null)}
            ><DeleteIcon style={{ color: "#5EBFE3", fontSize: 15 }} />  <small style={{ marginLeft: 10, }} onClick={() => { handleOpenDeleted() }}>ລຶບອອກ</small></MenuItem>
          </Menu>

        ) : (
          <Badge color="secondary" badgeContent={props.massage}>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem onClick={() => { handleShow() }}><SettingsIcon style={{ color: "#5EBFE3" }} /><small style={{ marginLeft: 10 }}>Setting</small> </MenuItem>
              <MenuItem onClick={() => { onEditform(form_uid) }}><EditIcon style={{ color: "#5EBFE3" }} /><small style={{ marginLeft: 10 }}>Edit</small></MenuItem>
              <MenuItem onClick={() => { CreateSenddate(form_uid, approval_id, formstatus) }}><SendIcon style={{ color: "#5EBFE3" }} /> <small style={{ marginLeft: 10 }}>Send</small></MenuItem>
              {/* <MenuItem><EditAttributesIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10 }}>Rename</small></MenuItem> */}
              <MenuItem><DeleteIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }} onClick={() => { handleOpenDeleted() }}>Deleted</small></MenuItem>
              {/* <MenuItem><GetAppIcon style={{ color: "#5EBFE3" }} />  <small style={{ marginLeft: 10, }}>Downlaod</small></MenuItem> */}
            </Menu>
          </Badge>
        )
      }


    </div>
  );
}
function ReadCheckbox({ data, setEmployee, setData_emp }) {
  const [Ischecked, setIschecked] = useState(false)

  const handleChange = (event, employee_id, name) => {
    if (event.target.checked === true) {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => [...prevEmployee, { approver_id: employee_id }]);
      setData_emp(prevEmployee_data => [...prevEmployee_data, { approver_id: employee_id, name: name }]);
    } else {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
      setData_emp(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
    }
  };

  return (
    <Checkbox
      checked={Ischecked}
      onChange={(event) => handleChange(event, data?.employee_id, data?.name)}
      color="primary"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};