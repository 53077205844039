import React, { useState, useRef, useContext, useEffect } from "react";
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import { LoginContext } from "../page/contexts/LoginContext";
import { Rnd } from 'react-rnd';
import axios from "axios";
export const EditFormTest = () => {
    const { id } = useParams();
    console.log("mobile=", id)

    let users = Cookies.get("user");
    let userLogin = JSON.parse(users)

    const componentRef = useRef();
    let componentRef_test = useRef(null)

    const [active, setActive] = useState("");
    const [listtext, setListText] = useState([])
    const [listtextw, setListTextw] = useState([])
    const [show, setShow] = useState(false);
    const [tableRow, setTableRow] = useState('')
    const [tableColumn, setTableColumn] = useState('')
    const [showdeltable, setShowdeltable] = useState(true)
    const [showdeltable1, setShowdeltable1] = useState(false)
    const [showdeltable2, setShowdeltable2] = useState(false)

    const [loading, setLoading] = useState(true);
    const [uselable, setUselable] = useState([])
    const [editcheckboxvalues, setEditcheckboxvalues] = useState('')
    const [widthsize, setWidthsize] = useState('')
    const [heightsize, setHeightsize] = useState('')
    const [dataText, setDataText] = useState([])
    const [index, setIndex] = useState('')
    const [index1, setIndex1] = useState('')
    const [tables, setTables] = useState([[]])
    const [tables1, setTables1] = useState([[]])
    const [tables2, setTables2] = useState([[]])
    const [datatable, setDatatable] = useState([])
    const [datatable1, setDatatable1] = useState([])
    const [datatable2, setDatatable2] = useState([])
    const [getindex_first, setGetindex_first] = useState([])
    const [getindex_second, setGetindex_second] = useState([])
    const [getindex_third, setGetindex_third] = useState([])
    const [get_index, setGet_index] = useState('')
    const [menucondigions, setMenucondigions] = useState('')
    const [conditions_text, setConditions_text] = useState('')
    const [conditions_checkbox, setConditions_checkbox] = useState('')
    const [conditions_image, setConditions_image] = useState('')
    const [conditions_table, setConditions_table] = useState('')
    const [inputValues, setInputValues] = useState('')
    const [inputValues1, setInputValues1] = useState('')
    const [editlable, setEditlable] = useState('')
    const [fontSize, setFontSize] = useState('')
    const [datacheckbox, setDatacheckbox] = useState([])
    const [uniqueCombinationCount_first, setUniqueCombinationCount_first] = useState(0);
    const [uniqueCombinationCount_second, setUniqueCombinationCount_second] = useState(0);
    const [uniqueCombinationCount_third, setUniqueCombinationCount_third] = useState(0);
    const [uniqueCombinations_first, setUniqueCombinations_first] = useState(new Set());
    const [uniqueCombinations_second, setUniqueCombinations_second] = useState(new Set());
    const [uniqueCombinations_third, setUniqueCombinations_third] = useState(new Set());
    const [selectedCells_first, setSelectedCells_first] = useState([])
    const [selectedCells_second, setSelectedCells_second] = useState([])
    const [selectedCells_third, setSelectedCells_third] = useState([])
    const [showSave, setShowSave] = useState(false);


    const [formstatus, setformstatus] = useState('')
    const [usetextlist, setUsetextlist] = useState([])
    const [usecheckbox, setUsecheckbox] = useState([])
    const [usetable, setUsetable] = useState([])
    const [countRow, setCountRow] = useState('')
    const [countRow1, setCountRow1] = useState('')

    const [tablechidren, setTablechidren] = useState([])
    const [tablechidren1, setTablechidren1] = useState([])
    const [tablechidren2, setTablechidren2] = useState([])
    const [tablechidren3, setTablechidren3] = useState([])
    const [usetable1, setUsetable1] = useState([])
    const [usetable2, setUsetable2] = useState([])
    const [usetable3, setUsetable3] = useState([])
    const [file, setFile] = useState();
    const [selectedImage, setSelectedImage] = useState([])
    const [selectedImage_show, setSelectedImage_show] = useState([])
    const [ing_width, setIng_width] = useState('')
    const [ing_heigh, setIng_heigh] = useState('')
    const [pictureUrl, setPictureUrl] = useState(null);
    const [showsave, setShowsave] = useState(false);
    const [saveAs, setSaveAs] = useState('')
    const [isLoading, setIsLoading,] = useState(false);
    const [showchats, setShowchats] = useState(false)
    const [showprinter, setShowprinter] = useState(false)
    const handleClosedel = () => {
        setShow(false);
    }
    const showprint = () => setShowprinter(true)
    const handleCloseforchat = () => {
        setShowchats(false)
    }
    const {
        setshowformEditScreen,
    } = useContext(LoginContext)
    const OncloseCreateformScreen = () => {

        setshowformEditScreen(false)
    }
    const handleShow = () => setShow(true);
    const handlchat = () => setShowchats(true)
    const OnAddText = () => {
        if (listtext.length === 0) {
            setListText([...listtext, { name: "", positionX: 100, positionY: 100, width: 200, height: 25, type: 'text' }]);
        } else {
            const positionY_number = (listtext.length - 1) * 100 + 100;
            setListText([...listtext, { name: "", positionX: 100, positionY: positionY_number, width: 200, height: 25, type: 'text' }]);
        }
    };
    const onDragStopinput = (e, d, index) => {
        const cloneDatas = [...listtext]
        const cloneData = { ...listtext[index] }
        cloneData.positionX = d.x;
        cloneData.positionY = d.y
        cloneDatas[index] = cloneData
        setListText([...cloneDatas])
    }
    const onDragStoptable = (e, d, index, key_conditions, key_values) => {
        if (key_conditions === 'create') {
            if (key_values === 'one') {
                const cloneDatas = [...datatable]
                const cloneData = { ...datatable[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setDatatable([...cloneDatas])
            } else if (key_values === 'two') {
                const cloneDatas = [...datatable1]
                const cloneData = { ...datatable1[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setDatatable1([...cloneDatas])

            } else {
                const cloneDatas = [...datatable2]
                const cloneData = { ...datatable2[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setDatatable2([...cloneDatas])
            }

        } else {
            if (key_values === 'one') {
                const cloneDatas = [...usetable]
                const cloneData = { ...usetable[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setUsetable([...cloneDatas])
            } else if (key_values === 'two') {
                const cloneDatas = [...usetable1]
                const cloneData = { ...usetable1[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setUsetable1([...cloneDatas])

            } else {
                const cloneDatas = [...usetable2]
                const cloneData = { ...usetable2[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setUsetable2([...cloneDatas])
            }

        }


    }

    const onDragStopDataText = (e, d, index, key_values) => {
        if (key_values === 'create') {
            const cloneDatas = [...dataText]
            const cloneData = { ...dataText[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setDataText([...cloneDatas])
        } else {
            const cloneDatas = [...uselable]
            const cloneData = { ...uselable[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setUselable([...cloneDatas])
        }
    }


    const onDragStopcheckbox = (e, d, index, key_values) => {
        if (key_values === 'create') {
            const cloneDatas = [...datacheckbox]
            const cloneData = { ...datacheckbox[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setDatacheckbox([...cloneDatas])
        } else {
            const cloneDatas = [...usecheckbox]
            const cloneData = { ...usecheckbox[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setUsecheckbox([...cloneDatas])

        }


    }

    const onDragStopinputupdate = (e, d, index) => {
        const cloneDatas = [...usetextlist]
        const cloneData = { ...usetextlist[index] }
        cloneData.positionX = d.x;
        cloneData.positionY = d.y
        cloneDatas[index] = cloneData
        setUsetextlist([...cloneDatas])
    }
    const onDragImagelogo = (e, d, index, key_values) => {
        if (key_values === 'create') {
            const cloneDatas = [...selectedImage]
            const cloneData = { ...selectedImage[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setSelectedImage([...cloneDatas])

        } else {
            const cloneDatas = [...selectedImage_show]
            const cloneData = { ...selectedImage_show[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setSelectedImage_show([...cloneDatas])

        }
    }
    const OnClickCheckimage = (index, key_values) => {
        setMenucondigions('Image')
        setConditions_image(key_values)
        if (key_values === 'create') {
            setGet_index(index)
            const cloneData = selectedImage[index]
            setIng_width(cloneData.width)
            setIng_heigh(cloneData.height)

        } else {
            setGet_index(index)
            const cloneData = selectedImage_show[index]
            setIng_width(cloneData.width)
            setIng_heigh(cloneData.height)
        }

    }
    const changeTextImage_width = (value, key, index) => {
        setIng_width(value)
        const object = { ...selectedImage[index] };
        object[key] = value;
        const cloneData = [...selectedImage];
        cloneData[index] = { ...object };
        setSelectedImage([...cloneData])
    }
    const changeTextImage_width_edit = (value, key, index) => {
        setIng_width(value)
        const object = { ...selectedImage_show[index] };
        object[key] = value;
        const cloneData = [...selectedImage_show];
        cloneData[index] = { ...object };
        setSelectedImage_show([...cloneData])
    }
    const changeTextImage_height = (value, key, index) => {
        setIng_heigh(value)
        const object = { ...selectedImage[index] };
        object[key] = value;
        const cloneData = [...selectedImage];
        cloneData[index] = { ...object };
        setSelectedImage([...cloneData])
    }
    const changeTextImage_height_edit = (value, key, index) => {
        setIng_heigh(value)
        const object = { ...selectedImage_show[index] };
        object[key] = value;
        const cloneData = [...selectedImage_show];
        cloneData[index] = { ...object };
        setSelectedImage_show([...cloneData])
    }
    const OnClickCheckbox = (index, key_values) => {
        setMenucondigions('CheckBox')
        setConditions_checkbox(key_values)
        if (key_values === 'create') {
            setGet_index(index)
            const cloneData = datacheckbox[index]
            setEditcheckboxvalues(cloneData.name)

        } else {
            setGet_index(index)
            const cloneData = usecheckbox[index]
            setEditcheckboxvalues(cloneData.name)
        }
    }
    const deletecheckbox = (index) => {
        setEditcheckboxvalues('')
        const cloneData = [...datacheckbox]
        cloneData.splice(index, 1)
        setDatacheckbox([...cloneData])
    }
    const deletecheckbox_edit = (index) => {
        setEditcheckboxvalues('')
        const cloneData = [...usecheckbox]
        cloneData.splice(index, 1)
        setUsecheckbox([...cloneData])
    }
    const onClickDataText = (index, key_values) => {
        console.log("index=", index)
        setMenucondigions('Text')
        setConditions_text(key_values)
        if (key_values === 'create') {
            setGet_index(index)
            const cloneData = dataText[index]
            setEditlable(cloneData.name)
            setFontSize(cloneData.font)
        } else {
            setGet_index(index)
            const cloneData = uselable[index]
            setEditlable(cloneData.name)
            setFontSize(cloneData.font)
        }
    }
    const Onclicktableindex = (index, key_conditions, key) => {
        setMenucondigions('table')
        setConditions_table(key_conditions)
        if (key_conditions === 'create') {
            if (key === 'one') {
                setShowdeltable(true)
                setShowdeltable1(false)
                setShowdeltable2(false)
                setGet_index(index)

            } else if (key === 'two') {
                setShowdeltable(false)
                setShowdeltable1(true)
                setShowdeltable2(false)
                setGet_index(index)

            } else {
                setShowdeltable(false)
                setShowdeltable1(false)
                setShowdeltable2(true)
                setGet_index(index)
            }

        } else {
            if (key === 'one') {
                // setShowdeltable(true)
                // setShowdeltable1(false)
                // setShowdeltable2(false)
                setGet_index(index)

            } else if (key === 'two') {
                // setShowdeltable(false)
                // setShowdeltable1(true)
                // setShowdeltable2(false)
                setGet_index(index)

            } else {
                // setShowdeltable(false)
                // setShowdeltable1(false)
                // setShowdeltable2(true)
                setGet_index(index)
            }

        }

    }
    const changeText_values_table = (value, key, index1, index, key_conditions, kev_values,) => {
        if (key_conditions === 'create') {
            if (kev_values === 'one') {
                const cloneTables = [...tables]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tables]
                setTables([...cloneData])
            } else if (kev_values === 'two') {
                const cloneTables = [...tables1]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tables1]
                setTables1([...cloneData])
            } else {
                const cloneTables = [...tables2]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tables2]
                setTables2([...cloneData])
            }

        } else {
            if (kev_values === 'one') {
                const cloneTables = [...tablechidren]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tablechidren]
                setTablechidren([...cloneData])
            } else if (kev_values === 'two') {
                const cloneTables = [...tablechidren1]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tablechidren1]
                setTablechidren1([...cloneData])
            } else {
                const cloneTables = [...tablechidren2]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tablechidren2]
                setTablechidren2([...cloneData])
            }

        }

    };
    const changeTextwidth = (value, key, index, index1, key_values) => {
        setWidthsize(value)
        if (key_values === 'one') {
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables]
            setTables([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables1]
            setTables1([...cloneData])

        } else {
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables2]
            setTables2([...cloneData])
        }
    };
    const changeTextwidth_edit = (value, key, index, index1, key_values) => {
        setWidthsize(value)
        if (key_values === 'one') {
            const cloneTables = [...usetable]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable]
            setUsetable([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...usetable1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable1]
            setUsetable([...cloneData])

        } else {
            const cloneTables = [...usetable2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable2]
            setUsetable2([...cloneData])
        }
    };
    const changeTextheight = (value, key, index, index1, key_values) => {
        setHeightsize(value)
        if (key_values === 'one') {
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables]
            setTables([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables1]
            setTables1([...cloneData])
        } else {
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables2]
            setTables2([...cloneData])
        }
    };
    const changeTextheight_edit = (value, key, index, index1, key_values) => {
        setHeightsize(value)
        if (key_values === 'one') {
            const cloneTables = [...usetable]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable]
            setUsetable([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...usetable1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable1]
            setUsetable1([...cloneData])
        } else {
            const cloneTables = [...usetable2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable2]
            setUsetable2([...cloneData])
        }
    };
    const MergeCellcolumn = (key_values) => {

        if (key_values === 'one') {
            const resultArray = getindex_first.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'rows'
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_first;
            const cloneData = [...tables]
            setTables([...cloneData])
            const Array = getindex_first.slice(1);
            const clone_Tables = [...tables];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables([...clone_Tables]);
            setGetindex_first([])
            setSelectedCells_first([])
            setUniqueCombinationCount_first([])
        } else if (key_values === 'two') {
            const resultArray = getindex_second.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'rows'
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_second;
            const cloneData = [...tables1]
            setTables1([...cloneData])
            const Array = getindex_second.slice(1);
            const clone_Tables = [...tables1];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables1([...clone_Tables]);
            setGetindex_second([])
            setSelectedCells_second([])
            setUniqueCombinationCount_second([])

        } else {
            const resultArray = getindex_third.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'rows'
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_third;
            const cloneData = [...tables2]
            setTables([...cloneData])
            const Array = getindex_third.slice(1);
            const clone_Tables = [...tables2];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables2([...clone_Tables]);
            setGetindex_third([])
            setSelectedCells_second([])
            setUniqueCombinationCount_third([])
        }
    }

    const MergeCellRow = (key_values) => {
        if (key_values === 'one') {
            const resultArray = getindex_first.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'cols'
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_first;
            const cloneData = [...tables]
            setTables([...cloneData])
            const Array = getindex_first.slice(1);
            const clone_Tables = [...tables];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables([...clone_Tables]);
            setGetindex_first([])
            setSelectedCells_first([])
            setUniqueCombinationCount_first([])

        } else if (key_values === 'two') {
            const resultArray = getindex_second.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'cols'
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_second;
            const cloneData = [...tables1]
            setTables1([...cloneData])
            const Array = getindex_second.slice(1);
            const clone_Tables = [...tables1];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables1([...clone_Tables]);
            setGetindex_second([])
            setSelectedCells_second([])
            setUniqueCombinationCount_second([])

        } else {
            const resultArray = getindex_third.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'cols'
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_third;
            const cloneData = [...tables2]
            setTables2([...cloneData])
            const Array = getindex_second.slice(1);
            const clone_Tables = [...tables2];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables2([...clone_Tables]);
            setGetindex_third([])
            setSelectedCells_third([])
            setUniqueCombinationCount_third([])
        }
    }
    const MergeCellRowAll = (key_values) => {
        if (key_values === 'one') {

            const resultArray = getindex_first.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const cloneTables = [...tables]
            const deleteObject = cloneTables[index1]
            deleteObject.splice(index, 1);
            const cloneData = [...tables]
            setTables([...cloneData])

        } else if (key_values === 'two') {
            const resultArray = getindex_second.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const cloneTables = [...tables1]
            const deleteObject = cloneTables[index1]
            deleteObject.splice(index, 1);
            const cloneData = [...tables1]
            setTables1([...cloneData])
        } else {
            const resultArray = getindex_third.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const cloneTables = [...tables2]
            const deleteObject = cloneTables[index1]
            deleteObject.splice(index, 1);
            const cloneData = [...tables2]
            setTables2([...cloneData])

        }
    }
    const changeLable = (value, key, getindex) => {
        setEditlable(value)
        const object = { ...dataText[getindex] };
        object[key] = value;
        const cloneData = [...dataText];
        cloneData[getindex] = { ...object };
        setDataText([...cloneData]);
    }
    const changeLable_edit = (value, key, getindex) => {
        setEditlable(value)
        const object = { ...uselable[getindex] };
        object[key] = value;
        const cloneData = [...uselable];
        cloneData[getindex] = { ...object };
        setUselable([...cloneData]);

    }
    const changeCheckbox = (value, key, getindex) => {
        setEditcheckboxvalues(value)
        const object = { ...datacheckbox[getindex] };
        object[key] = value;
        const cloneData = [...datacheckbox];
        cloneData[getindex] = { ...object };
        setDatacheckbox([...cloneData]);

    }
    const changeCheckbox_edit = (value, key, getindex) => {
        setEditcheckboxvalues(value)
        const object = { ...usecheckbox[getindex] };
        object[key] = value;
        const cloneData = [...usecheckbox];
        cloneData[getindex] = { ...object };
        setUsecheckbox([...cloneData]);

    }
    const deleteDataText = (index) => {
        const cloneData = [...dataText]
        cloneData.splice(index, 1)
        setDataText([...cloneData])
    }
    const deleteUseText = (index) => {
        const cloneData = [...uselable]
        cloneData.splice(index, 1)
        setUselable([...cloneData])

    }
    const Onclickrow = () => {
        const array = tablechidren.map((item) => {
            return item
        })
        let list = [];
        let data;
        for (let i = 0; i < 1; i++) {
            if (countRow == 2) {
                data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
            } else if (countRow == 3) {
                data = [{ name: '', value: '1', cols: 1, rows: 1, width: 80, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
            } else if (countRow == 4) {
                data = [{ name: '', value: '1', cols: 1, rows: 1, width: 80, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 80, height: 50 }]
            } else if (countRow == 5) {
            } else if (countRow == 6) {
            }
            list.push(data)
        }
        const result = [...array, ...list]
        setTablechidren([...result])
    }
    const deletetable_edit = (get_index, key_values) => {
        setSelectedCells_first([])
        setSelectedCells_second([])
        setSelectedCells_third([])
        setGetindex_first([])
        setGetindex_second([])
        setGetindex_third([])
        if (key_values === 'one') {
            setShowdeltable(false)
            const cloneData = [...usetable]
            cloneData.splice(get_index, 1)
            setUsetable([...cloneData])

        } else if (key_values === 'two') {
            setShowdeltable(false)
            const cloneData = [...datatable1]
            cloneData.splice(get_index, 1)
            setDatatable1([...cloneData])
        } else {
            setShowdeltable(false)
            const cloneData = [...datatable2]
            cloneData.splice(get_index, 1)
            setDatatable2([...cloneData])
        }
    }
    const deletetable = (get_index, key_values) => {
        setSelectedCells_first([])
        setSelectedCells_second([])
        setSelectedCells_third([])
        setGetindex_first([])
        setGetindex_second([])
        setGetindex_third([])
        if (key_values === 'one') {
            setShowdeltable(false)
            const cloneData = [...datatable]
            cloneData.splice(get_index, 1)
            setDatatable([...cloneData])

        } else if (key_values === 'two') {
            setShowdeltable(false)
            const cloneData = [...datatable1]
            cloneData.splice(get_index, 1)
            setDatatable1([...cloneData])
        } else {
            setShowdeltable(false)
            const cloneData = [...datatable2]
            cloneData.splice(get_index, 1)
            setDatatable2([...cloneData])
        }
    }
    const handleClose = () => {

    }

    const handleCloseSavle = () => {
        setShowsave(false)
    }
    const OnCreate = async () => {
        setIsLoading(true);
        let images
        if (!file) {
            images = 0
        } else {
            let formData = new FormData();
            for (const key of Object.keys(file)) {
                formData.append("file_name", file[key]);
            }
            let profileImageReturnName = await axios.post("/api/document-management/req/upload", formData);
            images = Object.values(profileImageReturnName.data)[0]
        }
        setShowSave(true)
        setIsLoading(true)
        let informdataUpdate = {
            form_uid: id,
            UpdateDataCheckbox: usecheckbox,
            UpdateDataLable: uselable,
            UpdateSizeForInput: usetextlist,
            UpdatePositionsOne: usetable,
            UpdatePositionsTwo: usetable1,
            UpdatePositionsThree: usetable2,
            DataTablepositionOneupdate: tablechidren,
            DataTablepositionTwoupdate: tablechidren1,
            DataTablepositionThreeupdate: tablechidren2,
            UpateImage: selectedImage_show,
            InsertImage: selectedImage,
            InsertDataCheckbox: datacheckbox,
            InsertDataLable: dataText,
            InsertSizeForInput: listtext,
            InsertPositionOne: datatable,
            InsertPositionTwo: datatable1,
            InsertPositionThree: datatable2,
            DataTablepositionOne: tables,
            DataTablepositionTwo: tables1,
            DataTablepositionThree: tables2,
            file_name: images
        }

        console.log("update=", informdataUpdate)
        if (formstatus === 0) {
            axios.post("/api/document-management/form/update-form", informdataUpdate).then((data) => {
            }).catch((error) => {
                console.log(error)

            }).finally(() => {
                const autoCloseTimeLoading = 1000;
                const timeoutId = setTimeout(() => {
                    setIsLoading(false)
                }, autoCloseTimeLoading);
                const autoCloseTime = 2000;
                const timeout = setTimeout(() => {
                    setShowSave(false)
                }, autoCloseTime);
                return () => clearTimeout(timeout, timeoutId);
            });

        } else {
            axios.post("/api/document-management/form/update-form-draft", informdataUpdate).then((data) => {
            }).catch((error) => {
                console.log(error)

            }).finally(() => {
                const autoCloseTimeLoading = 1000;
                const timeoutId = setTimeout(() => {
                    setIsLoading(false)
                }, autoCloseTimeLoading);
                const autoCloseTime = 2000;
                const timeout = setTimeout(() => {
                    setShowSave(false)
                }, autoCloseTime);
                return () => clearTimeout(timeout, timeoutId);
            });

        }
    }

    const OnclickTable = (index, index1, key_values) => {
        if (key_values === 'one') {
            setSelectedCells_second([])
            const number = parseInt(index + "" + index1);
            if (!uniqueCombinations_first.has(number)) {
                setUniqueCombinations_first((prevSet) => new Set([...prevSet, number]));
                setUniqueCombinationCount_first((prevCount) => parseInt(prevCount + 1));
            } else {
                // If not unique, remove the old element and decrement the count
                setUniqueCombinations_first((prevSet) => {
                    const newSet = new Set([...prevSet]);
                    newSet.delete(number);
                    return newSet;
                });

                setUniqueCombinationCount_first((prevCount) => parseInt(prevCount - 1));
            }

            setGetindex_first((prevArray) => {
                const existingElementIndex = prevArray.findIndex(
                    (item) => item.indexrow === index && item.indexco === index1
                );
                if (existingElementIndex === -1) {
                    return [...prevArray, { indexrow: index, indexco: index1 }];
                } else {
                    const newArray = [...prevArray];
                    newArray.splice(existingElementIndex, 1);
                    return newArray;
                }
            });
            setWidthsize('');
            setHeightsize('');
            setIndex(index);
            setIndex1(index1);

        } else if (key_values === 'two') {
            setSelectedCells_first([])

            const number = parseInt(index + "" + index1);
            if (!uniqueCombinations_second.has(number)) {
                // If unique, update the set and the count

                setUniqueCombinations_second((prevSet) => new Set([...prevSet, number]));
                setUniqueCombinationCount_second((prevCount) => parseInt(prevCount + 1));
            } else {
                // If not unique, remove the old element and decrement the count
                setUniqueCombinations_second((prevSet) => {
                    const newSet = new Set([...prevSet]);
                    newSet.delete(number);
                    return newSet;
                });

                setUniqueCombinationCount_second((prevCount) => parseInt(prevCount - 1));
            }
            setGetindex_second((prevArray) => {
                const existingElementIndex = prevArray.findIndex(
                    (item) => item.indexrow === index && item.indexco === index1
                );
                if (existingElementIndex === -1) {
                    return [...prevArray, { indexrow: index, indexco: index1 }];
                } else {
                    const newArray = [...prevArray];
                    newArray.splice(existingElementIndex, 1);
                    return newArray;
                }
            });
            setWidthsize('');
            setHeightsize('');
            setIndex(index);
            setIndex1(index1);

        } else {
            setSelectedCells_first([])
            const number = parseInt(index + "" + index1);
            if (!uniqueCombinations_third.has(number)) {
                // If unique, update the set and the count

                setUniqueCombinations_third((prevSet) => new Set([...prevSet, number]));
                setUniqueCombinationCount_third((prevCount) => parseInt(prevCount + 1));
            } else {
                // If not unique, remove the old element and decrement the count
                setUniqueCombinations_third((prevSet) => {
                    const newSet = new Set([...prevSet]);
                    newSet.delete(number);
                    return newSet;
                });
                setUniqueCombinationCount_third((prevCount) => parseInt(prevCount - 1));
            }
            setGetindex_second((prevArray) => {
                const existingElementIndex = prevArray.findIndex(
                    (item) => item.indexrow === index && item.indexco === index1
                );
                if (existingElementIndex === -1) {
                    return [...prevArray, { indexrow: index, indexco: index1 }];
                } else {
                    const newArray = [...prevArray];
                    newArray.splice(existingElementIndex, 1);
                    return newArray;
                }
            });
            setWidthsize('');
            setHeightsize('');
            setIndex(index);
            setIndex1(index1);
        }

    };


    const SelectCell = (rowIndex, colIndex, key_values) => {
        if (key_values === 'one') {
            const isSelected = selectedCells_first.some((cell) => cell.row === rowIndex && cell.col === colIndex);
            if (isSelected) {
                setSelectedCells_first((prevSelected) =>
                    prevSelected.filter((cell) => !(cell.row === rowIndex && cell.col === colIndex))
                );
            } else {
                setSelectedCells_first((prevSelected) => [...prevSelected, { row: rowIndex, col: colIndex }]);
            }

        } else if (key_values === 'two') {
            const isSelected = selectedCells_second.some((cell) => cell.row === rowIndex && cell.col === colIndex);
            if (isSelected) {
                setSelectedCells_second((prevSelected) =>
                    prevSelected.filter((cell) => !(cell.row === rowIndex && cell.col === colIndex))
                );
            } else {
                setSelectedCells_second((prevSelected) => [...prevSelected, { row: rowIndex, col: colIndex }]);
            }

        }

    };
    const changeText = (value, key_values, key, index) => {
        if (key_values === 'create') {

            const object = { ...listtext[index] };
            object[key] = value;
            const cloneData = [...listtext];
            cloneData[index] = { ...object };
            setListText([...cloneData]);
        } else {
            const object = { ...usetextlist[index] };
            object[key] = value;
            const cloneData = [...usetextlist];
            cloneData[index] = { ...object };
            setUsetextlist([...cloneData]);

        }

    };
    const changeText2 = (value, key, index, index1) => {
        setInputValues(value)
        const cloneTables = [...tables]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...tables]
        setTables([...cloneData])
    };
    const changeText3 = (value, key, index, index1) => {
        setInputValues1(value)
        const cloneTables = [...tables]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...tables]
        setTables([...cloneData])
    };
    const changeText2_edit = (value, key, index, index1) => {
        setInputValues(value)
        const cloneTables = [...usetable]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...usetable]
        setUsetable([...cloneData])
    };
    const changeText3_edit = (value, key, index, index1) => {
        setInputValues1(value)
        const cloneTables = [...usetable2]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...usetable2]
        setUsetable1([...cloneData])
    };


    const changeTextForsize = (value, key, index) => {
        const object = { ...listtext[index] };
        object[key] = value;
        const cloneData = [...listtext];
        cloneData[index] = { ...object };
        setListText([...cloneData]);
        setListTextw([cloneData[index]]);
    };
    const Onclicktextlist = (index) => {
        setMenucondigions('input')
        const cloneData = listtext[index]
        cloneData.index = index
        setListTextw([cloneData])

    }
    const OnclickTextUpdate = (index) => {
        setMenucondigions('input')
        const cloneData = usetextlist[index]
        cloneData.index = index
        setListTextw([cloneData])
    }
    const deletechange = (index) => {
        const cloneData = [...listtext]
        const cloneData1 = [...listtextw]
        cloneData.splice(index, 1)
        cloneData1.splice(index, 1)
        setListText([...cloneData])
        setListTextw([...cloneData1])
    }
    const onAddNewItem = () => {

        setDataText([...dataText, { name: 'Text', positionX: 50, positionY: 100, width: 0, height: 0, font: '15px', fontWeight: '', type: 'lable' }])
    }
    const onAddNewCheckbox = () => {

        const newcheckbox = { name: 'Checkbox', positionX: 0, positionY: 0, width: 50, height: 0, type: 'checkbox' }
        const cloneData = [...datacheckbox]
        cloneData.push(newcheckbox)
        setDatacheckbox([...cloneData])
    }
    const handlePictureChange = (event) => {
        setFile(event.target.files);
        const picture = event.target.files[0];
        const selectedFiles = event.target.files;
        const file_attachment = Array.from(selectedFiles);
        setSelectedImage([{ name: file_attachment[0].name, positionX: 100, positionY: 0, width: 100, height: 100, type: 'image' }])
        if (picture) {
            const pictureUrl = URL.createObjectURL(picture);
            console.log("pictureUrl=", pictureUrl)
            setPictureUrl(pictureUrl);
        }
    };
    const onCreateTable = () => {
        setSelectedCells_first([])
        setSelectedCells_second([])
        setSelectedCells_third([])
        if (usetable.length == 0) {
            setShow(false)
            setShowdeltable(true)
            if (!tableRow || !tableColumn) return
            const newItemtable = {
                name: '',
                positionX: 0,
                positionY: 0,
                width: 749,
                height: 200,
                countrow: tableRow,
                type: 'table',
            }


            const cloneData = [...datatable]
            cloneData.push(newItemtable)
            setDatatable([...cloneData])
            let item = [];
            for (let i = 0; i < tableColumn; i++) {
                let row = tableRow
                let data;
                if (row == 1) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 2) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 3) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 4) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 5) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 6) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 7) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 8) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 9) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 10) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                }
                item.push(data)
            }
            setTables(item)

        } else if (usetable1.length == 0) {
            setShow(false)
            if (!tableRow || !tableColumn) return
            const newItemtable1 = {
                name: '',
                positionX: 0,
                positionY: 141,
                width: 749,
                height: 200,
                type: 'table',
                countrow: tableRow,
            }
            const cloneData = [...datatable1]
            cloneData.push(newItemtable1)
            setDatatable1([...cloneData])
            let item1 = [];
            for (let i = 0; i < tableColumn; i++) {
                let row = tableRow
                let data;
                if (row == 1) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 2) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 3) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 4) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 5) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 6) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 7) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 8) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 9) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 10) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                }
                item1.push(data)
            }
            setTables1(item1)
        } else if (usetable2.length == 0) {
            setShow(false)

            if (!tableRow || !tableColumn) return
            const newItemtable2 = {
                name: '',
                positionX: 0,
                positionY: 290,
                width: 749,
                height: 200,
                type: 'table',
                countrow: tableRow,
            }
            const cloneData = [...datatable2]
            cloneData.push(newItemtable2)
            setDatatable2([...cloneData])
            let item2 = [];
            for (let i = 0; i < tableColumn; i++) {
                let row = tableRow
                let data;
                if (row == 2) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 3) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 4) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
                } else if (row == 5) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 6) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 7) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 8) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 9) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                } else if (row == 10) {
                    data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

                }
                item2.push(data)
            }
            setTables2(item2)
        }
    }

    const OnloadListData = () => {
        axios.get(`/api/document-management/form/get-form/${id}`).then((data) => {
            console.log("lllllllll=", data)


            setformstatus([...data?.data?.dataForms][0].formstatus)
            if (data?.data?.resuGetTable_sizeforinputlts !== null) {
                setUsetextlist([...data?.data?.resuGetTable_sizeforinputlts])
            }
            if (data?.data?.GetImage_positions !== null) {
                setSelectedImage_show([...data?.data?.GetImage_positions])
                setPictureUrl([...data?.data?.GetImage_positions][0].images[0]);
            }
            if (data?.data?.GetTable_checkbox !== null) {
                setUsecheckbox([...data?.data?.GetTable_checkbox])
            }
            if (data?.data?.GetTable_label !== null) {
                setUselable([...data?.data?.GetTable_label])
            }
            if (data?.data?.GetTable_position_one !== null) {
                setUsetable([...data?.data?.GetTable_position_one])
                setCountRow([...data?.data?.GetTable_position_one][0].countrow)
                setTablechidren(JSON.parse([...data?.data?.GetTable_position_one][0].body_table))
            }
            if (data?.data?.GetTable_positions_two !== null) {
                setUsetable1([...data?.data?.GetTable_positions_two])
                setCountRow1([...data?.data?.GetTable_positions_two][0].countrow)
                setTablechidren1(JSON.parse([...data?.data?.GetTable_positions_two][0].body_table))
            }
            setLoading(false)

        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadListData()
    }, [])
    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', marginTop: 50,display:'flex'  }}>
                sdfsafas
                {
                    usetextlist && usetextlist.map((e, index) => {
                        return (
                            < Rnd
                                default={{
                                    x: e.positionX,
                                    y: e.positionY,
                                }}
                                onDragStop={(e, d) => onDragStopinputupdate(e, d, index)}
                            >
                                <ComponentInput_update
                                    index={index}
                                    changeText={changeText}
                                    e={e}
                                    OnclickTextUpdate={OnclickTextUpdate}
                                />
                            </Rnd>
                        )
                    })
                }
            </div>
        </div>
    )
}
function ComponentInput_update({ OnclickTextUpdate, changeText, e, index }) {
    return (
        <>
            <input
                style={{
                    border: '1px solid #5EBFE3',
                    width: `${e.width}px`,
                    height: `${e.height}px`,
                    border: 'none',
                }}
                placeholder="................................................................................................................................................................................................................................................................................................"
                value={e?.name}
                onChange={(el) => changeText(el.target.value, 'edit', "name", index)}
                onClick={() => { OnclickTextUpdate(index) }}

            />
        </>

    )
}