import React from 'react'
import axios from 'axios'
export const httpErrorHandler = (err,setIsModalOpen) => {
  if (err === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(err)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = err?.response
    const request = err?.request
    const config = err?.config //here we have access the config used to make the api call (we can make a retry using this conf)
    if (err.code === 'ERR_NETWORK') {
      // setCheckConditions(true)
      console.log('connection problems..')
    } else if (err.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
    } else if (err.code === 'ERR_BAD_REQUEST') {
      console.log('Connections ')
    }else if(err.code === 'ERR_BAD_RESPONSE'){
      console.log("Connectionss")
      
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response?.status
      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted')
      } else if (statusCode === 401) {
        console.log('Please login to access this resource')
        //redirect user to login
      } else if (statusCode === 403) { 
        setIsModalOpen(true)
          // window.location.assign('https://secure.phongsavanhgroup.com/');
        setTimeout(() => {
          window.location.assign('https://secure.phongsavanhgroup.com/');
        }, 2000);
      }else if (statusCode === 500){
        console.log("statusCode")
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
}
