import React, { useState, useRef, useContext, useEffect } from "react";
import ChatIcon from '@material-ui/icons/Chat';
import PrintIcon from '@material-ui/icons/Print';
import { Rnd } from 'react-rnd';
import { Input } from "./Formmenu/Input";
import { TableMenu } from "./Formmenu/TableMenu";
import { LoginContext } from "../page/contexts/LoginContext";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { TextMenu } from "./Formmenu/TextMenu";
import { CheckBoxMenu } from "./Formmenu/CheckBoxMenu";
import { ImageMenu } from "./Formmenu/ImageMenu";
import CancelIcon from '@material-ui/icons/Cancel';
import axios from "axios";
import { TextMenuEdit } from "./Formmenu/TextMenuEdit";
import { ModalSave } from "./ModalSave";
import { Chats } from "./Chats";
import Cookies from 'js-cookie';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MadalSaveusingForm from "./MadalSaveusingForm";

export const UserusingFormTest = (props) => {
    const { id } = props;
    let users = Cookies.get("user");
    let data_user = JSON.parse(users)
    let user_id = data_user?.id

    const componentRef = useRef();
    const handlePrintToPDF = () => {
        const input = componentRef.current;
        if (input) {
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save('document.pdf');
                window.open(pdf.output('bloburl'), '_blank');
            });
        }
    };
    const [active, setActive] = useState("");
    const [listtext, setListText] = useState([])
    const [listtextw, setListTextw] = useState([])
    const [show, setShow] = useState(false);
    const [tableRow, setTableRow] = useState('')
    const [tableColumn, setTableColumn] = useState('')
    const [showdeltable, setShowdeltable] = useState(true)
    const [showdeltable1, setShowdeltable1] = useState(false)
    const [showdeltable2, setShowdeltable2] = useState(false)
    const [uselable, setUselable] = useState([])
    const [editcheckboxvalues, setEditcheckboxvalues] = useState('')
    const [widthsize, setWidthsize] = useState('')
    const [heightsize, setHeightsize] = useState('')
    const [dataText, setDataText] = useState([])
    const [index, setIndex] = useState('')
    const [index1, setIndex1] = useState('')
    const [tables, setTables] = useState([[]])
    const [tables1, setTables1] = useState([[]])
    const [tables2, setTables2] = useState([[]])
    const [datatable, setDatatable] = useState([])
    const [datatable1, setDatatable1] = useState([])
    const [datatable2, setDatatable2] = useState([])
    const [getindex_first, setGetindex_first] = useState([])
    const [getindex_second, setGetindex_second] = useState([])
    const [getindex_third, setGetindex_third] = useState([])
    const [get_index, setGet_index] = useState('')
    const [menucondigions, setMenucondigions] = useState('')
    const [conditions_text, setConditions_text] = useState('')
    const [conditions_checkbox, setConditions_checkbox] = useState('')
    const [conditions_image, setConditions_image] = useState('')
    const [conditions_table, setConditions_table] = useState('')
    const [inputValues, setInputValues] = useState('')
    const [inputValues1, setInputValues1] = useState('')
    const [editlable, setEditlable] = useState('')
    const [fontSize, setFontSize] = useState('')
    const [datacheckbox, setDatacheckbox] = useState([])
    const [uniqueCombinationCount_first, setUniqueCombinationCount_first] = useState(0);
    const [uniqueCombinationCount_second, setUniqueCombinationCount_second] = useState(0);
    const [uniqueCombinationCount_third, setUniqueCombinationCount_third] = useState(0);
    const [uniqueCombinations_first, setUniqueCombinations_first] = useState(new Set());
    const [uniqueCombinations_second, setUniqueCombinations_second] = useState(new Set());
    const [uniqueCombinations_third, setUniqueCombinations_third] = useState(new Set());
    const [selectedCells_first, setSelectedCells_first] = useState([])
    const [selectedCells_second, setSelectedCells_second] = useState([])
    const [selectedCells_third, setSelectedCells_third] = useState([])
    const [showSave, setShowSave] = useState(false);
    const [saveconditions, setSaveconditions] = useState('')
    const [saveAs, setSaveAs] = useState('')
    const [formstatus, setformstatus] = useState('')
    const [usetextlist, setUsetextlist] = useState([])
    const [usecheckbox, setUsecheckbox] = useState([])
    const [usetable, setUsetable] = useState([])
    const [countRow, setCountRow] = useState('')
    const [countRow1, setCountRow1] = useState('')
    const [countRow2, setCountRow2] = useState('')
    const [countRow3, setCountRow3] = useState('')
    const [tablechidren, setTablechidren] = useState([])
    const [tablechidren1, setTablechidren1] = useState([])
    const [tablechidren2, setTablechidren2] = useState([])
    const [tablechidren3, setTablechidren3] = useState([])
    const [usetable1, setUsetable1] = useState([])
    const [usetable2, setUsetable2] = useState([])
    const [usetable3, setUsetable3] = useState([])
    const [file, setFile] = useState();
    const [selectedImage, setSelectedImage] = useState([])
    const [selectedImage_show, setSelectedImage_show] = useState([])
    const [ing_width, setIng_width] = useState('')
    const [ing_heigh, setIng_heigh] = useState('')
    const [pictureUrl, setPictureUrl] = useState(null);
    const [showsave, setShowsave] = useState(false);
    const [saveforusing, setSaveforusing] = useState(false)

    const [isLoading, setIsLoading,] = useState(false);
    const handleClosedel = () => {
        setShow(false);
    }


    const {
        setShowUserusingFormScreen,
    } = useContext(LoginContext)
    const OncloseUsingformScreen = () => {
        setShowUserusingFormScreen(false)
    };
    const handleChange = (e) => {
        setSaveconditions(e);
    };



    const handleCloseSavle = () => {
        setSaveforusing(false)
    }
    const handleClose = () => {
        setSaveAs(false)
    }
    const handlesaveShow = () => setSaveforusing(true)

    const onDragStopinput = (e, d, index) => {
        const cloneDatas = [...listtext]
        const cloneData = { ...listtext[index] }
        cloneData.positionX = d.x;
        cloneData.positionY = d.y
        cloneDatas[index] = cloneData
        setListText([...cloneDatas])
    }
    const onDragStoptable = (e, d, index, key_conditions, key_values) => {
        if (key_conditions === 'create') {
            if (key_values === 'one') {
                const cloneDatas = [...datatable]
                const cloneData = { ...datatable[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setDatatable([...cloneDatas])
            } else if (key_values === 'two') {
                const cloneDatas = [...datatable1]
                const cloneData = { ...datatable1[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setDatatable1([...cloneDatas])

            } else {
                const cloneDatas = [...datatable2]
                const cloneData = { ...datatable2[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setDatatable2([...cloneDatas])
            }

        } else {
            if (key_values === 'one') {
                const cloneDatas = [...usetable]
                const cloneData = { ...usetable[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setUsetable([...cloneDatas])
            } else if (key_values === 'two') {
                const cloneDatas = [...usetable1]
                const cloneData = { ...usetable1[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setUsetable1([...cloneDatas])

            } else {
                const cloneDatas = [...usetable2]
                const cloneData = { ...usetable2[index] }
                cloneData.positionX = d.x;
                cloneData.positionY = d.y
                cloneDatas[index] = cloneData
                setUsetable2([...cloneDatas])
            }

        }
    }
    const onDragStopDataText = (e, d, index, key_values) => {
        if (key_values === 'create') {
            const cloneDatas = [...dataText]
            const cloneData = { ...dataText[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setDataText([...cloneDatas])
        } else {
            const cloneDatas = [...uselable]
            const cloneData = { ...uselable[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setUselable([...cloneDatas])
        }
    }


    const onDragStopcheckbox = (e, d, index, key_values) => {
        if (key_values === 'create') {
            const cloneDatas = [...datacheckbox]
            const cloneData = { ...datacheckbox[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setDatacheckbox([...cloneDatas])
        } else {
            const cloneDatas = [...usecheckbox]
            const cloneData = { ...usecheckbox[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setUsecheckbox([...cloneDatas])

        }


    }

    const onDragStopinputupdate = (e, d, index) => {
        const cloneDatas = [...usetextlist]
        const cloneData = { ...usetextlist[index] }
        cloneData.positionX = d.x;
        cloneData.positionY = d.y
        cloneDatas[index] = cloneData
        setUsetextlist([...cloneDatas])
    }
    const onDragImagelogo = (e, d, index, key_values) => {
        if (key_values === 'create') {
            const cloneDatas = [...selectedImage]
            const cloneData = { ...selectedImage[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setSelectedImage([...cloneDatas])

        } else {
            const cloneDatas = [...selectedImage_show]
            const cloneData = { ...selectedImage_show[index] }
            cloneData.positionX = d.x;
            cloneData.positionY = d.y
            cloneDatas[index] = cloneData
            setSelectedImage_show([...cloneDatas])

        }
    }
    const OnClickCheckimage = (index, key_values) => {
        setMenucondigions('Image')
        setConditions_image(key_values)
        if (key_values === 'create') {
            setGet_index(index)
            const cloneData = selectedImage[index]
            setIng_width(cloneData.width)
            setIng_heigh(cloneData.height)

        } else {
            setGet_index(index)
            const cloneData = selectedImage_show[index]
            setIng_width(cloneData.width)
            setIng_heigh(cloneData.height)
        }

    }
    const changeTextImage_width = (value, key, index) => {
        setIng_width(value)
        const object = { ...selectedImage[index] };
        object[key] = value;
        const cloneData = [...selectedImage];
        cloneData[index] = { ...object };
        setSelectedImage([...cloneData])
    }
    const changeTextImage_width_edit = (value, key, index) => {
        setIng_width(value)
        const object = { ...selectedImage_show[index] };
        object[key] = value;
        const cloneData = [...selectedImage_show];
        cloneData[index] = { ...object };
        setSelectedImage_show([...cloneData])
    }
    const changeTextImage_height = (value, key, index) => {
        setIng_heigh(value)
        const object = { ...selectedImage[index] };
        object[key] = value;
        const cloneData = [...selectedImage];
        cloneData[index] = { ...object };
        setSelectedImage([...cloneData])
    }
    const changeTextImage_height_edit = (value, key, index) => {
        setIng_heigh(value)
        const object = { ...selectedImage_show[index] };
        object[key] = value;
        const cloneData = [...selectedImage_show];
        cloneData[index] = { ...object };
        setSelectedImage_show([...cloneData])
    }
    const OnClickCheckbox = (index, key_values) => {
        setMenucondigions('CheckBox')
        setConditions_checkbox(key_values)
        if (key_values === 'create') {
            setGet_index(index)
            const cloneData = datacheckbox[index]
            setEditcheckboxvalues(cloneData.name)

        } else {
            setGet_index(index)
            const cloneData = usecheckbox[index]
            setEditcheckboxvalues(cloneData.name)
        }
    }
    const deletecheckbox = (index) => {
        setEditcheckboxvalues('')
        const cloneData = [...datacheckbox]
        cloneData.splice(index, 1)
        setDatacheckbox([...cloneData])
    }
    const deletecheckbox_edit = (index) => {
        setEditcheckboxvalues('')
        const cloneData = [...usecheckbox]
        cloneData.splice(index, 1)
        setUsecheckbox([...cloneData])
    }
    const onClickDataText = (index, key_values) => {
        console.log("index=", index)
        setMenucondigions('Text')
        setConditions_text(key_values)
        if (key_values === 'create') {
            setGet_index(index)
            const cloneData = dataText[index]
            setEditlable(cloneData.name)
            setFontSize(cloneData.font)
        } else {
            setGet_index(index)
            const cloneData = uselable[index]
            setEditlable(cloneData.name)
            setFontSize(cloneData.font)
        }
    }
    const Onclicktableindex = (index, key_conditions, key) => {
        setMenucondigions('table')
        setConditions_table(key_conditions)
        if (key_conditions === 'create') {
            if (key === 'one') {
                setShowdeltable(true)
                setShowdeltable1(false)
                setShowdeltable2(false)
                setGet_index(index)

            } else if (key === 'two') {
                setShowdeltable(false)
                setShowdeltable1(true)
                setShowdeltable2(false)
                setGet_index(index)

            } else {
                setShowdeltable(false)
                setShowdeltable1(false)
                setShowdeltable2(true)
                setGet_index(index)
            }

        } else {
            if (key === 'one') {
                // setShowdeltable(true)
                // setShowdeltable1(false)
                // setShowdeltable2(false)
                setGet_index(index)

            } else if (key === 'two') {
                // setShowdeltable(false)
                // setShowdeltable1(true)
                // setShowdeltable2(false)
                setGet_index(index)

            } else {
                // setShowdeltable(false)
                // setShowdeltable1(false)
                // setShowdeltable2(true)
                setGet_index(index)
            }

        }

    }
    const changeText_values_table = (value, key, index1, index, key_conditions, kev_values,) => {
        if (key_conditions === 'create') {
            if (kev_values === 'one') {
                const cloneTables = [...tables]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tables]
                setTables([...cloneData])
            } else if (kev_values === 'two') {
                const cloneTables = [...tables1]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tables1]
                setTables1([...cloneData])
            } else {
                const cloneTables = [...tables2]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tables2]
                setTables2([...cloneData])
            }

        } else {
            if (kev_values === 'one') {
                const cloneTables = [...tablechidren]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tablechidren]
                setTablechidren([...cloneData])
            } else if (kev_values === 'two') {
                const cloneTables = [...tablechidren1]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tablechidren1]
                setTablechidren1([...cloneData])
            } else {
                const cloneTables = [...tablechidren2]
                const datarow = cloneTables[index1]
                const object = datarow[index]
                object[key] = value;
                const cloneData = [...tablechidren2]
                setTablechidren2([...cloneData])
            }

        }

    };
    const changeTextwidth = (value, key, index, index1, key_values) => {
        setWidthsize(value)
        if (key_values === 'one') {
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables]
            setTables([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables1]
            setTables1([...cloneData])

        } else {
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables2]
            setTables2([...cloneData])
        }
    };
    const changeTextwidth_edit = (value, key, index, index1, key_values) => {
        setWidthsize(value)
        if (key_values === 'one') {
            const cloneTables = [...usetable]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable]
            setUsetable([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...usetable1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable1]
            setUsetable([...cloneData])

        } else {
            const cloneTables = [...usetable2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable2]
            setUsetable2([...cloneData])
        }
    };
    const changeTextheight = (value, key, index, index1, key_values) => {
        setHeightsize(value)
        if (key_values === 'one') {
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables]
            setTables([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables1]
            setTables1([...cloneData])
        } else {
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...tables2]
            setTables2([...cloneData])
        }
    };
    const changeTextheight_edit = (value, key, index, index1, key_values) => {
        setHeightsize(value)
        if (key_values === 'one') {
            const cloneTables = [...usetable]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable]
            setUsetable([...cloneData])
        } else if (key_values === 'two') {
            const cloneTables = [...usetable1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable1]
            setUsetable1([...cloneData])
        } else {
            const cloneTables = [...usetable2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = value;
            const cloneData = [...usetable2]
            setUsetable2([...cloneData])
        }
    };
    const MergeCellcolumn = (key_values) => {
        if (key_values === 'one') {
            const resultArray = getindex_first.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'rows'
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_first;
            const cloneData = [...tables]
            setTables([...cloneData])
            const Array = getindex_first.slice(1);
            const clone_Tables = [...tables];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables([...clone_Tables]);
            setGetindex_first([])
            setSelectedCells_first([])
            setUniqueCombinationCount_first([])
        } else if (key_values === 'two') {
            const resultArray = getindex_second.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'rows'
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_second;
            const cloneData = [...tables1]
            setTables1([...cloneData])
            const Array = getindex_second.slice(1);
            const clone_Tables = [...tables1];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables1([...clone_Tables]);
            setGetindex_second([])
            setSelectedCells_second([])
            setUniqueCombinationCount_second([])

        } else {
            const resultArray = getindex_third.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'rows'
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_third;
            const cloneData = [...tables2]
            setTables([...cloneData])
            const Array = getindex_third.slice(1);
            const clone_Tables = [...tables2];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables2([...clone_Tables]);
            setGetindex_third([])
            setSelectedCells_second([])
            setUniqueCombinationCount_third([])
        }
    }

    const MergeCellRow = (key_values) => {
        if (key_values === 'one') {
            const resultArray = getindex_first.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'cols'
            const cloneTables = [...tables]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_first;
            const cloneData = [...tables]
            setTables([...cloneData])
            const Array = getindex_first.slice(1);
            const clone_Tables = [...tables];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables([...clone_Tables]);
            setGetindex_first([])
            setSelectedCells_first([])
            setUniqueCombinationCount_first([])

        } else if (key_values === 'two') {
            const resultArray = getindex_second.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'cols'
            const cloneTables = [...tables1]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_second;
            const cloneData = [...tables1]
            setTables1([...cloneData])
            const Array = getindex_second.slice(1);
            const clone_Tables = [...tables1];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables1([...clone_Tables]);
            setGetindex_second([])
            setSelectedCells_second([])
            setUniqueCombinationCount_second([])

        } else {
            const resultArray = getindex_third.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const key = 'cols'
            const cloneTables = [...tables2]
            const datarow = cloneTables[index1]
            const object = datarow[index]
            object[key] = uniqueCombinationCount_third;
            const cloneData = [...tables2]
            setTables2([...cloneData])
            const Array = getindex_second.slice(1);
            const clone_Tables = [...tables2];
            Array.forEach((e) => {
                const deleteObject = clone_Tables[e.indexco];
                deleteObject.splice(e.indexrow, 1);
            });
            setTables2([...clone_Tables]);
            setGetindex_third([])
            setSelectedCells_third([])
            setUniqueCombinationCount_third([])
        }
    }
    const MergeCellRowAll = (key_values) => {
        if (key_values === 'one') {

            const resultArray = getindex_first.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const cloneTables = [...tables]
            const deleteObject = cloneTables[index1]
            deleteObject.splice(index, 1);
            const cloneData = [...tables]
            setTables([...cloneData])

        } else if (key_values === 'two') {
            const resultArray = getindex_second.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const cloneTables = [...tables1]
            const deleteObject = cloneTables[index1]
            deleteObject.splice(index, 1);
            const cloneData = [...tables1]
            setTables1([...cloneData])
        } else {
            const resultArray = getindex_third.slice(0, 1);
            const index = resultArray[0].indexrow
            const index1 = resultArray[0].indexco
            const cloneTables = [...tables2]
            const deleteObject = cloneTables[index1]
            deleteObject.splice(index, 1);
            const cloneData = [...tables2]
            setTables2([...cloneData])

        }
    }
    const changeLable = (value, key, getindex) => {
        setEditlable(value)
        const object = { ...dataText[getindex] };
        object[key] = value;
        const cloneData = [...dataText];
        cloneData[getindex] = { ...object };
        setDataText([...cloneData]);
    }
    const changeLable_edit = (value, key, getindex) => {
        setEditlable(value)
        const object = { ...uselable[getindex] };
        object[key] = value;
        const cloneData = [...uselable];
        cloneData[getindex] = { ...object };
        setUselable([...cloneData]);

    }
    const changeCheckbox = (value, key, getindex) => {
        setEditcheckboxvalues(value)
        const object = { ...datacheckbox[getindex] };
        object[key] = value;
        const cloneData = [...datacheckbox];
        cloneData[getindex] = { ...object };
        setDatacheckbox([...cloneData]);

    }
    const changeCheckbox_edit = (value, key, getindex) => {
        setEditcheckboxvalues(value)
        const object = { ...usecheckbox[getindex] };
        object[key] = value;
        const cloneData = [...usecheckbox];
        cloneData[getindex] = { ...object };
        setUsecheckbox([...cloneData]);

    }
    const deleteDataText = (index) => {
        const cloneData = [...dataText]
        cloneData.splice(index, 1)
        setDataText([...cloneData])
    }
    const deleteUseText = (index) => {
        const cloneData = [...uselable]
        cloneData.splice(index, 1)
        setUselable([...cloneData])
    }
    const deletetable_edit = (get_index, key_values) => {
        setSelectedCells_first([])
        setSelectedCells_second([])
        setSelectedCells_third([])
        setGetindex_first([])
        setGetindex_second([])
        setGetindex_third([])
        if (key_values === 'one') {
            setShowdeltable(false)
            const cloneData = [...usetable]
            cloneData.splice(get_index, 1)
            setUsetable([...cloneData])

        } else if (key_values === 'two') {
            setShowdeltable(false)
            const cloneData = [...datatable1]
            cloneData.splice(get_index, 1)
            setDatatable1([...cloneData])
        } else {
            setShowdeltable(false)
            const cloneData = [...datatable2]
            cloneData.splice(get_index, 1)
            setDatatable2([...cloneData])
        }
    }
    const deletetable = (get_index, key_values) => {
        setSelectedCells_first([])
        setSelectedCells_second([])
        setSelectedCells_third([])
        setGetindex_first([])
        setGetindex_second([])
        setGetindex_third([])
        if (key_values === 'one') {
            setShowdeltable(false)
            const cloneData = [...datatable]
            cloneData.splice(get_index, 1)
            setDatatable([...cloneData])

        } else if (key_values === 'two') {
            setShowdeltable(false)
            const cloneData = [...datatable1]
            cloneData.splice(get_index, 1)
            setDatatable1([...cloneData])
        } else {
            setShowdeltable(false)
            const cloneData = [...datatable2]
            cloneData.splice(get_index, 1)
            setDatatable2([...cloneData])
        }
    }
    const OnCreate = async () => {

        let images;
        if (!file) {
            images = 0
        } else {
            let formData = new FormData();
            for (const key of Object.keys(file)) {
                formData.append("file_name", file[key]);
            }
            formData.append("file_name", file);
            let profileImageReturnName = await axios.post("/api/document-management/req/upload", formData);
            images = Object.values(profileImageReturnName.data)[0][0]
        }
        if (saveconditions === '1') {
            console.log("IN")
            let informdata = {
                form_id: id,
                title: saveAs,
                req_status: 0,
                docno_status: 0,
                doc_no: 0,
                created_by: user_id,
                InsertDataCheckbox: usecheckbox,
                InsertDataLable: uselable,
                InsertPositionOne: usetable,
                DataTablepositionOne: tablechidren,
                InsertPositionsTwo: usetable1,
                DataTablepositiontwo: tablechidren1,
                InsertPositionsThree: '',
                DataTablepositionThree: '',
                InsertSizeForInput: usetextlist,
                InsertDataImage: selectedImage_show,
                file_name: images
            }

            console.log("Insert_in=", informdata)
            axios.post("/api/document-management/req/insert-request-form", informdata).then((data) => {
                console.log("Successfully=", data)
                setSaveforusing(false);
                setSaveconditions('')
            }).catch((err) => {
                console.log(err)
            })
        } else {
            console.log("Out_out")
    
            let informdata = {
                form_id: id,
                title: saveAs,
                req_status: 0,
                docno_status: 0,
                doc_no: 0,
                created_by: user_id,
                InsertDataCheckbox: usecheckbox,
                InsertDataLable: uselable,
                InsertPositionOne: usetable,
                DataTablepositionOne: tablechidren,
                InsertPositionsTwo: usetable1,
                DataTablepositiontwo: tablechidren1,
                InsertPositionsThree: '',
                DataTablepositionThree: '',
                InsertSizeForInput: usetextlist,
                InsertDataImage: selectedImage_show,
                file_name: images
            }

            axios.post("/api/document-management/outgoing/insert-outgoing-form", informdata).then((data) => {
        
                setSaveforusing(false);
                setSaveconditions('')
            }).catch((err) => {
                console.log(err)
            })
        }
    }
    const OnclickTable = (index, index1, key_values) => {
        if (key_values === 'one') {
            setSelectedCells_second([])
            const number = parseInt(index + "" + index1);
            if (!uniqueCombinations_first.has(number)) {
                setUniqueCombinations_first((prevSet) => new Set([...prevSet, number]));
                setUniqueCombinationCount_first((prevCount) => parseInt(prevCount + 1));
            } else {
                // If not unique, remove the old element and decrement the count
                setUniqueCombinations_first((prevSet) => {
                    const newSet = new Set([...prevSet]);
                    newSet.delete(number);
                    return newSet;
                });

                setUniqueCombinationCount_first((prevCount) => parseInt(prevCount - 1));
            }

            setGetindex_first((prevArray) => {
                const existingElementIndex = prevArray.findIndex(
                    (item) => item.indexrow === index && item.indexco === index1
                );
                if (existingElementIndex === -1) {
                    return [...prevArray, { indexrow: index, indexco: index1 }];
                } else {
                    const newArray = [...prevArray];
                    newArray.splice(existingElementIndex, 1);
                    return newArray;
                }
            });
            setWidthsize('');
            setHeightsize('');
            setIndex(index);
            setIndex1(index1);

        } else if (key_values === 'two') {
            setSelectedCells_first([])

            const number = parseInt(index + "" + index1);
            if (!uniqueCombinations_second.has(number)) {
                // If unique, update the set and the count

                setUniqueCombinations_second((prevSet) => new Set([...prevSet, number]));
                setUniqueCombinationCount_second((prevCount) => parseInt(prevCount + 1));
            } else {
                // If not unique, remove the old element and decrement the count
                setUniqueCombinations_second((prevSet) => {
                    const newSet = new Set([...prevSet]);
                    newSet.delete(number);
                    return newSet;
                });

                setUniqueCombinationCount_second((prevCount) => parseInt(prevCount - 1));
            }
            setGetindex_second((prevArray) => {
                const existingElementIndex = prevArray.findIndex(
                    (item) => item.indexrow === index && item.indexco === index1
                );
                if (existingElementIndex === -1) {
                    return [...prevArray, { indexrow: index, indexco: index1 }];
                } else {
                    const newArray = [...prevArray];
                    newArray.splice(existingElementIndex, 1);
                    return newArray;
                }
            });
            setWidthsize('');
            setHeightsize('');
            setIndex(index);
            setIndex1(index1);

        } else {
            setSelectedCells_first([])
            const number = parseInt(index + "" + index1);
            if (!uniqueCombinations_third.has(number)) {
                // If unique, update the set and the count

                setUniqueCombinations_third((prevSet) => new Set([...prevSet, number]));
                setUniqueCombinationCount_third((prevCount) => parseInt(prevCount + 1));
            } else {
                // If not unique, remove the old element and decrement the count
                setUniqueCombinations_third((prevSet) => {
                    const newSet = new Set([...prevSet]);
                    newSet.delete(number);
                    return newSet;
                });
                setUniqueCombinationCount_third((prevCount) => parseInt(prevCount - 1));
            }
            setGetindex_second((prevArray) => {
                const existingElementIndex = prevArray.findIndex(
                    (item) => item.indexrow === index && item.indexco === index1
                );
                if (existingElementIndex === -1) {
                    return [...prevArray, { indexrow: index, indexco: index1 }];
                } else {
                    const newArray = [...prevArray];
                    newArray.splice(existingElementIndex, 1);
                    return newArray;
                }
            });
            setWidthsize('');
            setHeightsize('');
            setIndex(index);
            setIndex1(index1);
        }

    };


    const SelectCell = (rowIndex, colIndex, key_values) => {
        if (key_values === 'one') {
            const isSelected = selectedCells_first.some((cell) => cell.row === rowIndex && cell.col === colIndex);
            if (isSelected) {
                setSelectedCells_first((prevSelected) =>
                    prevSelected.filter((cell) => !(cell.row === rowIndex && cell.col === colIndex))
                );
            } else {
                setSelectedCells_first((prevSelected) => [...prevSelected, { row: rowIndex, col: colIndex }]);
            }

        } else if (key_values === 'two') {
            const isSelected = selectedCells_second.some((cell) => cell.row === rowIndex && cell.col === colIndex);
            if (isSelected) {
                setSelectedCells_second((prevSelected) =>
                    prevSelected.filter((cell) => !(cell.row === rowIndex && cell.col === colIndex))
                );
            } else {
                setSelectedCells_second((prevSelected) => [...prevSelected, { row: rowIndex, col: colIndex }]);
            }
        }

    };
    const changeText = (value, key_values, key, index) => {
        if (key_values === 'create') {
            const object = { ...listtext[index] };
            object[key] = value;
            const cloneData = [...listtext];
            cloneData[index] = { ...object };
            setListText([...cloneData]);

        } else {
            const object = { ...usetextlist[index] };
            object[key] = value;
            const cloneData = [...usetextlist];
            cloneData[index] = { ...object };
            setUsetextlist([...cloneData]);
        }

    };
    const changeText2 = (value, key, index, index1) => {
        setInputValues(value)
        const cloneTables = [...tables]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...tables]
        setTables([...cloneData])
    };
    const changeText3 = (value, key, index, index1) => {
        setInputValues1(value)
        const cloneTables = [...tables]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...tables]
        setTables([...cloneData])
    };
    const changeText2_edit = (value, key, index, index1) => {
        setInputValues(value)
        const cloneTables = [...usetable]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...usetable]
        setUsetable([...cloneData])
    };
    const changeText3_edit = (value, key, index, index1) => {
        setInputValues1(value)
        const cloneTables = [...usetable2]
        const datarow = cloneTables[index1]
        const object = datarow[index]
        object[key] = value;
        const cloneData = [...usetable2]
        setUsetable1([...cloneData])
    };


    const changeTextForsize = (value, key, index) => {
        const object = { ...listtext[index] };
        object[key] = value;
        const cloneData = [...listtext];
        cloneData[index] = { ...object };
        setListText([...cloneData]);
        setListTextw([cloneData[index]]);
    };
    const Onclicktextlist = (index) => {
        setMenucondigions('input')
        const cloneData = listtext[index]
        cloneData.index = index
        setListTextw([cloneData])

    }
    const OnclickTextUpdate = (index) => {
        setMenucondigions('input')
        const cloneData = usetextlist[index]
        cloneData.index = index
        setListTextw([cloneData])
    }
    const deletechange = (index) => {
        const cloneData = [...listtext]
        const cloneData1 = [...listtextw]
        cloneData.splice(index, 1)
        cloneData1.splice(index, 1)
        setListText([...cloneData])
        setListTextw([...cloneData1])
    }
    const OnloadListData = () => {
        axios.get(`/api/document-management/form/get-form/${id}`).then((data) => {
            setformstatus([...data?.data?.dataForms][0].formstatus)
            if (data?.data?.resuGetTable_sizeforinputlts === null) {

            } else {
                setUsetextlist([...data?.data?.resuGetTable_sizeforinputlts])
            }

            if (data?.data?.GetImage_positions === null) {

            } else {
                setSelectedImage_show([...data?.data?.GetImage_positions])
            }
            if (data?.data?.GetTable_checkbox === null) {
            } else {
                setUsecheckbox([...data?.data?.GetTable_checkbox])
            }
            if (data?.data?.GetTable_label !== null) {
                setUselable([...data?.data?.GetTable_label])
            }
            if (data?.data?.GetTable_position_one !== null) {
                setUsetable([...data?.data?.GetTable_position_one])
                setCountRow([...data?.data?.GetTable_position_one][0].countrow)
                setTablechidren(JSON.parse([...data?.data?.GetTable_position_one][0].body_table))
            }
            if (data?.data?.GetTable_positions_two !== null) {
                setUsetable1([...data?.data?.GetTable_positions_two])
                setCountRow1([...data?.data?.GetTable_positions_two][0].countrow)
                setTablechidren1(JSON.parse([...data?.data?.GetTable_positions_two][0].body_table))

            }
           


        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadListData()
    }, [])

    return (
        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between' }}>
            <div style={{ width: 300, backgroundColor: '#dbeafe', display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold' }}>Control Panel </h6>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{
                        cursor: 'pointer',
                        width: 80,
                        height: 80,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        marginLeft: 10,
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                        boxShadow: active === 'table' ? '1px 2px 9px #F4AAB9' : ''
                    }}
                        onMouseOver={() => setActive('table')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { handlesaveShow() }}
                    >
                        <SaveAltIcon style={{ fontWeight: 'bold' }} />
                        <small style={{ fontWeight: 'bold' }}>Save</small>
                    </div>
                    <div style={{
                        cursor: 'pointer',
                        width: 80,
                        height: 80,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                        boxShadow: active === 'input' ? '1px 2px 9px #F4AAB9' : ''
                    }}
                        onMouseOver={() => setActive('input')}
                        onMouseLeave={() => setActive(null)}
                        onClick={handlePrintToPDF}
                    >
                        <PrintIcon style={{ fontWeight: 'bold' }} />
                        <small style={{ fontWeight: 'bold' }}>Print</small>
                    </div>
                    <div style={{
                        cursor: 'pointer',
                        width: 80,
                        height: 80,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        marginTop: 5,
                        marginRight: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10,
                        boxShadow: active === 'Text' ? '1px 2px 9px #F4AAB9' : ''
                    }}
                        onMouseOver={() => setActive('Text')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => { OncloseUsingformScreen() }}
                    >
                        <CancelIcon style={{ fontWeight: 'bold' }} />
                        <small style={{ fontWeight: 'bold' }}>Close</small>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                        style={{ border: '1px solid #fff', marginLeft: 10, marginRight: 10, borderRadius: 5 }}
                    >
                    </div>
                </div>

            </div>

            <div ref={componentRef} style={{
                width: 794.2512,
                height: '297mm',
                backgroundColor: 'white',
                padding: 10,
                border: '1px solid gray',

            }}>
                {
                    saveforusing === true ? (
                        <>
                            <MadalSaveusingForm
                                saveAs={saveAs}
                                setSaveAs={setSaveAs}
                                saveforusing={saveforusing}
                                handleChange={handleChange}
                                handleCloseSavle={handleCloseSavle}
                                OnCreate={OnCreate}
                            />

                        </>) : null
                }



                {
                    showSave === true ? (<>
                        <ModalSave
                            showSave={showSave}
                            handleClose={handleClose}
                            isLoading={isLoading}
                        />

                    </>) : null
                }
                {
                    datatable.map((el, index) => {
                        if (el.type === 'table') {
                            return (
                                <Rnd
                                    key={index}
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,
                                    }}
                                    onDragStop={(e, d) => onDragStoptable(e, d, index, 'create', 'one')}
                                    onClick={() => { Onclicktableindex(index, 'create', 'one') }}
                                >
                                    <table style={{ border: '1px solid gray' }}>
                                        {
                                            tables && tables.map((item, rowIndex) => {
                                                return (
                                                    <>
                                                        <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                                                            {
                                                                item.map((data, colIndex) => {
                                                                    const cellProps = {
                                                                        key: colIndex,
                                                                        style: {
                                                                            width: `${data.width}px`,
                                                                            border: '1px solid gray',
                                                                            backgroundColor: selectedCells_first.some(
                                                                                (selectedCell) => selectedCell.row === rowIndex && selectedCell.col === colIndex
                                                                            )
                                                                                ? 'yellow'
                                                                                : 'white',
                                                                        },
                                                                        colSpan: data.cols,
                                                                        rowSpan: data.rows,
                                                                    };
                                                                    if (data.merged) {

                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <td {...cellProps}>
                                                                                <textarea value={data?.name}
                                                                                    onChange={(e) => { changeText_values_table(e.target.value, 'name', rowIndex, colIndex, 'create', 'one') }}
                                                                                    onClick={() => {
                                                                                        OnclickTable(colIndex, rowIndex, 'one');
                                                                                        SelectCell(rowIndex, colIndex, 'one')
                                                                                    }}
                                                                                    style={{ border: 'none', width: '100%', height: `${data?.height}px`, marginTop: 5 }} />
                                                                            </td>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </table>
                                </Rnd>
                            )
                        }
                    })
                }
                {
                    datatable1.map((el, index) => {
                        if (el.type === 'table') {
                            return (
                                <Rnd
                                    key={index}
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,

                                    }}
                                    onDragStop={(e, d) => onDragStoptable(e, d, index, 'create', 'two')}
                                    onClick={() => { Onclicktableindex(index, 'create', 'two') }}
                                >
                                    <table style={{ border: '1px solid gray' }}>
                                        {
                                            tables1 && tables1.map((item, rowIndex) => {
                                                return (
                                                    <>
                                                        <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                                                            {
                                                                item.map((data, colIndex) => {
                                                                    const cellProps = {
                                                                        key: colIndex,
                                                                        style: {
                                                                            width: `${data.width}px`,
                                                                            border: '1px solid gray',
                                                                            backgroundColor: selectedCells_second.some(
                                                                                (selectedCell) => selectedCell.row === rowIndex && selectedCell.col === colIndex
                                                                            )
                                                                                ? 'yellow'
                                                                                : 'white',
                                                                        },
                                                                        colSpan: data.cols,
                                                                        rowSpan: data.rows,
                                                                    };
                                                                    if (data.merged) {

                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <td {...cellProps}>
                                                                                <textarea value={data?.name}
                                                                                    onChange={(e) => { changeText_values_table(e.target.value, 'name', rowIndex, colIndex, 'create', 'two') }}
                                                                                    onClick={() => {
                                                                                        OnclickTable(colIndex, rowIndex, 'two');
                                                                                        SelectCell(rowIndex, colIndex, 'two')
                                                                                    }}

                                                                                    style={{ border: 'none', width: '100%', height: `${data?.height}px`, marginTop: 5 }} />
                                                                            </td>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </table>
                                </Rnd>
                            )
                        }
                    })
                }
                {
                    usetable && usetable.map((el, index) => {
                        return (
                            <div key={index}>
                                <Rnd
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,

                                    }}
                                    onDragStop={(e, d) => onDragStoptable(e, d, index, 'edit', 'one')}
                                    onClick={() => { Onclicktableindex(index, 'edit', 'one') }}
                                >
                                    <table style={{ border: '1px solid gray' }}>
                                        {
                                            tablechidren && tablechidren.map((item, rowIndex) => {
                                                return (
                                                    <>
                                                        <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                                                            {
                                                                item.map((data, colIndex) => {
                                                                    const cellProps = {
                                                                        key: colIndex,
                                                                        style: {
                                                                            width: `${data.width}px`,
                                                                            border: '1px solid gray',
                                                                            // backgroundColor: selectedCells_first.some(
                                                                            //     (selectedCell) => selectedCell.row === rowIndex && selectedCell.col === colIndex
                                                                            // )
                                                                            //     ? 'yellow'
                                                                            //     : 'white',
                                                                        },
                                                                        colSpan: data.cols,
                                                                        rowSpan: data.rows,
                                                                    };
                                                                    if (data.merged) {

                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <td {...cellProps}>
                                                                                <textarea value={data?.name}
                                                                                    onChange={(e) => { changeText_values_table(e.target.value, 'name', rowIndex, colIndex, 'update', 'one') }}
                                                                                    onClick={() => {
                                                                                        // OnclickTable(colIndex, rowIndex, 'one');
                                                                                        // SelectCell(rowIndex, colIndex, 'one')
                                                                                    }}
                                                                                    style={{ border: 'none', width: '100%', height: `${data?.height}px`, marginTop: 5 }} />
                                                                            </td>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }

                                    </table>
                                </Rnd>
                            </div>
                        )
                    })
                }

                {
                    usetable1 && usetable1.map((el, index) => {
                        return (
                            <div key={index}>
                                <Rnd
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,

                                    }}
                                    onDragStop={(e, d) => onDragStoptable(e, d, index, 'edit', 'two')}
                                    onClick={() => { Onclicktableindex(index, 'edit', 'two') }}
                                >
                                    <table style={{ border: '1px solid gray' }}>
                                        {
                                            tablechidren1 && tablechidren1.map((item, rowIndex) => {
                                                return (
                                                    <>
                                                        <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                                                            {
                                                                item.map((data, colIndex) => {
                                                                    const cellProps = {
                                                                        key: colIndex,
                                                                        style: {
                                                                            width: `${data.width}px`,
                                                                            border: '1px solid gray',
                                                                            // backgroundColor: selectedCells_first.some(
                                                                            //     (selectedCell) => selectedCell.row === rowIndex && selectedCell.col === colIndex
                                                                            // )
                                                                            //     ? 'yellow'
                                                                            //     : 'white',
                                                                        },
                                                                        colSpan: data.cols,
                                                                        rowSpan: data.rows,
                                                                    };
                                                                    if (data.merged) {

                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <td {...cellProps}>
                                                                                <textarea value={data?.name}
                                                                                    onChange={(e) => { changeText_values_table(e.target.value, 'name', rowIndex, colIndex, 'update', 'two') }}
                                                                                    onClick={() => {
                                                                                        // OnclickTable(colIndex, rowIndex, 'one');
                                                                                        // SelectCell(rowIndex, colIndex, 'one')
                                                                                    }}
                                                                                    style={{ border: 'none', width: '100%', height: `${data?.height}px`, marginTop: 5 }} />
                                                                            </td>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }

                                    </table>
                                </Rnd>
                            </div>
                        )
                    })
                }

                {
                    usetextlist && usetextlist.map((e, index) => {
                        return (
                            < Rnd
                                default={{
                                    x: e.positionX,
                                    y: e.positionY,
                                }}
                                onDragStop={(e, d) => onDragStopinputupdate(e, d, index)}
                            >
                                <ComponentInput_update
                                    index={index}
                                    changeText={changeText}
                                    e={e}
                                    OnclickTextUpdate={OnclickTextUpdate}
                                />
                            </Rnd>
                        )
                    })
                }
                {
                    listtext && listtext.map((e, index) => {
                        return (
                            < Rnd
                                default={{
                                    x: e.positionX,
                                    y: e.positionY,
                                }}
                                onDragStop={(e, d) => onDragStopinput(e, d, index)}
                            >
                                <ComponentInput
                                    index={index}
                                    changeText={changeText}
                                    e={e}
                                    Onclicktextlist={Onclicktextlist}

                                />
                            </Rnd>
                        )
                    })
                }
                {
                    selectedImage && selectedImage.map((e, index) => {
                        return (
                            <Rnd
                                default={{
                                    x: e.positionX,
                                    y: e.positionY,
                                }}
                                onDragStop={(e, d) => { onDragImagelogo(e, d, index, 'create') }}
                                onClick={() => { OnClickCheckimage(index, 'create') }}
                            >
                                {pictureUrl && (
                                    <img src={pictureUrl} alt="Selected Picture" style={{ width: `${e?.width}px`, height: `${e?.height}px` }} />
                                )}
                            </Rnd>
                        )
                    })
                }
                {
                    selectedImage_show && selectedImage_show.map((e, index) => {
                        return (
                            <Rnd
                                key={index}
                                default={{
                                    x: e.positionX,
                                    y: e.positionY,
                                }}
                                onDragStop={(e, d) => { onDragImagelogo(e, d, index, 'edit') }}
                                onClick={() => { OnClickCheckimage(index, 'edit') }}
                            >
                                <img src={e.images[0]} style={{ width: `${e?.width}px`, height: `${e?.height}px` }} />
                            </Rnd>
                        )
                    })
                }
                {
                    dataText && dataText.map((el, index) => {
                        return (
                            <>
                                <Rnd
                                    key={index}
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,
                                    }}
                                    onDragStop={(e, d) => onDragStopDataText(e, d, index, 'create')}
                                    onClick={() => { onClickDataText(index, 'create') }}
                                >
                                    <small style={{ fontSize: el.font, fontWeight: el.fontWeight }}>{el.name}</small>
                                </Rnd>
                            </>
                        )

                    })
                }
                {
                    uselable && uselable.map((el, index) => {
                        return (
                            <Rnd
                                key={index}
                                default={{
                                    x: el.positionX,
                                    y: el.positionY,

                                }}
                                onDragStop={(e, d) => onDragStopDataText(e, d, index, 'edit')}
                                onClick={() => { onClickDataText(index, 'edit') }}
                                style={{ display: 'flex', justifyContent: 'flex-start' }}
                            >
                                <small style={{ fontSize: el.font, fontWeight: el.fontWeight }}>{el.name}</small>
                            </Rnd>
                        )

                    })
                }
                {
                    datacheckbox && datacheckbox.map((el, index) => {
                        return (
                            <Rnd
                                key={index}
                                default={{
                                    x: el.positionX,
                                    y: el.positionY,

                                }}
                                onDragStop={(e, d) => onDragStopcheckbox(e, d, index, 'create')}
                                onClick={() => OnClickCheckbox(index, 'create')}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="checkbox" style={{ cursor: 'pointer' }} />
                                    <small style={{ marginLeft: 5 }}>{el.name}</small>

                                </div>

                            </Rnd>
                        )
                    })
                }
                {
                    usecheckbox && usecheckbox.map((el, index) => {
                        return (
                            <Rnd
                                key={index}
                                default={{
                                    x: el.positionX,
                                    y: el.positionY,
                                }}
                                onDragStop={(e, d) => onDragStopcheckbox(e, d, index, 'edit')}
                                onClick={() => OnClickCheckbox(index, 'edit')}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="checkbox" style={{ cursor: 'pointer' }} />
                                    <small style={{ marginLeft: 5 }}>{el.name}</small>

                                </div>
                            </Rnd>
                        )
                    })
                }

            </div>
            <div style={{ backgroundColor: '#dbeafe', width: 300}}>
                {
                    menucondigions === 'input' ? (<>
                        {
                            listtextw && listtextw.map((e, index) => {
                                return (
                                    <div key={index} style={{ width: '100%' }}>
                                        <Input
                                            changeTextForsize={changeTextForsize}
                                            deletechange={deletechange}
                                            e={e}
                                            index={e.index}
                                        />
                                    </div>
                                )
                            })
                        }
                    </>) : menucondigions === 'table' ? (
                        <div style={{ width: '100%' }}>
                            {
                                conditions_table === 'create' ? (<>
                                    <TableMenu
                                        datatable={datatable}
                                        datatable1={datatable1}
                                        inputValues={inputValues}
                                        inputValues1={inputValues1}
                                        changeText2={changeText2}
                                        changeText3={changeText3}
                                        changeTextheight={changeTextheight}
                                        changeTextwidth={changeTextwidth}
                                        MergeCellcolumn={MergeCellcolumn}
                                        MergeCellRow={MergeCellRow}
                                        MergeCellRowAll={MergeCellRowAll}
                                        deletetable={deletetable}
                                        showdeltable={showdeltable}
                                        showdeltable1={showdeltable1}
                                        get_index={get_index}
                                        widthsize={widthsize}
                                        heightsize={heightsize}
                                        index={index}
                                        index1={index1}

                                    />
                                </>) : (<>
                                    <TableMenu
                                        datatable={usetable}
                                        datatable1={usetable1}
                                        inputValues={usetable2}
                                        inputValues1={inputValues1}
                                        changeText2={changeText2_edit}
                                        changeText3={changeText3_edit}
                                        changeTextheight={changeTextheight_edit}
                                        changeTextwidth={changeTextwidth_edit}
                                        MergeCellcolumn={MergeCellcolumn}
                                        MergeCellRow={MergeCellRow}
                                        MergeCellRowAll={MergeCellRowAll}
                                        deletetable={deletetable_edit}
                                        showdeltable={showdeltable}
                                        showdeltable1={showdeltable1}
                                        get_index={get_index}
                                        widthsize={widthsize}
                                        heightsize={heightsize}
                                        index={index}
                                        index1={index1}

                                    />
                                </>)
                            }


                        </div>
                    ) : menucondigions === 'Text' ? (

                        <div style={{ width: '100%' }}>
                            {
                                conditions_text === 'edit' ? (
                                    <>
                                        <TextMenuEdit
                                            uselable={uselable}
                                            editlable={editlable}
                                            changeLable={changeLable_edit}
                                            get_index={get_index}
                                            deleteDataText={deleteUseText}
                                        />

                                    </>) : (<>
                                        <TextMenu
                                            dataText={dataText}
                                            editlable={editlable}
                                            changeLable={changeLable}
                                            get_index={get_index}
                                            deleteDataText={deleteDataText}
                                        />
                                    </>)
                            }

                        </div>
                    ) : menucondigions === 'CheckBox' ? (
                        <>
                            {
                                conditions_checkbox === 'create' ? (<>
                                    <CheckBoxMenu
                                        datacheckbox={datacheckbox}
                                        editcheckboxvalues={editcheckboxvalues}
                                        changeCheckbox={changeCheckbox}
                                        get_index={get_index}
                                        deletecheckbox={deletecheckbox}
                                    />
                                </>) : (<>
                                    <CheckBoxMenu
                                        datacheckbox={usecheckbox}
                                        editcheckboxvalues={editcheckboxvalues}
                                        changeCheckbox={changeCheckbox_edit}
                                        get_index={get_index}
                                        deletecheckbox={deletecheckbox_edit}
                                    />
                                </>)
                            }


                        </>) : menucondigions === 'Image' ? (
                            <>
                                {
                                    conditions_image === 'create' ? (<>
                                        <ImageMenu
                                            get_index={get_index}
                                            selectedImage={selectedImage}
                                            ing_width={ing_width}
                                            ing_heigh={ing_heigh}
                                            changeTextImage_width={changeTextImage_width}
                                            changeTextImage_height={changeTextImage_height}
                                        />
                                    </>) : (
                                        <>
                                            <ImageMenu
                                                get_index={get_index}
                                                selectedImage={selectedImage_show}
                                                ing_width={ing_width}
                                                ing_heigh={ing_heigh}
                                                changeTextImage_width={changeTextImage_width_edit}
                                                changeTextImage_height={changeTextImage_height_edit}
                                            />

                                        </>)
                                }
                            </>) : null
                }
            </div>
        </div>
    )
}
function ComponentInput({ Onclicktextlist, changeText, e, index }) {
    return (
        <>
            <input
                style={{
                    border: '1px solid #5EBFE3',
                    width: `${e.width}px`,
                    height: `${e.height}px`,
                    border: 'none',
                }}
                placeholder="................................................................................................................................................................................................................................................................................................"
                value={e?.name}
                onChange={(el) => changeText(el.target.value, 'create', "name", index)}
                onClick={() => { Onclicktextlist(index) }}

            />
        </>

    )
}
function ComponentInput_update({ OnclickTextUpdate, changeText, e, index }) {
    return (
        <>
            <input
                style={{
                    border: '1px solid #5EBFE3',
                    width: `${e.width}px`,
                    height: `${e.height}px`,
                    border: 'none',
                }}
                placeholder="................................................................................................................................................................................................................................................................................................"
                value={e?.name}
                onChange={(el) => changeText(el.target.value, 'edit', "name", index)}
                onClick={() => { OnclickTextUpdate(index) }}

            />
        </>

    )
}

