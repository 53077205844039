import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import GestureIcon from '@material-ui/icons/Gesture';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from "@material-ui/core/ListItemText";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DvrIcon from '@material-ui/icons/Dvr';
import Collapse from "@material-ui/core/Collapse";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../page/contexts/LoginContext";
import TemplateBorwserview from "./TemplateBorwserview"
import TemplateMobile from "./TemplateMobile"
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import axios from "axios";
import Cookies from 'js-cookie';
import SettingsIcon from "@material-ui/icons/Settings";
import { CreateFormTest } from "../components/CreateFormTest"
import { CreateForm } from "../components/CreateForm";
import Badge from '@material-ui/core/Badge';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import { EditFormTest } from "../components/EditFormTest";

import { ViewFormTest } from "../components/ViewFormTest";

import { UserusingFormTest } from "../components/UserusingFormTest";
import { UserEditformTest } from "../components/UserEditformTest";
import { MySinature } from "../componentsmobile/MySinature";
import { UserCheckDoc_noTest } from "../components/UserCheckDoc_noTest";
import { UserCheckDoc_noOutTest } from "../components/UserCheckDoc_noOutTest";
import { EdiForm } from "../components/EdiForm";
import { BrowserView, MobileView } from 'react-device-detect';
import Notifications from "../components/Notifications";
import { useMediaQuery } from 'react-responsive';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function MobileViewHome(props) {
  const Navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [listform, setListform] = useState([]);
  const [openingsetting, setOpeningsetting] = useState(false)
  const [openingApproved, setOpeningApproved] = useState(false)
  const [opneingrequest, setOpneingrequest] = useState(false)
  const [openingDoc_no, setOpeningDoc_no] = useState(false)
  const [active, setActive] = useState("");
  const [open1, setOpen1] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false)
  const anchorRef = useRef(null);
  const {
    setDataList,
    open,
    setOpen,
    listOpent,
    setListOpent,
    listopenForm,
    setListopenForm,
    id,
    showforcreateform,
    showformscreen,
    setShowformscreen,
    showformEditScreen,
    showViewFormScreen,
    showUserusingFormScreen,
    showUserEditFormScreen,
    showUserCheckFormScreen,
    showUserCheckFormScreenOut,
    document_status,
    notifications
  } = useContext(LoginContext)
  let users = Cookies.get("user");
  let data = JSON.parse(users)
  const handleCloseOpenting = () => {
    setOpeningsetting(false)
  }
  const handlOpenNotifications = () => setOpenNotifications(true)
  const handleCloseOpentNoti = () => {
    setOpenNotifications(false)
  }
  const opening = () => {
    setOpeningsetting(!openingsetting)
  }
  // let user_id = data?.user?.user_id
  let user_name = data?.name
  const handleToggle = () => {
    setOpen1((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen1(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen1(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open1);
  React.useEffect(() => {
    if (prevOpen.current === true && open1 === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open1;
  }, [open]);
  const Showfullscreen = () => {
    setShowformscreen(true)
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onloadDepartment = () => {
    axios.get('/api/document-management/form/get-departments').then((data) => {
      setListform([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setListOpent(!listOpent);
  };
  const handleClickform = () => {
    setListopenForm(!listopenForm)
  }
  const handleClickMyApprove = () => {
    setOpeningApproved(!openingApproved)
  }
  const handleClickMyrequest = () => {
    setOpneingrequest(!opneingrequest)
  }
  const handlClickDoc_no = () => {
    setOpeningDoc_no(!openingDoc_no)
  }

  const OnloadListViewrequest = () => {
    axios.get('/api/document-management/setting/view-form-request/').then((data) => {
      // setCount([...data?.data?.count][0].count)
      // setRole_id(data?.data?.datastatus)
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnLogout = () => {
    Cookies.remove('user');
    window.location.href = '/Login';
  }
  const Ondepartment = (id) => {
    let data = {
      department_id: id
    }
    axios.post('/api/document-management/form/get-formBy-departments', data).then((data) => {
      setDataList([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    OnloadListViewrequest()
    onloadDepartment();
  }, [])
  useEffect(() => {
    if (notifications !== 0) {
      const timeoutId = setTimeout(() => {
        setOpenNotifications(true)
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [notifications]);

  return (
    <div>

      {showformscreen === true ? (
        <>
          <CreateFormTest />
          {/* < CreateForm /> */}
        </>) : (showformEditScreen === true) ? (
          <>
            {/* <EdiForm
              id={id}
            /> */}
            <EditFormTest
              id={id} />
          </>) : (showViewFormScreen === true) ? (
            <>
              {/* <ViewForm
                id={id}
              /> */}
              <ViewFormTest
                id={id}
              />
            </>) : (showUserusingFormScreen === true) ? (
              <>
                {/* <UserusingForm
                  id={id}

                /> */}
                <UserusingFormTest
                  id={id}
                />

              </>) : (showUserEditFormScreen === true) ? (<>
                {/* <UserEditform
                  id={id}
                /> */}
                <UserEditformTest
                  id={id}
                  document_status={document_status}
                />

              </>) : (showUserCheckFormScreen === true) ? (
                <>
                  {/* <UserCheckDoc_no
                    id={id}
                  /> */}
                  <UserCheckDoc_noTest
                    id={id}

                  />
                </>
              ) : (showUserCheckFormScreenOut === true) ? (
                <>
                  {/* <UserCkeckDoc_noOut
                    id={id}
                  /> */}
                  <UserCheckDoc_noOutTest
                    id={id}
                  />


                </>) : (showforcreateform === true) ? (
                  <>
                    <EditFormTest />
                  </>) : (
        <>

          <MySinature
            openingsetting={openingsetting}
            handleCloseOpenting={handleCloseOpenting}
            setOpeningsetting={setOpeningsetting}
          />
          <Notifications
            openNotifications={openNotifications}
            handleCloseOpentNoti={handleCloseOpentNoti}
            Navigate={Navigate}
          />
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <Typography variant="h6" noWrap style={{ marginLeft: 0 }}>
                    Phongsavanh Group
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                  >
                    <Badge badgeContent={notifications} color="secondary"
                      style={{ marginRight: 10, marginTop: 5, cursor: 'pointer' }}
                      onClick={() => { handlOpenNotifications() }}
                    >
                      <NotificationsNoneIcon />
                    </Badge>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
              <Divider />

              <List style={{ backgroundColor: '#0f172a' }}
              >
                <ListItem button onClick={() => {Navigate("/Index");handleDrawerClose()}}
                  style={{ backgroundColor: active === 'Dashboard' ? 'gray' : '' }}
                  onMouseOver={() => setActive('Dashboard')}
                  onMouseLeave={() => setActive(null)}
                >
                  <ListItemIcon>
                    <AppsIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <span style={{ fontSize: 18, fontWeight: 'bold', color: 'white' }}>ໜ້າຫຼັກ</span>
                </ListItem>
              </List>
              <Divider />
              <List style={{ backgroundColor: '#0f172a' }}
              >
                <ListItem button onClick={handleClickMyApprove}
                  style={{ backgroundColor: active === 'Myapproved' ? 'gray' : '' }}
                  onMouseOver={() => setActive('Myapproved')}
                  onMouseLeave={() => setActive(null)}
                >
                  <ListItemIcon>
                    <RecentActorsIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <small style={{ fontSize: 18, color: 'white' }}>ອະນຸມັດ</small>
                  <ListItemText />
                </ListItem>
                <Collapse in={openingApproved} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button className={classes.nested}
                      onClick={() => { Navigate("/RequestForm");handleDrawerClose() }}
                      style={{ backgroundColor: active === 'ApprovedForm' ? 'gray' : '' }}
                      onMouseOver={() => setActive('ApprovedForm')}
                      onMouseLeave={() => setActive(null)}
                    >
                      <ListItemIcon >
                        <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                      </ListItemIcon>
                      <span style={{ fontSize: 15, marginLeft: 5, color: 'white' }}>ສ້າງແບບຟອມ</span>
                    </ListItem>
                    <ListItem button className={classes.nested}
                     onClick={() => {Navigate("/ApprovedRequestIn");handleDrawerClose()}}
                      style={{ backgroundColor: active === 'ApprovedRequestIn' ? 'gray' : '' }}
                      onMouseOver={() => setActive('ApprovedRequestIn')}
                      onMouseLeave={() => setActive(null)}
                    >
                      <ListItemIcon>
                        <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                      </ListItemIcon>
                      <small style={{ fontSize: 15, marginLeft: 5, color: 'white' }} >ເອກະສານຂາເຂົ້າ</small>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => {Navigate("/ApprovedRequestOut");handleDrawerClose()}}
                      style={{ backgroundColor: active === 'ApprovedRequestOut' ? 'gray' : '' }}
                      onMouseOver={() => setActive('ApprovedRequestOut')}
                      onMouseLeave={() => setActive(null)}
                    >
                      <ListItemIcon>
                        <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                      </ListItemIcon>
                      <small style={{ fontSize: 15, marginLeft: 5, color: 'white' }} >ເອກະສານຂາອອກ</small>
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => {Navigate("/ApprovedRequest");handleDrawerClose()}}
                      style={{ backgroundColor: active === 'ApprovedRequest' ? 'gray' : '' }}
                      onMouseOver={() => setActive('ApprovedRequest')}
                      onMouseLeave={() => setActive(null)}
                    >
                      <ListItemIcon>
                        <FormatListNumberedIcon style={{ marginLeft: 40, color: 'white' }} />
                      </ListItemIcon>
                      <small style={{ fontSize: 15, marginLeft: 5, color: 'white' }} >ເອກະສານໃຜໃນ</small>
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem button 
                  style={{ backgroundColor: active === 'fo' ? 'gray' : '' }}
                  onMouseOver={() => setActive('fo')}
                  onMouseLeave={() => setActive(null)}
                  onClick={() => { opening();handleDrawerClose() }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <small style={{ fontSize: 18, color: 'white' }}>ລາຍເຊັນ</small>
                  </div>
                  <ListItemText />
                </ListItem>
                <ListItem button 
                  style={{ backgroundColor: active === 'logout' ? 'gray' : '' }}
                  onMouseOver={() => setActive('logout')}
                  onMouseLeave={() => setActive(null)}
                  onClick={() => { OnLogout() }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon style={{ color: 'white' }} />
                  </ListItemIcon>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                   
                    <small style={{ fontSize: 18, color: 'white' }}>ອອກຈາກລະບົບ</small>
                  </div>
                  <ListItemText />
                </ListItem>
             
                <ListItem style={{ height: 600 }}>
                  <ListItemText />
                </ListItem>
              </List>
            </Drawer>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {props.children}
            </main>
          </div>


        </>
      )
      }

    </div>

  );
}
