import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import Cookies from 'js-cookie';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { DataGrid } from '@material-ui/data-grid';
import { LoginContext } from "../page/contexts/LoginContext";
import AssignmentIcon from '@material-ui/icons/Assignment';
import LoadingOverlay from 'react-loading-overlay';
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export const Doc_noOut = () => {
  const classes = useStyles();
  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState(true);
  const [dataListFilter, setDataListFilter] = useState([])
  const [filterstatus, setFilterstatus] = useState('')
  const [conditionsfilter, setConditionsfilter] = useState('')
  const [getvalues, setGetvalues] = useState('')
  let users = Cookies.get("user");
  let data = JSON.parse(users)
  let user_id = data?.user?.user_id
  const { setId, setShowUserCheckFormScreenOut } = useContext(LoginContext)

  const OnloadFormData = () => {
    axios.get(`/api/document-management/outgoing/number/Get-All-Request-DocNumber`).then((data) => {
    
      if(data?.data?.results === null)
      {
        setLoading(false)
      }else{
        setDataList([...data?.data?.results])
        setLoading(false)
      }
     
    }).catch((err) => {
      console.log(err)
    })

  }
  const OnloadFormDataForFilter = () => {
    axios.get('/api/document-management/outgoing/number/Get-All-Request-DocNumber').then((data) => {
      setDataListFilter([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const filterFormData = (e) => {
    setFilterstatus(e)
    setConditionsfilter(1)
    if (e === 'reset') {
      OnloadFormData()
    } else {
      let filterlist = dataListFilter.filter((el) => el.createdstatus == e)
      setDataList([...filterlist])
    }
  }
  const filterForSearch = (e) => {
    setGetvalues(e)
    if (conditionsfilter === 1) {
      let searchname = dataListFilter.filter((el) => el.title.toLowerCase().includes(e.toLowerCase()));
      if (!e) {
        OnloadFormData()
      } else {
        setDataList([...searchname])
      }

    } else {
      let searchname = dataList.filter((el) => el.title.toLowerCase().includes(e.toLowerCase()));
      if (!e) {
        OnloadFormData()
      } else {
        setDataList([...searchname])
      }
    }
  }
  const onEditform = (id) => {
    setId(id)
    setShowUserCheckFormScreenOut(true)
  }

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ເອກະສານ</small>
      ),
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <DescriptionIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
          <small style={{ cursor: 'pointer' }}>
            {params.row.title}
          </small>
        </div>
      )
    },
    {
      field: 'created_at',
      headerName: 'Last Modified',
      width: 100,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່</small>
      ),
      renderCell: (params) => (
        <>
          {
            moment(params.row.created_at).format('DD-MM-YYYY')
          }
        </>
      )
    },
    {
      field: 'createdby_name',
      headerName: 'Create by ',
      width: 170,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຄົນຮ້ອງຂໍ</small>
      ),
    },
    {
      field: 'employee_department_name',
      headerName: 'Create by ',
      width: 300,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
      ),
    },
    {
      field: 'req_status',
      headerName: 'Status',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນະ</small>
      ),
      renderCell: (params) => (
        <>
          {
            params.row.createdstatus === 1 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ScreenRotationIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#c62828' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#c62828' }}>Requested....</small>
              </div>
            )}
          {
            params.row.createdstatus === 2 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>

                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Completed....</small>
              </div>
            )}
          {
            params.row.createdstatus === 8 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ReportProblemIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff5724' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff5724' }}>Draft....</small>
              </div>
            )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5EBFE3', fontWeight: 'bold', fontSize: 20 }}>#####</small>
      ),
      renderCell: (params) => (
        <div style={{ display: 'flex', flexDirection: 'row' ,marginRight:10}}>
          <ComponentView
            req_id={params.row.req_id}
            onEditform={onEditform}
          />
        </div>

      )
    },
  ];
  const Avatar = ({ src, alt }) => (
    <img style={{
      width: 40,
      height: 40,
      borderRadius: '50%',
      marginRight: -40,
      border: '1px solid red',
      borderBlockColor:'green'
    }} src={src} alt={alt} />
  );
  const avatars = [
    { src: 'avatar1.jpg', alt: 'Avatar 1' },
    { src: 'avatar2.jpg', alt: 'Avatar 2' },
    { src: 'avatar3.jpg', alt: 'Avatar 3' },
    // Add more avatars as needed
  ];
  const AvatarGroup = ({ avatars }) => (
    <div style={{ display: 'flex' }}>
      {avatars.map((avatar, index) => (
        <Avatar key={index} src={avatar.src} alt={avatar.alt} />
  
      ))}
    </div>
  );
  useEffect(() => {
    OnloadFormData()
    OnloadFormDataForFilter()
  }, [])
  return (
    <LoadingOverlay
    active={loading}
    spinner
    text="Loading..."
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ໃສ່ເລກທີ່</h5>
          <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
          <h5 style={{ color: "#007bff" }}>ເອກະສານຂາອອກ</h5>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: '50%' }}>
            <input
              type="text"
              placeholder="Search field"
              style={{
                width: '50%',
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5
              }}
              value={getvalues}
              onChange={(e) => filterForSearch(e.target.value)}
            />
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
            <select
              style={{
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5,

              }}
              onChange={(e) => filterFormData(e.target.value)}
              value={filterstatus}
            >
              <option value='reset'>=ໃຫ້ເລື່ອກສະຖານະ=</option>
              <option value='1'>Requested</option>
              <option value='2'>Completed</option>
              <option value='6'>Draft</option>
            </select>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              rows={dataList}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
            // loading={loading} // Pass the loading state to the DataGrid
            />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}
function ComponentView({ onEditform, req_id }) {
  const [active, setActive] = useState("");
  return (
    <>
      <div style={{
        border: '1px solid #5EBFE3',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: 25, cursor: 'pointer',
        boxShadow: active === 's' ? '1px 2px 9px #F4AAB9' : '',
        backgroundColor: active === 's' ? '#e9eef5' : ''
      }}
        onMouseOver={() => setActive('s')}
        onMouseLeave={() => setActive(null)}
        onClick={() => { onEditform(req_id) }}
      >
        <AssignmentIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#007bff' }} />
        <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, marginRight: 3, color: '#007bff' }}>View</small>
      </div>
    </>

  )
}

