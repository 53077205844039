import React, { useState } from "react";
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
export const Input = (props) => {
    const [active, setActive] = useState('')
    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Frame</h6>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>X</small>
                        <input

                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            value={props.e.positionX}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>Y</small>
                        <input
                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            value={props.e.positionY}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>W</small>
                        <input
                            value={props.e.width}
                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}

                            onChange={(el) => { props.changeTextForsize(el.target.value, "width", props.index) }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <small style={{
                            marginLeft: 10,
                            backgroundColor: 'white',
                            width: 50,
                            border: '1px solid #5EBFE3',
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>H</small>
                        <input
                            style={{
                                width: '100%',
                                marginRight: 10,
                                border: '1px solid #5EBFE3',
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderLeft: 'none',
                            }}
                            value={props.e.height}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Family</h6>
                <select
                    style={{ border: '1px solid #5EBFE3', marginLeft: 10, marginRight: 10, borderRadius: 5 }}
                >
                    <option>BebasNeue-Regular</option>
                    <option>NotoSans-Bold</option>
                </select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Color</h6>
                <input
                    placeholder='#000000'
                    style={{
                        border: '1px solid #5EBFE3',
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 5
                    }}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Style</h6>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'b' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('b')}
                            onMouseLeave={() => setActive(null)}
                        >
                            <FormatBoldIcon />
                        </div>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'I' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('I')}
                            onMouseLeave={() => setActive(null)}
                        >
                            <FormatItalicIcon />
                        </div>
                        <div style={{
                            width: 50,
                            height: 50,
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 10,
                            cursor: 'pointer',
                            boxShadow: active === 'C' ? '1px 2px 9px #F4AAB9' : ''
                        }}
                            onMouseOver={() => setActive('C')}
                            onMouseLeave={() => setActive(null)}
                        >
                            <FormatLineSpacingIcon />
                        </div>
                    </div>

                </div>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6 style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>Font Size</h6>
                <input
                    placeholder='000000 PX'
                    style={{
                        border: '1px solid #5EBFE3',
                        marginLeft: 10,
                        marginRight: 10,
                        borderRadius: 5
                    }}
                />
            </div>
        </div>
    )
}
