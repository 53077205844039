import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import moment from "moment";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});
export const Chats = (props) => {
    const classes = useStyles();
    const [isInputClicked, setIsInputClicked] = useState(false);
    const [active, setActive] = useState('')
    const [isLoading, setIsLoading,] = useState(false);
    const [dataChat, setDataChat] = useState([])
    const [datasend, setDatasend] = useState('')
    const handleInputClick = () => {
        setIsInputClicked(true);
    };
    const handleInputBlur = () => {
        setIsInputClicked(false);
    };
    const OnloadChat = () => {
        axios.get(`/api/document-managementform-reply/all-chat/${props.id}`).then((data) => {
            console.log("data=",data)

            if (data?.data?.results !== null) {
                setDataChat([...data?.data?.results])
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const OnChat = () => {
        setIsLoading(true)
        let dataIn = {
            form_id: props.id,
            comment: datasend,
        }
        axios.post('/api/document-managementform-reply/chat-reply', dataIn).then((data) => {
            OnloadChat();
            setDatasend('')
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        OnloadChat()
    }, [])
    return (
        <Modal show={props.show} onHide={props.handleClosedel} style={{ paddingTop: 50 }}>
            <Modal.Header closeButton>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <AccountCircleIcon /><small style={{ marginLeft: 10, marginTop: 2 }}>Mr.{props?.userLogin?.name}</small>
                </div>
            </Modal.Header>
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column',overflowY: 'auto', maxHeight: '400px' }}>
                        {dataChat && dataChat.map((data, index) => {

                            return (
                                <div key={index}>
                                    {
                                        data?.status == 1 ? (<>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                <AccountCircleIcon style={{ fontSize: 20, marginLeft: 20, marginTop: 10 }} />
                                                <small style={{ marginLeft: 10, marginTop: 10 }}>{data?.crator_name}</small>
                                            </div>
                                            <UserCharts data={data} />
                                        </>) : (<>
                                            <MyCharts data={data} />
                                        </>)
                                    }
                                </div>
                            )
                        })}

                    </div>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <input
                            style={{
                                width: '80%',
                                marginLeft: 10,
                                marginTop: 10,
                                borderRadius: 5,
                                border: `1px solid ${isInputClicked ? 'red' : '#5EBFE3'}`,
                                marginRight: 5,
                                height: 40
                            }}
                            value={datasend}
                            onChange={(e) => setDatasend(e.target.value)}
                            onClick={() => { handleInputClick() }}
                            onBlur={() => { handleInputBlur() }}
                        />

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 10,
                            border: '1px solid #5EBFE3',
                            marginRight: 5,
                            borderRadius: 5,
                            width: '15%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            backgroundColor: active === 'save' ? '#e9eef5' : '', // Fix the condition here
                        }}
                            onMouseOver={() => setActive('save')}
                            onMouseLeave={() => setActive(null)}
                            onClick={() => { OnChat() }}
                        >
                            {
                                isLoading === true ? (<>
                                    <CircularProgress size={20} />
                                </>) : (<>
                                    <small style={{ fontWeight: 'bold', color: '#007BFF' }}>
                                        Send
                                    </small>
                                    <SendIcon style={{ color: '#007BFF' }} />
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
function MyCharts({ data }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 20 }}>
            <div style={{ backgroundColor: '#e9eef5', marginTop: 5, borderRadius: 5, marginRight: 10, display: 'flex', flexDirection: 'column' }}>
                <small style={{ marginLeft: 20 }}>{data?.details}</small>
                <small style={{ marginLeft: 20 }}>{moment(data?.created_at).format('DD-MM-YYYY HH:mm:ss')}</small>
            </div>
        </div>
    )
}
function UserCharts({ data }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10, marginBottom: 20 }}>
            <div style={{ backgroundColor: '#e9eef5', marginTop: 5, borderRadius: 5, marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                <small style={{}}>{data?.details}</small>
                <small style={{}}>{moment(data?.created_at).format('DD-MM-YYYY HH:mm:ss')}</small>
            </div>
        </div>

    )
}





