import React from 'react'
import { Modal } from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Spinner } from "react-bootstrap";
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));
export const ModalSaveTable = (props) => {
    const classes = useStyles();
    return (
        <Modal show={props.showsave} onHide={props.handleCloseSavle} style={{ paddingTop: 50 }} size="sm">
            <Modal.Header closeButton>
                <span style={{ fontSize: 20, paddingTop: 10 }}>
                    Save As</span>
            </Modal.Header>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 10 }}>
                <TextField
                    style={{ width: '90%', marginLeft: 10 }}
                    value={props.saveAs}
                    onChange={e => props.setSaveAs(e.target.value)}
                    id="standard-basic"
                    label="Form Name" />

            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, marginLeft: 10 }}>
                <Button
                    style={{ marginRight: 20, marginBottom: 10 }}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={() => { props.OnCreate() }}
                >
                    {!props.isLoading ? (
                        <>
                            Save
                        </>
                    ) : (
                        <>
                            {
                                <Spinner animation="border" variant="light" size='sm' />
                            }
                        </>)
                    }
                </Button>
            </div>
        </Modal>
    )
}
