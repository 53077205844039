import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import LongMenu from "../components/ListSetting";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import ErrorIcon from '@material-ui/icons/Error';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DescriptionIcon from '@material-ui/icons/Description';
import CircularProgressWithLabel from "../components/CircularProgressWithLabel"
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function FormList() {
  const classes = useStyles();
  const [dataList, setDataList] = useState([])
  const [dataListFilter, setDataListFilter] = useState([])
  const [listdepartment, setlistdepartment] = useState('')
  const [listallemployee, setListallemployee] = useState([]);
  const [dataLavel, setDataLavel] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [dataLaveldefualt, setDataLaveldefualt] = useState([])
  const [dataUserdefualt, setDataUserdefualt] = useState([])
  const [loading, setLoading] = useState(true);
  const [filterstatus, setFilterstatus] = useState('')
  const [conditionsfilter, setConditionsfilter] = useState('')
  const [getvalues,setGetvalues]=useState('')
  const [active, setActive] = useState("");
  const OnloadFormData = () => {
    axios.get('/api/document-management/form/forms/').then((data) => {
      setDataList([...data?.data?.results])
      setLoading(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadFormDataForFilter = () => {
    axios.get('/api/document-management/form/forms/').then((data) => {
      setDataListFilter([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const filterFormData = (e) => {
    setFilterstatus(e)
    setConditionsfilter(1)
    if (e === 'reset') {
      OnloadFormData()
    } else {
      let filterlist = dataListFilter.filter((el) => el.formstatus == e)
      setDataList([...filterlist])
    }
  }
  const filterForSearch = (e) => {
    setGetvalues(e)
    if (conditionsfilter === 1) {
      let searchname = dataListFilter.filter((el) => el.form_name.toLowerCase().includes(e.toLowerCase()));
      if (!e) {
        OnloadFormData()
      } else {
        setDataList([...searchname])
      }

    } else {
      let searchname = dataList.filter((el) => el.form_name.toLowerCase().includes(e.toLowerCase()));
      if (!e) {
        OnloadFormData()
      } else {
        setDataList([...searchname])
      }
    }
  }
  const OnLoadCount = () => {
    axios.get('/api/document-management/setting/all-approve').then((data) => {

    }).catch((err) => {
      console.log(err)
    })
  }
  const OnlaodDepartment = () => {
    axios.get('/api/document-management/setting/all-department').then((data) => {
      setlistdepartment([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadAllEmployee = () => {
    axios.get('/api/document-management/setting/all-employee').then((data) => {
      setListallemployee([...data?.data?.results])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadInformationsTimeline = (form_id) => {
    axios.get(`/api/document-management/form/get-approval-data/${form_id}`).then((data) => {
      setDataLavel([...data?.data?.approvals])
      setDataUser([...data?.data?.users])
    }).catch((err) => {
      console.log(err)
    })
  }
  const OnloadDefualtTimeLine = () => {
    axios.get('/api/document-management/form/get-approval-load').then((data) => {
      setDataLaveldefualt([...data?.data?.approvals])
      setDataUserdefualt([...data?.data?.users])
    }).catch((err) => {
      console.log(err)
    })
  }
  const columns = [
    {
      field: 'form_name',
      headerName: 'Form_name',
      width: 200,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ຊື່ແບບຟອມ</small>
      ),
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <DescriptionIcon style={{ marginRight: 10, color: '#007bff', fontWeight: 'bold' }} />
          <small style={{ cursor: 'pointer' }} onClick={() => { OnloadInformationsTimeline(params.row.form_id) }}>
            {params.row.form_name}
          </small>

        </div>
      )
    },

    {
      field: 'created_at',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ວັນທີ່</small>
      ),
      renderCell: (params) => (
        <small>{moment(params.row.created_at).format('DD-MM-YYYY')}</small>
      )
    },
    {
      field: 'department_name',
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ພະແນກ</small>
      ),
      width: 200,
      renderCell: (params) => (
        <small>{params.row.department_name}</small>
      )
    },
    {
      field: 'req_status',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>ສະຖະນາ</small>
      ),
      renderCell: (params) => (
        <>
          {
            params.row.formstatus === 0 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ScreenRotationIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#4f46e5' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#4f46e5' }}>Created....</small>
              </div>
            )}
          {
            params.row.formstatus === 1 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <RepeatOneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#f000b9' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#f000b9' }}>Requested....</small>
              </div>
            )}
          {
            params.row.formstatus === 2 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <AutorenewIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff9800' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff9800' }}>In Progress....</small>
              </div>
            )}
          {
            params.row.formstatus === 3 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <DoneIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#10b981' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#10b981' }}>Approved....</small>
              </div>
            )}
          {
            params.row.formstatus === 4 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ErrorIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Rejected....</small>
              </div>
            )}
          {
            params.row.formstatus === 5 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ReportProblemIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#01579b' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#01579b' }}>Draft....</small>
              </div>
            )}
          {
            params.row.formstatus === 6 && (
              <div style={{ border: '1px solid #5EBFE3', borderRadius: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: 25 }}>
                <ReportProblemIcon style={{ fontSize: 20, marginLeft: 5, marginTop: 2, color: '#ff5724' }} />
                <small style={{ fontSize: 13, marginTop: -14, marginLeft: 3, color: '#ff5724' }}>Draft....</small>
              </div>
            )}
        </>
      ),
    },
    
    {
      field: 'actions',
      width: 150,
      renderHeader: () => (
        <small style={{ color: '#5ebfe3', fontWeight: 'bold', fontSize: 20 }}>#####</small>
      ),
      renderCell: (params) => (
        <div
        onClick={() => { OnloadInformationsTimeline(params.row.form_id) }}
        >
          <LongMenu
            form_id={params.row.form_id}
            approval_id={params.row.approval_id}
            formstatus={params.row.formstatus}
            OnloadFormData={OnloadFormData}
            listdepartment={listdepartment}
            listallemployee={listallemployee}
          />

        </div>

      )
    },
  ];
  useEffect(() => {
    OnloadFormData()
    OnloadFormDataForFilter()
    OnloadDefualtTimeLine()
    OnlaodDepartment()
    OnloadAllEmployee();
    OnLoadCount();
  }, [])
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <h5 style={{ color: '#007bff', fontWeight: 'bold' }}>ຈັດການຂໍ້ມູນ</h5>
          <ArrowForwardIosIcon style={{ fontSize: 15, marginTop: 6, fontWeight: 'bold', color: '#007bff' }} />
          <h5 style={{ color: '#007bff' }}>ເເບບຟອມ</h5>
        </div>
        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '60%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '60%', justifyContent: 'flex-start' }}>
              <input
                type="text"
                placeholder="Search field"
                style={{
                  width: '50%',
                  border: '1px solid #5EBFE3',
                  height: 35,
                  borderRadius: 5
                }}
                value={getvalues}
                onChange={(e) => filterForSearch(e.target.value)}
              />
              <div style={{ cursor: 'pointer', backgroundColor: active === 's' ? '#e9eef5' : '', width: '20%', border: '1px solid #5EBFE3', borderRadius: 5, marginLeft: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onMouseOver={() => setActive('s')}
                onMouseLeave={() => setActive(null)}
              >
                <SearchIcon style={{ color: '#5EBFE3' }} />
                <small>ຄົ້ນຫາ</small>
              </div>
            </div>
            <select
              style={{
                border: '1px solid #5EBFE3',
                height: 35,
                borderRadius: 5,
                marginRight: 15
              }}
              onChange={(e) => filterFormData(e.target.value)}
              value={filterstatus}
            >
              <option value='reset'>=ໃຫ້ເລື່ອກສະຖານະ=</option>
              <option value='0'>Created</option>
              <option value='1'>Requested</option>
              <option value='2'>In Progress</option>
              <option value='3'>Approval</option>
              <option value='4'>Rejected</option>
              <option value='6'>Draft</option>
            </select>
          </div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '40%', justifyContent: 'center', justifyItems: 'center' }}>
            <div style={{ backgroundColor: '#e9eef5', display: 'flex', borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
              <h5 style={{ marginLeft: 10, fontSize: 20, color: "#007bff", marginLeft: 10, marginRight: 10 }}>ລາຍການອະນຸມັດລ່າສຸດ</h5>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ height: 500, width: '60%' }}>
            <DataGrid
              rows={dataList}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
            />
          </div>
          <div style={{ border: '1px solid #ccc', backgroundColor: 'white', borderRadius: 10, width: '40%', marginLeft: 20 }}>
            {
              dataLavel.length === 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                  < CircularProgressWithLabel
                    dataLaveldefualt={dataLaveldefualt}
                    dataUserdefualt={dataUserdefualt}
                  />
                </div>

              ) : (<>
                <h5 style={{ marginLeft: 10, fontSize: 20, fontWeight: 20 }}>Timeline</h5>
                <Timeline align="alternate">
                  {dataLavel && dataLavel.map((item, index) => {
                    return (<>
                      <TimelineItem key={index}>
                        <TimelineOppositeContent>
                          <Typography variant="body2" color="textSecondary">
                            ວັນທີ່ສ້າງ:{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}
                          </Typography>
                          {
                            item?.levels == 0 ? (
                              <>
                                <Typography color="textSecondary" style={{ marginLeft: 10 }} >
                                  ສ້າງແບບຟອມ..
                                </Typography>

                              </>
                            ) : item?.levels == 1 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 2 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress..</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 3 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval..</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 4 ? (<>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >


                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected..</small>
                                      </div>
                                    </Typography>

                                  </>) : item?.formstatus === 6 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >


                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>   Draft...</small>
                                        </div>
                                      </Typography>
                                    </>) : null
                                }
                              </>
                            ) : item?.levels == 2 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting..</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 2 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>In Progress</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 3 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 4 ? (<>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Rejected</small>
                                      </div>
                                    </Typography>

                                  </>) : item?.formstatus === 6 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >
                                        Draft
                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Draft</small>
                                        </div>
                                      </Typography>
                                    </>) : null
                                }
                              </>
                            ) : item?.levels == 3 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 2 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> In Progress</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 3 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 4 ? (<>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Rejected</small>
                                      </div>
                                    </Typography>

                                  </>) : item?.formstatus === 6 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Draft</small>
                                        </div>
                                      </Typography>
                                    </>) : null
                                }
                              </>
                            ) : item?.levels == 4 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Waiting</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 2 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> In Progress</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 3 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> Approval</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 4 ? (<>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Rejected</small>
                                      </div>
                                    </Typography>

                                  </>) : item?.formstatus === 6 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Draft</small>
                                        </div>
                                      </Typography>
                                    </>) : null
                                }
                              </>
                            ) : item?.levels == 5 ? (
                              <>
                                {
                                  item?.formstatus == 0 || item?.formstatus == 1 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Waiting</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 2 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}> In Progress</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 3 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Approval</small>
                                        </div>
                                      </Typography>
                                    </>
                                  ) : item?.formstatus === 4 ? (<>
                                    <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                      <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                        <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Rejected</small>
                                      </div>
                                    </Typography>

                                  </>) : item?.formstatus === 6 ? (
                                    <>
                                      <Typography color="textSecondary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} >

                                        <div style={{ backgroundColor: '#007bff', borderRadius: 5 }}>
                                          <small style={{ color: 'white', fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Draft</small>
                                        </div>
                                      </Typography>
                                    </>) : null
                                }
                              </>
                            ) : null
                          }
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot style={{ backgroundColor: "#007bff" }}>
                            {
                              item?.levels == 0 ? (
                                <>
                                  <AccessAlarmIcon />

                                </>
                              ) : item?.levels == 1 ? (
                                <>
                                  {
                                    item?.formstatus == 0 || item?.formstatus == 1 ?
                                      (
                                        <>
                                          <AccessAlarmIcon />
                                        </>
                                      ) : item?.formstatus === 2 ? (
                                        <>
                                          <AutorenewIcon />
                                        </>
                                      ) : item?.formstatus === 3 ? (
                                        <>
                                          <CheckCircleOutlineIcon />
                                        </>
                                      ) : item.formstatus === 4 ? (
                                        <>
                                          <ErrorIcon />
                                        </>

                                      ) : item.formstatus === 6 ? (
                                        <>
                                          <ReportProblemIcon />
                                        </>
                                      ) : null
                                  }
                                </>
                              ) : item?.levels == 2 ? (
                                <>
                                  {
                                    item?.formstatus == 0 || item?.formstatus == 1 ?
                                      (
                                        <>
                                          <AccessAlarmIcon />
                                        </>
                                      ) : item?.formstatus === 2 ? (
                                        <>
                                          <AutorenewIcon />
                                        </>
                                      ) : item?.formstatus === 3 ? (
                                        <>
                                          <CheckCircleOutlineIcon />
                                        </>
                                      ) : item.formstatus === 4 ? (
                                        <>
                                          <ErrorIcon />
                                        </>

                                      ) : item.formstatus === 6 ? (
                                        <>
                                          <ReportProblemIcon />
                                        </>
                                      ) : null
                                  }

                                </>
                              ) : item?.levels == 3 ? (
                                <>
                                  {
                                    item?.formstatus == 0 || item?.formstatus == 1 ?
                                      (
                                        <>
                                          <AccessAlarmIcon />
                                        </>
                                      ) : item?.formstatus === 2 ? (
                                        <>
                                          <AutorenewIcon />
                                        </>
                                      ) : item?.formstatus === 3 ? (
                                        <>
                                          <CheckCircleOutlineIcon />
                                        </>
                                      ) : item.formstatus === 4 ? (
                                        <>
                                          <ErrorIcon />
                                        </>

                                      ) : item.formstatus === 6 ? (
                                        <>
                                          <ReportProblemIcon />
                                        </>
                                      ) : null
                                  }

                                </>
                              ) : item?.levels == 4 ? (
                                <>
                                  {
                                    item?.formstatus == 0 || item?.formstatus == 1 ?
                                      (
                                        <>
                                          <AccessAlarmIcon />
                                        </>
                                      ) : item?.formstatus === 2 ? (
                                        <>
                                          <AutorenewIcon />
                                        </>
                                      ) : item?.formstatus === 3 ? (
                                        <>
                                          <CheckCircleOutlineIcon />
                                        </>
                                      ) : item.formstatus === 4 ? (
                                        <>
                                          <ErrorIcon />
                                        </>

                                      ) : item.formstatus === 6 ? (
                                        <>
                                          <ReportProblemIcon />
                                        </>
                                      ) : null
                                  }
                                </>
                              ) : item?.levels == 5 ? (
                                <>
                                  {
                                    item?.formstatus == 0 || item?.formstatus == 1 ?
                                      (
                                        <>
                                          <AccessAlarmIcon />
                                        </>
                                      ) : item?.formstatus === 2 ? (
                                        <>
                                          <AutorenewIcon />
                                        </>
                                      ) : item?.formstatus === 3 ? (
                                        <>
                                          <CheckCircleOutlineIcon />
                                        </>
                                      ) : item.formstatus === 4 ? (
                                        <>
                                          <ErrorIcon />
                                        </>

                                      ) : item.formstatus === 6 ? (
                                        <>
                                          <ReportProblemIcon />
                                        </>
                                      ) : null
                                  }

                                </>
                              ) : null
                            }
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Paper elevation={3} className={classes.paper}>
                            <ComponentCell
                              item={item}
                              dataUser={dataUser}
                            />
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    </>)
                  })}
                </Timeline>

              </>)
            }
          </div>
        </div>
      </div>
    </>
  )
}
function ComponentCell({ item, dataUser }) {
  const filter = dataUser.filter((el) => el.level_uid == item?.level_uid);
  return (<>
    {
      filter.map((data, index) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 10 }} key={index}>
              <div style={{ backgroundColor: '#e9eef5', width: 50, height: 50, borderRadius: '50%', }}>
                <img src={data.image[0]} style={{ width: 50, height: 50, borderRadius: '50%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <small>{data?.create_employee_name}</small>
              </div>
            </div>
          </>

        )
      })
    }
  </>)
}
