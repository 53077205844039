import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import { LoginContext } from "../page/contexts/LoginContext";
import { MySinature } from "./MySinature";
export const ModalSignature = (props) => {
    const [active, setActive] = useState("");
    const {
        openingsetting,
        handleCloseOpenting,
        opening

    } = useContext(LoginContext)
    return (
        <div style={{ width: '100%' }}>

            <MySinature
                openingsetting={openingsetting}
                handleCloseOpenting={handleCloseOpenting}
                OnloadFormData={props.OnloadFormData}
            />
            <Modal show={props.signature_user} onHide={props.handleClosesignature_user} style={{ paddingTop: 50 }} >
                <Modal.Header closeButton>

                </Modal.Header>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ border: '3px solid red', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <WarningIcon style={{ color: 'red', marginLeft: 10, marginTop: 10 }} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <small style={{ fontSize: 20 }}>ທ່ານຍັງບໍ່ມີລາຍເຊັນຢູ່ໃນລະບົບ.</small>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#2196F3',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            marginTop: 20,
                            cursor: 'pointer',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            backgroundColor: active === 'N' ? '#2196F3' : '#007BFF', // Fix the condition here
                        }}
                        onMouseOver={() => setActive('N')}
                        onMouseLeave={() => setActive(null)}
                        onClick={() => {props.handleClosesignature_user(); opening() }}
                    >
                        <AddIcon style={{ color: 'white' }} />
                    </div>
                </div>

            </Modal>
        </div>
    )
}
