import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
export const ModalSave = (props) => {
    return (
    <div style={{ width: '100%' }}>
      <Modal show={props.showSave} style={{ paddingTop: 50 }} size="sm">
        <Modal.Header closeButton>
          <span style={{ fontSize: 25, fontWeight: 'bold', paddingTop: 10, color: '#2eb85c' }}>Peding..</span>
        </Modal.Header>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
          {props.isLoading === false ? (
            <>
              <CheckCircleIcon style={{ color: '#2eb85c', marginLeft: 40, marginTop: 20, fontSize: 40 }} />
              <small style={{ fontSize: 30, marginRight: 35, marginTop: 20 }}>Successfully....</small>
            </>
          ) : (
            <>
              <CircularProgress style={{ marginLeft: 40, marginTop: 20, fontSize: 40 }} />
              <small style={{ fontSize: 30, marginRight: 30, marginTop: 20 }}>Waiting....</small>
            </>)
          }
        </div>
      </Modal>
    </div>
    )
}

