import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { Rnd } from 'react-rnd';

import { makeStyles } from '@material-ui/core/styles';
import { Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";

import { LoginContext } from "../page/contexts/LoginContext";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));
export const EdiForm = (props) => {
    // const { id } = props;
    const classes = useStyles();
    let componentRef = useRef(null)
    const [isLoading, setIsLoading,] = useState(false);
    const [datatable, setDatatable] = useState([])
    const [datatable1, setDatatable1] = useState([])
    const [datatable2, setDatatable2] = useState([])
    const [tableRow, setTableRow] = useState('')
    const [tableColumn, setTableColumn] = useState('')
    const [show, setShow] = useState(false);
    const [showcoments, setShowcoments] = useState(false)
    const [uselable, setUselable] = useState([])
    const [usecheckbox, setUsecheckbox] = useState([])
    const [usetextlist, setUsetextlist] = useState([])
    const [getindex, setGetindex] = useState('')
    const [countRow, setCountRow] = useState('')
    const [countRow1, setCountRow1] = useState('')
    const [countRow2, setCountRow2] = useState('')
    const [countRow3, setCountRow3] = useState('')
    const [index1, setIndex1] = useState('')
    const [index2, setIndex2] = useState('')
    const [bold, setBold] = useState(false)
    const [editlable, setEditlable] = useState('')
    const [showdeltable, setShowdeltable] = useState(true)
    const [showdeltable1, setShowdeltable1] = useState(false)
    const [showdeltable2, setShowdeltable2] = useState(false)
    const [listtext, setListText] = useState([])
    const [datacheckbox, setDatacheckbox] = useState([])
    const [listtextw, setListTextw] = useState([])
    const [fontSize, setFontSize] = useState('')
    const [data, setdata] = useState([])
    const [editcheckboxvalues, setEditcheckboxvalues] = useState('')
    const [datalable, setDatalable] = useState([])
    const [usetable, setUsetable] = useState([])
    const [tablechidren, setTablechidren] = useState([])
    const [usetable1, setUsetable1] = useState([])
    const [usetable2, setUsetable2] = useState([])
    const [usetable3, setUsetable3] = useState([])
    const [tablechidren1, setTablechidren1] = useState([])
    const [tablechidren2, setTablechidren2] = useState([])
    const [tablechidren3, setTablechidren3] = useState([])
    const [inputValues, setInputValues] = useState('')
    const [inputValues1, setInputValues1] = useState('')
    const [inputValues2, setInputValues2] = useState('')
    const [inputValues3, setInputValues3] = useState('')
    const [selectedImage, setSelectedImage] = useState([])
    const [showsave, setShowsave] = useState(false);
    const [widthsize, setWidthsize] = useState('')
    const [widthsize1, setWidthsize1] = useState('')
    const [heightsize, setHeightsize] = useState('')
    const [heightsize1, setHeightsize1] = useState('')
    const [conditions, setConditions] = useState(false)
    const [formstatus, setformstatus] = useState('')
    const [tables, setTables] = useState([[]])
    const [tables1, setTables1] = useState([[]])
    const [tables2, setTables2] = useState([[]])
    const [tables3, setTables3] = useState([[]])
    const [tables4, setTables4] = useState([[]])
    const [saveAs, setSaveAs] = useState('')
    const [textarea, setTextarea] = useState('')
    const [dataChat, setDataChat] = useState([])
    const [name, setname] = useState([])
    const [datasend, setDatasend] = useState('')
    const {
        setshowformEditScreen,
    } = useContext(LoginContext)


    const OnloadListData = () => {
        console.log("props=", props.form_id)
        axios.get(`/api/document-management/form/get-form/${props.id}`).then((data) => {
            // setformstatus([...data?.data?.dataForms][0].formstatus)
            if (data?.data?.resuGetTable_sizeforinputlts !== null) {
                setUsetextlist([...data?.data?.resuGetTable_sizeforinputlts])
            }

            if (data?.data?.GetImage_positions === null) {

            } else {
                setSelectedImage([...data?.data?.GetImage_positions])
            }
            if (data?.data?.GetTable_checkbox === null) {
            } else {
                setUsecheckbox([...data?.data?.GetTable_checkbox])
            }
            if (data?.data?.GetTable_label === null) {

            } else {
                setUselable([...data?.data?.GetTable_label])
            }
            if (data?.data?.GetTable_position_one === null) {

            } else {
                setUsetable([...data?.data?.GetTable_position_one])
            }
            if (data?.data?.GetTable_positions_three === null) {

            } else {
                setCountRow3([...data?.data?.GetTable_positions_three][0].countRow)
                setTablechidren3(JSON.parse([...data?.data?.GetTable_positions_three][0].body_table))
            }
            if (data?.data?.GetTable_positions_two === null) {

            } else {
                setUsetable1([...data?.data?.GetTable_positions_two])
            }
            if (data?.data?.GetTable_positions_three === null) {
            } else {
                setUsetable2([...data?.data?.GetTable_positions_three])
            }


            if (data?.data?.GetTable_position_one === null) {

            } else {
                setCountRow([...data?.data?.GetTable_position_one][0].countrow)
                setTablechidren(JSON.parse([...data?.data?.GetTable_position_one][0].body_table))
            }

            if (data?.data?.GetTable_positions_two === null) {

            } else {
                setCountRow1([...data?.data?.GetTable_positions_two][0].countrow)
                setTablechidren1(JSON.parse([...data?.data?.GetTable_positions_two][0].body_table))
            }

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        OnloadListData()

    }, [])
    return (
        <div style={{ width: '100%', backgroundColor: '#ebedef' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div ref={(el) => (componentRef = el)} style={{ display: 'flex', width: '210mm', height: 2000, backgroundColor: 'white', marginLeft: 10 }}>
                    {
                        datatable && datatable.map((el, index) => {
                            if (el.type === 'table') {
                                return (
                                    <Rnd
                                        key={index}
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}

                                    >
                                        <table style={{ border: '1px solid gray' }}  >
                                            {
                                                tables && tables.map((item, index1) => {
                                                    return (
                                                        <>
                                                            <tr key={index1} style={{ border: '1px solid gray' }}>
                                                                {
                                                                    item.map((data, index) => {
                                                                        return (
                                                                            <>
                                                                                <td key={index} style={{ width: `${data.width}px`, border: '1px solid gray' }} colSpan={data.cols} rowSpan={data.rows}>
                                                                                    <textarea value={data?.name}

                                                                                        style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </table>
                                    </Rnd>
                                )
                            }
                        })
                    }

                    {
                        usetable && usetable.map((el, index) => {
                            return (
                                <div key={index}>
                                    <Rnd
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}

                                    >
                                        <table style={{ border: '1px solid gray' }}>
                                            {
                                                tablechidren && tablechidren.map((item, index1) => {
                                                    return (
                                                        <>
                                                            <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                {
                                                                    item.map((data, index2) => {
                                                                        return (
                                                                            <>
                                                                                <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                    <textarea value={data?.name}

                                                                                        style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </table>
                                    </Rnd>
                                </div>
                            )
                        })
                    }
                    {
                        usetable1 && usetable1.map((el, index) => {
                            return (
                                <div key={index}>
                                    <Rnd
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}

                                    >
                                        <table style={{ border: '1px solid gray' }}>
                                            {
                                                tablechidren1 && tablechidren1.map((item, index1) => {
                                                    return (
                                                        <>
                                                            <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                {
                                                                    item.map((data, index2) => {
                                                                        return (
                                                                            <>
                                                                                <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                    <textarea value={data?.name}

                                                                                        style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </table>
                                    </Rnd>
                                </div>
                            )
                        })
                    }

                    {
                        usetable2 && usetable2.map((el, index) => {
                            return (
                                <div key={index}>
                                    <Rnd
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}


                                    >
                                        <table style={{ border: '1px solid gray' }}>
                                            {
                                                tablechidren2 && tablechidren2.map((item, index1) => {
                                                    return (
                                                        <>
                                                            <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                {
                                                                    item.map((data, index2) => {
                                                                        return (
                                                                            <>
                                                                                <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                    <textarea value={data?.name}

                                                                                        style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </table>
                                    </Rnd>
                                </div>
                            )
                        })
                    }
                    
                    {
                        usetable3 && usetable3.map((el, index) => {
                            return (
                                <div key={index}>
                                    <Rnd
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,

                                        }}



                                    >
                                        <table style={{ border: '1px solid gray' }}>
                                            {
                                                tablechidren3 && tablechidren3.map((item, index1) => {
                                                    return (
                                                        <>
                                                            <tr key={index1} style={{ width: `${item.width}px`, border: '1px solid gray' }}>
                                                                {
                                                                    item.map((data, index2) => {
                                                                        return (
                                                                            <>
                                                                                <td key={index2} style={{ border: '1px solid gray', width: `${data.width}px` }} colSpan={data.cols} rowSpan={data.rows}>

                                                                                    <textarea value={data?.name}

                                                                                        // onClick={()=>{OnclickTable(index,index1)}}
                                                                                        style={{ border: 'none', width: '100%', height: `${data?.height}px` }} />
                                                                                </td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </table>
                                    </Rnd>
                                </div>
                            )
                        })
                    }

                    {
                        uselable && uselable.map((el, index) => {
                            return (
                                <Rnd
                                    key={index}
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,

                                    }}

                                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                                >

                                    <small style={{ fontSize: el.font, fontWeight: el.fontWeight }}>{el.name}</small>
                                </Rnd>
                            )

                        })
                    }
                    {
                        datalable && datalable.map((el, index) => {
                            return (
                                <>
                                    <Rnd
                                        key={index}
                                        default={{
                                            x: el.positionX,
                                            y: el.positionY,
                                        }}

                                    >
                                        <small style={{ fontSize: el.font, fontWeight: el.fontWeight }}>{el.name}</small>
                                    </Rnd>
                                </>
                            )

                        })
                    }
                    {
                        usecheckbox && usecheckbox.map((el, index) => {
                            return (
                                <Rnd
                                    key={index}
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,

                                    }}

                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <input type="checkbox" style={{ cursor: 'pointer' }} />
                                        <small style={{ marginLeft: 5 }}>{el.name}</small>

                                    </div>
                                </Rnd>
                            )
                        })
                    }
                    {
                        usetextlist && usetextlist.map((e, index) => {
                            return (
                                < Rnd
                                    default={{
                                        x: e.positionX,
                                        y: e.positionY,
                                    }}

                                >
                                    <RowComponent
                                        index={index}

                                        e={e}

                                    />
                                </Rnd>
                            )
                        })
                    }
                    {
                        datacheckbox && datacheckbox.map((el, index) => {
                            return (
                                <Rnd
                                    key={index}
                                    default={{
                                        x: el.positionX,
                                        y: el.positionY,

                                    }}

                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <input type="checkbox" style={{ cursor: 'pointer' }} />
                                        <small style={{ marginLeft: 5 }}>{el.name}</small>

                                    </div>

                                </Rnd>
                            )

                        })

                    }
                    {
                        listtext && listtext.map((e, index) => {
                            return (
                                < Rnd
                                    default={{
                                        x: e.positionX,
                                        y: e.positionY,
                                    }}

                                >

                                    <RowComponent
                                        index={index}
                                        e={e}
                                    />
                                </Rnd>
                            )
                        })
                    }
                    {
                        selectedImage && selectedImage.map((e, index) => {
                            console.log("e=", e.images[0])

                            return (
                                <Rnd
                                    default={{
                                        x: e.positionX,
                                        y: e.positionY,
                                    }}
                                >
                                    <img src={e.images[0]} alt="Selected Picture" style={{ width: `${e?.width}px`, height: `${e?.height}px` }} />
                                </Rnd>
                            )
                        })
                    }
                </div>
                <div>
                    <ReactToPrint
                        trigger={() =>
                            <div style={{ border: '1px solid #ccc', borderRadius: 3, width: 80, marginLeft: 10, cursor: 'pointer', height: 30, marginTop: 10, marginRight: 10 }} >
                              <small>Print</small>

                            </div>
                        }
                        content={() => componentRef}
                    />
                </div>
            </div>
        </div>
    )

}
function RowComponent({ e }) {
    return (
        <>
            <input
                placeholder="......................................................................................................................................................................"
                value={e?.name}

                style={{
                    width: `${e.width}px`,
                    height: `${e.height}px`,
                    border: 'none',
                }}
            />
        </>
    )
}


