import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import { ModalOpenSetting } from "./ModalOpenSetting";
import { ModalSave } from "./ModalSave";
import { ModalOpenSuccessully } from "./ModalOpenSuccessully";
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
const ITEM_HEIGHT = 48;
export default function MenuActions(props) {
  const listdepartment = props.listdepartment
  const listallemployee = props.listallemployee
  const approval_id = props.approval_id
  const req_uid = props.req_uid
  const req_status = props.req_status
  const signature_id = props.signature_id
  const Onloaddata = props.Onloaddata
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data_emp, setData_emp] = useState([]);
  const [g_department_id, setG_department_id] = useState('')
  const [listshowemployee, setListshowemployee] = useState([])
  const [isLoading, setIsLoading,] = useState(false);
  const [number, setNumber] = useState('')
  const [successfully, setSuccessfully] = useState(false)
  const [employee, setEmployee] = useState([]);
  const [openingsetting, setOpeningsetting] = useState(false)
  const [showSave, setShowSave] = useState(false);
  const [modalstatus, setModalstatus] = useState('')
  const open = Boolean(anchorEl);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState("");
  const handleCloseOpenting = () => {
    setOpeningsetting(false)
  }
  const handleShow = () => {
    setAnchorEl(null);
    setShow(true)
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const OnChooseNumber = (e) => {
    setNumber(e)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosedel = () => {
    setShow(false);
    setData_emp([]);
    setListshowemployee([]);
    setNumber('')
    setG_department_id('')
  }
  const OnOptionsDepart = (e) => {
    setG_department_id(e)
    let list = listallemployee.filter((el) => el.department_id == e)
    setListshowemployee([...list])
  }
  const Successfully = () => {
    setSuccessfully(false)
  }

  const CreateEmployeeApproved = () => {
    setIsLoading(true)
    let Informdata = {
      req_id: req_uid,
      max_approval: number,
      details: employee
    }

    axios.post('/api/document-management/incoming/apprval/create-approval', Informdata).then((data) => {

      setIsLoading(false)
      Onloaddata();
      setShow(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  const CreateInsert = (approval_id, signature_id, req_uid, req_status) => {
    if (approval_id === null) {
      setAnchorEl(null);
      setOpeningsetting(true)
      return;
      return;
    } if (signature_id === null) {
      setAnchorEl(null);
      alert("signature_id")
      return;
    } else {
      if (req_status === 1) {
        setAnchorEl(null);
        setSuccessfully(true);
        setModalstatus(2)
        return;
      } else if (req_status === 4) {
        setAnchorEl(null);
        setModalstatus(1)
        setSuccessfully(true);
        return;
      } else {
        setAnchorEl(null);
        setShowSave(true)
        setIsLoading(true)
        let dataInform = {
          req_id: req_uid,
          approver: approval_id,
          comment: ''
        }
        console.log("dataInform=", dataInform)
        axios.post('/api/document-management/incoming/setting/insert-setting', dataInform).then((data) => {
          console.log("data=", data)
          Onloaddata();
          // setShowSave(false)
          // setIsSending(false)
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          const autoCloseTimeLoading = 1000;
          const timeoutId = setTimeout(() => {
            setIsLoading(false)
          }, autoCloseTimeLoading);
          const autoCloseTime = 2000;
          const timeout = setTimeout(() => {
            setShowSave(false)
          }, autoCloseTime);
          return () => clearTimeout(timeout, timeoutId);
        });

      }
    }

  }

  return (
    <div>
      <Modal show={show} onHide={handleClosedel} style={{ paddingTop: 50 }} size="lg">
        <Modal.Header closeButton>
          <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>Setting</span>
        </Modal.Header>
        {/* {JSON.stringify(data_emp)} */}
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '100%', marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 50, marginRight: 10 }}>
              <div
                style={{
                  border: '1px solid #5ebfe3',
                  backgroundColor: "#e9eef5",
                  borderTopLeftRadius: 5,
                  borderRight: 'none',
                  borderBottomLeftRadius: 5,
                  height: 40,

                  marginTop: 20
                }}>
                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກຈຳນວນຄົນ:</small>
              </div>
              <select
                onChange={(e) => OnChooseNumber(e.target.value)}
                value={number}
                style={{
                  display: 'flex',
                  flex: 1,
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderLeft: 'none',
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <option>ກະລຸນາເລື່ອກຈຳນວນຄົນ</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: 50, marginLeft: 10 }}>
              <div
                style={{
                  border: '1px solid #5ebfe3',
                  backgroundColor: "#e9eef5",
                  borderTopLeftRadius: 5,
                  borderRight: 'none',
                  borderBottomLeftRadius: 5,
                  height: 40,
                  marginTop: 20
                }}>
                <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ໃຫ້ເລື່ອກພະແນກ:</small>
              </div>
              <select
                style={{
                  display: 'flex',
                  flex: 1,
                  border: '1px solid #5ebfe3',
                  height: 40,
                  marginTop: 20,
                  borderLeft: 'none',
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  width: '30%'
                }}
                onChange={(e) => OnOptionsDepart(e.target.value)}
                value={g_department_id}
              >

                <option>ກະລຸນາເລື່ອກພະແນກ</option>
                {
                  listdepartment && listdepartment.map((item, index) => {
                    return (
                      <option key={index} value={item?.department_id}>{item?.department_name}</option>
                    )
                  })
                }
              </select>

            </div>
          </div>
          <div style={{ height: 300, width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginLeft: 50, marginRight: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                listshowemployee.map((data, index) => {

                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <small style={{ cursor: 'pointer' }} >

                        <ReadCheckbox
                          data={data}
                          setEmployee={setEmployee}
                          setData_emp={setData_emp}
                        />
                      </small>
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #5ebfe3', width: '50%', marginRight: 50, marginLeft: 10, marginBottom: 10, overflowY: 'auto' }}>
              {
                data_emp.map((data, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 0' }}>
                      <Checkbox
                        defaultChecked
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <small style={{ fontSize: 15, fontWeight: 'bold', marginTop: 10 }}>{data?.name}</small>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #5ebfe3',
              borderRadius: 3,
              marginRight: 50,
              marginBottom: 20,
              width: 100,
              height: 35,
              cursor: 'pointer',
              backgroundColor: active === 'save' ? '#e9eef5' : '', // Fix the condition here
            }}
              onMouseOver={() => setActive('save')}
              onMouseLeave={() => setActive(null)}
              onClick={() => { CreateEmployeeApproved(req_uid) }}
            >
              <small style={{ color: '#007bff' }}>
                {
                  !isLoading ? (<>
                    ບັນທືກ
                  </>) : (
                    <>
                      {
                        <Spinner animation="border" variant="light" size='sm' style={{ color: '#007bff' }} />
                      }
                    </>)
                }
              </small>
            </div>

          </div>
        </div>
      </Modal>

      <ModalOpenSetting
        openingsetting={openingsetting}
        handleCloseOpenting={handleCloseOpenting}
      />
      {
        showSave === true ? (<>
          <ModalSave
            showSave={showSave}
            handleClose={handleClose}
            isLoading={isLoading}
          />
        </>) : null
      }
      <ModalOpenSuccessully
        successfully={successfully}
        Successfully={Successfully}
        modalstatus={modalstatus}
      />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={() => { handleShow() }}><SettingsIcon style={{ color: '#5ebfe3' ,fontSize:15}} /><small style={{ marginLeft: 10, }} >ເລືອກຜູ້ອະນຸມັດ</small> </MenuItem>
        <MenuItem onClick={()=>{props.handleShowedit();setAnchorEl(null)}}><EditIcon style={{ color: '#5ebfe3' ,fontSize:15}} />  <small style={{ marginLeft: 10, }} >ແກ້ໄຂ</small></MenuItem>
        <MenuItem onClick={() => { CreateInsert(approval_id, signature_id, req_uid, req_status) }}><SendIcon style={{ color: '#5ebfe3',fontSize:15 }} /><small style={{ marginLeft: 10, }} >ສົ່ງ</small></MenuItem>
      </Menu>
    </div>
  );
}
function ReadCheckbox({ data, setEmployee, setData_emp }) {
  const [Ischecked, setIschecked] = useState(false)
  const handleChange = (event, employee_id, name) => {
    if (event.target.checked === true) {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => [...prevEmployee, { approver_id: employee_id }]);
      setData_emp(prevEmployee_data => [...prevEmployee_data, { approver_id: employee_id, name: name }]);
    } else {
      setIschecked(event.target.checked)
      setEmployee(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
      setData_emp(prevEmployee => prevEmployee.filter(item => item.approver_id !== employee_id));
    }
  };
  return (
    <Checkbox
      checked={Ischecked}
      onChange={(event) => handleChange(event, data?.employee_id, data?.name)}
      color="primary"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};
