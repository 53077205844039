import React, { useState, useRef } from "react";
import SignatureCanvas from 'react-signature-canvas';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Modal } from "react-bootstrap";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));
export const MySinature = (props) => {
    const classes = useStyles();
    const [active, setActive] = useState("");
    const [Ischecked, setIschecked] = useState(false)
    const [file, setFile] = useState();
    const [pictureUrl, setPictureUrl] = useState(null);
    const sigCanvas = useRef(null);
    const [signatureData, setSignatureData] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const clearSignature = () => {
        sigCanvas.current.clear();
    };
    const clearUpload = () => {
        setPictureUrl(null)
    }
    const saveSignature = () => {
        setIsLoading(true)
        if (sigCanvas.current) {
            const canvas = sigCanvas.current;
            const signatureDataUrl = canvas.toDataURL();
            let dataImage = {
                data: signatureDataUrl
            }
            axios.post('/api/document-management/signature/create-sign/', dataImage).then((data) => {
                props.setOpeningsetting(false)
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
            })

        }
    };


    const OnCreate = async () => {
        setIsLoading(true);
        let images
        if (!file) {
            images = 0
        } else {
            let formData = new FormData();
            for (const key of Object.keys(file)) {
                formData.append("file_name", file[key]);
            }
            formData.append("file_name", file);
            let profileImageReturnName = await axios.post("/api/document-management/signature/insert", formData);
            images = Object.values(profileImageReturnName.data)[0][0]
            console.log("images=",images)
            props.setOpeningsetting(false)
            setIsLoading(false)
        }
    }
    const CreateSignature = () => {
        setIschecked(false)
    }
    const CreateUpload = () => {
        setIschecked(true)
    }
    const handlePictureChange = (event) => {
        setFile(event.target.files);
        const picture = event.target.files[0];
        if (picture) {
            const pictureUrl = URL.createObjectURL(picture);
            setPictureUrl(pictureUrl);
        }
    };
    return (
        <div>
            <Modal show={props.openingsetting} onHide={props.handleCloseOpenting} style={{ paddingTop: 50 }} size="lg">
                <Modal.Header closeButton>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <AccountCircleIcon style={{ fontSize: 50 }} />
                            <h4 style={{ marginTop: 15 }}>Mr.
                                {props.user_name}
                            </h4>
                        </div>
                    </div>
                </Modal.Header>
                <div style={{ width: '100%' }}>
                    <div style={{ borderRadius: 3, display: 'flex', marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, flexDirection: 'column' }}>
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #5EBFE3',
                                borderRadius: 5,
                                width: 100,
                                height: 30,
                                cursor: 'pointer',
                                backgroundColor: active === 'c' ? '#e9eef5' : '',
                                boxShadow: active === 'c' ? '1px 2px 9px #F4AAB9' : ''
                            }}
                                onMouseOver={() => setActive('c')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { CreateSignature() }}
                            >
                                <small>Signature</small>
                            </div>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handlePictureChange}
                            />
                            <label htmlFor="contained-button-file" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 5,
                                width: 100,
                                height: 30,
                                cursor: 'pointer',
                                border: '1px solid #5EBFE3',
                                marginLeft: 10,
                                backgroundColor: active === 'u' ? '#e9eef5' : '',
                                boxShadow: active === 'u' ? '1px 2px 9px #F4AAB9' : ''
                            }}
                                onMouseOver={() => setActive('u')}
                                onMouseLeave={() => setActive(null)}
                                onClick={() => { CreateUpload() }}
                            >
                                <small>Upload</small>
                            </label>
                        </div>
                        {
                            Ischecked === false ? (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 20 }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                penColor="black"
                                                canvasProps={{
                                                    width: 150, height: 200, className: 'sigCanvas',
                                                    style: {
                                                        marginTop: 20,
                                                        border: '1px solid #5EBFE3',
                                                        backgroundColor: '#e9eef5',
                                                        cursor: 'pointer',
                                                        borderRadius: 5,
                                                        marginLeft: 10
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        >
                                            <div style={{
                                                border: '1px solid #5EBFE3',
                                                borderRadius: 5,
                                                width: 50,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                backgroundColor: active === 's' ? '#e9eef5' : '',
                                                boxShadow: active === 's' ? '1px 2px 9px #F4AAB9' : ''

                                            }}
                                                onMouseOver={() => setActive('s')}
                                                onMouseLeave={() => setActive(null)}
                                                onClick={clearSignature}
                                            >
                                                <small>ສ້າງໃໝ່</small>
                                            </div>
                                            <div style={{
                                                border: '1px solid #5EBFE3',
                                                borderRadius: 5,
                                                marginTop: 30,
                                                width: 50,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                backgroundColor: active === 'g' ? '#e9eef5' : '',
                                                boxShadow: active === 'g' ? '1px 2px 9px #F4AAB9' : ''
                                            }}
                                                onMouseOver={() => setActive('g')}
                                                onMouseLeave={() => setActive(null)}
                                                onClick={saveSignature}
                                            >
                                                <small>
                                                    {
                                                        isLoading === true ? (<>
                                                               <Spinner animation="border" variant="light" size='sm' style={{color:'red'}} />
                                                        </>) : (<>
                                                            ບັນທືກmmmm
                                                        </>)
                                                    }

                                                </small>
                                            </div>
                                        </div>
                                        <div style={{ width: '50%', }}>
                                            <div style={{ border: '1px solid #5EBFE3', marginTop: 20, height: 200, marginRight: 10, borderRadius: 5, backgroundColor: '#e9eef5' }}>
                                                {signatureData && <img src={signatureData} alt="Signature" />}
                                            </div>
                                        </div>
                                    </div>
                                </>) : (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 20 }}>
                                        <div style={{ width: '50%', }}>
                                            <div style={{
                                                border: '1px solid #5EBFE3',
                                                marginTop: 20,
                                                height: 200,
                                                marginLeft: 10,
                                                borderRadius: 5,
                                                backgroundColor: '#e9eef5',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                {pictureUrl && (
                                                    <img src={pictureUrl} style={{ width: '100%', height: 200 }} />
                                                )}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        >
                                            <div style={{
                                                border: '1px solid #5EBFE3',
                                                borderRadius: 5,
                                                width: 50,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                backgroundColor: active === 's' ? '#e9eef5' : '',
                                                boxShadow: active === 's' ? '1px 2px 9px #F4AAB9' : ''

                                            }}
                                                onMouseOver={() => setActive('s')}
                                                onMouseLeave={() => setActive(null)}
                                                onClick={clearUpload}
                                            >
                                                <small>ສ້າງໃໝ່</small>
                                            </div>
                                            <div style={{
                                                border: '1px solid #5EBFE3',
                                                borderRadius: 5,
                                                marginTop: 30,
                                                width: 50,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                backgroundColor: active === 'g' ? '#e9eef5' : '',
                                                boxShadow: active === 'g' ? '1px 2px 9px #F4AAB9' : ''
                                            }}
                                                onMouseOver={() => setActive('g')}
                                                onMouseLeave={() => setActive(null)}
                                                onClick={OnCreate}
                                            >
                                                <small>     
                                                    {
                                                    isLoading === true ? (<>
                                                         <Spinner animation="border" variant="light" size='sm' />
                                                    </>) : (<>
                                                        ບັນທືກss
                                                    </>)
                                                }</small>
                                            </div>
                                        </div>
                                        <div style={{ width: '50%', }}>
                                            <div style={{ border: '1px solid #5EBFE3', marginTop: 20, height: 200, marginRight: 10, borderRadius: 5, backgroundColor: '#e9eef5' }}>
                                            </div>
                                        </div>
                                    </div>

                                </>)
                        }

                    </div>
                </div>
            </Modal>

        </div>
    )
}
function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}