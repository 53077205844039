
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));
export const ModalIncomingDocument = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading,] = useState(false);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [req_status, setReq_status] = useState('')
    const handlePictureChange = (event) => {
        props.setFile(event.target.files);
        const selectedFiles = event.target.files;
        const file_attachment = Array.from(selectedFiles);
        props.setImage(file_attachment)

    };
    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
        setChecked2(false)
        setReq_status(1)
    };
    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
        setChecked1(false)
        setReq_status(0)
    };
    const CreateData = async () => {
        setIsLoading(true)
        let images
        if (!props.file) {
            images = 0
        } else {
            let formData = new FormData();
            for (const key of Object.keys(props.file)) {
                formData.append("file_name", props.file[key]);
            }
            formData.append("file_attachment", props.file);
            let profileImageReturnName = await axios.post("/api/document-management/incoming/upload", formData);
            images = profileImageReturnName.data;
        }
        let dataInform = {
            title:props.title,
            company_name:props.company_name,
            contact:props.contact,
            address:props.address,
            doc_no:props.doc_no,
            req_status:props.req_status,
            file_name: props.images,
        }
        axios.post('/api/document-management/incoming/insert-incoming-form', dataInform).then((data) => {
            props.Onloaddata();
            props.setTitle('')
            props.setAddress('')
            props.setCompany_name('')
            props.setDoc_no('')
            props.setAddress('')
            props.setContact('')
            props.setImage('')
            setIsLoading(false)
            props.setShow(false)
            props.setChecked1(false)
            setChecked2(false)
           
        }).catch((err) => {
            console.log(err)
        })
    }
    return (
        <>
            {
                props.checkedstatus === 1 ? (<>
                    <Modal show={props.show} onHide={props.handleClosedel} style={{ paddingTop: 40 }} size="lg">
                        <Modal.Header closeButton>
                            <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>
                                ຂໍ້ມູນເອກະສານຂາເຂົ້າ </span>
                        </Modal.Header>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ຫົວຂໍ້ເອກະສານ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setTitle(e.target.value)}
                                    value={props.title}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 53, marginRight: 10, marginTop: 6 }}>ຊື່ບໍລິສັດ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setCompany_name(e.target.value)}
                                    value={props.company_name}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 69, marginRight: 10, marginTop: 6 }}>ເບີໂທ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setContact(e.target.value)}
                                    value={props.contact}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 79, marginRight: 10, marginTop: 6 }}>ທີ່ຢູ່:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setAddress(e.target.value)}
                                    value={props.address}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 10, marginRight: 10, marginTop: 6 }}>ເລກທີເອກະສານ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setDoc_no(e.target.value)}
                                    value={props.doc_no}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ marginLeft: 90 }}>
                                    <Checkbox
                                        checked={checked1}
                                        onChange={handleChange1}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <small>ປະຕິເສດ</small>
                                </div>
                                <div>
                                    <Checkbox
                                        checked={checked2}
                                        onChange={handleChange2}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    <small>ຮັບເອກະສານ</small>

                                </div>

                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                <div className={classes.root} style={{ marginLeft: 91 }}>
                                    <input

                                        className={classes.input}
                                        id="contained-button-file"
                                        multiple
                                        onChange={handlePictureChange}
                                        type="file"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <div style={{ border: '1px solid #5ebfe3', borderRadius: 5, cursor: 'pointer', height: 30 }}>
                                            <small style={{ marginLeft: 10, marginRight: 10, marginTop: 5, display: 'flex', flex: 1 }}>Upload Picture</small>
                                        </div>

                                    </label>
                                </div>
                                <div style={{ border: '1px solid #5ebfe3', width: 600, height: 150, marginLeft: 100 }}>
                                    {props.image &&
                                        props.image.map((data, index) => {
                                            let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                            return (
                                                <div key={index}>
                                                    <small style={{ fontSize: 15 }}>{data?.name} ({sizes}) kb</small>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 78, marginTop: 20 }}>
                                <Button
                                    style={{ marginRight: 20, marginBottom: 10 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    onClick={() => { CreateData() }}
                                >
                                    {!isLoading ? (
                                        <>
                                            Save
                                        </>
                                    ) : (
                                        <>
                                            {
                                                <Spinner animation="border" variant="light" size='sm' />
                                            }
                                        </>)
                                    }
                                </Button>
                            </div>
                        </div>
                    </Modal>

                </>) : (<>
                    <Modal show={props.show} onHide={props.handleClosedel} style={{ paddingTop: 40 }} size="lg">
                        <Modal.Header closeButton>
                            <span style={{ fontSize: 20, fontWeight: 'bold', paddingTop: 10, color: "#007bff" }}>
                                ແກ້ໄຂຂໍ້ມູນເອກະສານຂາເຂົ້າ </span>
                        </Modal.Header>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 14, marginRight: 10, marginTop: 6 }}>ຫົວຂໍ້ເອກະສານ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setTitle(e.target.value)}
                                    value={props.title}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 53, marginRight: 10, marginTop: 6 }}>ຊື່ບໍລິສັດ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setCompany_name(e.target.value)}
                                    value={props.company_name}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 69, marginRight: 10, marginTop: 6 }}>ເບີໂທ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setContact(e.target.value)}
                                    value={props.contact}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 79, marginRight: 10, marginTop: 6 }}>ທີ່ຢູ່:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setAddress(e.target.value)}
                                    value={props.address}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        border: '1px solid #5ebfe3',
                                        backgroundColor: "#e9eef5",
                                        borderTopLeftRadius: 5,
                                        borderRight: 'none',
                                        borderBottomLeftRadius: 5,
                                        height: 40,
                                        marginLeft: 100,
                                        marginTop: 20
                                    }}>
                                    <small style={{ display: 'flex', flex: 1, fontSize: 15, marginLeft: 10, marginRight: 10, marginTop: 6 }}>ເລກທີເອກະສານ:</small>
                                </div>
                                <input type="text"
                                    onChange={(e) => props.setDoc_no(e.target.value)}
                                    value={props.doc_no}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        border: '1px solid #5ebfe3',
                                        height: 40,
                                        marginTop: 20,
                                        marginRight: 100,
                                        borderLeft: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5

                                    }}
                                />
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ marginLeft: 90 }}>
                                    <Checkbox
                                        checked={checked1}
                                        onChange={handleChange1}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <small>ປະຕິເສດ</small>
                                </div>
                                <div>
                                    <Checkbox
                                        checked={checked2}
                                        onChange={handleChange2}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    <small>ຮັບເອກະສານ</small>

                                </div>

                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                <div className={classes.root} style={{ marginLeft: 91 }}>
                                    <input

                                        className={classes.input}
                                        id="contained-button-file"
                                        multiple
                                        onChange={handlePictureChange}
                                        type="file"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <div style={{ border: '1px solid #5ebfe3', borderRadius: 5, cursor: 'pointer', height: 30 }}>
                                            <small style={{ marginLeft: 10, marginRight: 10, marginTop: 5, display: 'flex', flex: 1 }}>Upload Picture</small>
                                        </div>

                                    </label>
                                </div>
                                <div style={{ border: '1px solid #5ebfe3', width: 600, height: 150, marginLeft: 100 }}>
                                    {props.image &&
                                        props.image.map((data, index) => {
                                            let sizes = parseFloat(data?.size / 1024).toFixed(2)
                                            return (
                                                <div key={index}>
                                                    <small style={{ fontSize: 15 }}>{data?.name} ({sizes}) kb</small>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 78, marginTop: 20 }}>
                                <Button
                                    style={{ marginRight: 20, marginBottom: 10 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    onClick={() => { CreateData() }}
                                >
                                    {!isLoading ? (
                                        <>
                                            Save
                                        </>
                                    ) : (
                                        <>
                                            {
                                                <Spinner animation="border" variant="light" size='sm' />
                                            }
                                        </>)
                                    }
                                </Button>
                            </div>
                        </div>
                    </Modal>

                </>)
            }

        </>

    )
}
