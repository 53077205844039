import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';

import ErrorIcon from '@material-ui/icons/Error';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
export const ModalOpenRequestSuccessully = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <Modal show={props.successfully} onHide={props.Successfully} style={{ paddingTop: 50 }} >
                <Modal.Header closeButton>
                </Modal.Header>
                {
                    props.modalstatus === 1 ? (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                <div style={{ border: '3px solid #e65100', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                    <ScreenRotationIcon style={{ color: '#e65100', marginLeft: 10, marginTop: 10 }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                <small style={{ fontSize: 20 }}>ສົ່ງໄປຫາຄົນທີ່ໃສ່ເລກທີ່ເອກກະສານແລ້ວ.....</small>
                            </div>

                        </>) : props.modalstatus === 3 ? (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                    <div style={{ border: '3px solid #f000b9', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                        <RepeatOneIcon style={{ color: '#f000b9', marginLeft: 10, marginTop: 10 }} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                    <small style={{ fontSize: 20 }}>ກໍາລັງສົ່ງຮ້ອງຂໍໄປຫາຄົນທີ່ອະນຸມັດ.....</small>
                                </div>
                            </>) : props.modalstatus === 4 ? (<>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                    <div style={{ border: '3px solid #ff9800', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                        <AutorenewIcon style={{ color: '#ff9800', marginLeft: 10, marginTop: 10 }} />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                    <small style={{ fontSize: 20 }}>ກໍາລັງດໍາເນີນງານ.....</small>
                                </div>
                            </>) : props.modalstatus === 5 ? (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <div style={{ border: '3px solid #10b981', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            <DoneIcon style={{ color: '#10b981', marginLeft: 10, marginTop: 10 }} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <small style={{ fontSize: 20 }}>ເອກະສານຖືກອະນຸມັດໄປແລ້ວ.....</small>
                                    </div>
                                </>
                            ) : props.modalstatus === 6 ? (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <div style={{ border: '3px solid #5EBFE3', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            <ErrorIcon style={{ color: '#01579b', marginLeft: 10, marginTop: 10 }} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <small style={{ fontSize: 20 }}>ເອກະສານຖືກປະຕິເສດ.....</small>
                                    </div>
                                </>
                            ) : props.modalstatus === 7 ? (
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <div style={{ border: '3px solid #5EBFE3', width: 50, height: 50, borderRadius: '50%', marginTop: 20, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            <ErrorIcon style={{ color: '#01579b', marginLeft: 10, marginTop: 10 }} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                        <small style={{ fontSize: 20 }}>ໄດ້ຮັບເອກະສານແລ້ວ.....</small>
                                    </div>
                                </>
                            ) : null
                }
            </Modal>
        </div>
    )
}

